import { Material } from '../material';

export const BEND_INNER_RADIUS_MATERIALS = [
  Material.ALUMINUM,
  Material.STEEL,
  Material.STAINLESS_STEEL,
];

export const BEND_INNER_RADIUS_SHEET_THICKNESSES = [
  0.5, 0.7, 0.88, 1.0, 1.25, 1.5, 1.75, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5.0, 6.0, 6.4, 8.0, 9.6, 10.0,
  12.0, 12.7, 14.0, 15.0, 15.8, 16.0, 19.0, 20.0, 25.0,
];

export const BEND_INNER_RADIUS_UPPER_TOOL_RADII = [0.5, 1, 2, 2.3, 3, 4, 5, 6, 8, 10.0, 20.0];

export const BEND_INNER_RADIUS_DIE_WIDTHS = [
  4, 5, 6, 8, 10, 12, 14, 16, 20, 24, 30, 40, 50, 60, 70, 80, 90, 100, 120, 140, 160, 180, 200, 220,
  240,
];

export const BEND_INNER_RADIUS_BENDING_ANGLES = [30, 60, 90, 120];
