<div class="input-container">
  <!-- Material -->
  <div class="dropdown">
    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.MATERIAL | translate }}
    </span>
    <xui-dropdown
      #materialDropDown
      [label]="selectedMaterialName()"
      data-ngtx="bend-inner-radius:material"
    >
      <xui-dropdown-item
        (click)="onSelectMaterial(material.id); materialDropDown.close()"
        *ngFor="let material of materials"
      >
        {{ material?.name }}
      </xui-dropdown-item>
    </xui-dropdown>
  </div>
  <xui-input-field
    (focusout)="onSelectItem()"
    [(text)]="tensileStrengthValue.value"
    [disabled]="true"
    data-ngtx="pressing-force:tensileStrength"
    label="{{ translations.CALCULATIONS.INPUT_LABELS.TENSILE_STRENGTH | translate }}"
  >
    <xui-value
      #tensileStrengthValue
      [(binding)]="tensileStrength"
      [fallbackValue]="tensileStrength"
      lsbValidateRequired
    >
      <xui-number-formatter [maxDecimals]="0" [unit]="'N/mm²'"></xui-number-formatter>
    </xui-value>
  </xui-input-field>

  <!-- Sheet thickness -->
  <div class="dropdown">
    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.SHEET_THICKNESS | translate }}
    </span>
    <xui-dropdown
      #sheetThicknessDropDown
      [class.invalid-selection]="!selectedSheetThickness.isValid"
      [label]="selectedSheetThicknessInCmOrInch.value"
      data-ngtx="bend-inner-radius:sheet-thickness"
    >
      <xui-value #selectedSheetThicknessInCmOrInch [binding]="selectedSheetThickness.id">
        <xui-length-converter
          imperialTargetUnit="inch"
          metricTargetUnit="mm"
        ></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="2"
          [unit]="selectedSheetThicknessInCmOrInch.unit"
        ></xui-number-formatter>
      </xui-value>
      <xui-dropdown-item
        (click)="
          selectedSheetThickness = sheetThickness;
          sheetThicknessForCalculation = sheetThickness.id;
          onSelectItem();
          sheetThicknessDropDown.close()
        "
        *ngFor="let sheetThickness of sheetThicknesses"
        [class.invalid-option]="!sheetThickness.isValid"
      >
        <xui-value #sheetThicknessInCentimeterOrInch [binding]="sheetThickness.id">
          <xui-length-converter
            imperialTargetUnit="inch"
            metricTargetUnit="mm"
          ></xui-length-converter>
          <xui-number-formatter
            [maxDecimals]="2"
            [unit]="sheetThicknessInCentimeterOrInch.unit"
          ></xui-number-formatter>
        </xui-value>
        {{ sheetThicknessInCentimeterOrInch.value }}
      </xui-dropdown-item>
    </xui-dropdown>
  </div>

  <ng-container *ngIf="!selectedSheetThickness.isValid">
    <span class="calculationError">
      {{ translations.CALCULATIONS.ERROR_MESSAGES.UNKNOWN_PARAMETER_COMBINATION | translate }}
    </span>
  </ng-container>

  <!-- Die Width -->
  <div class="dropdown">
    <span class="dropdown-label">
      {{ translations.CALCULATIONS.TYPES.DIE_WIDTH | translate }}
    </span>
    <xui-dropdown
      #dieWidthDropDown
      [class.invalid-selection]="!selectedDieWidth.isValid"
      [label]="dieWidthInCmOrInch.value"
      data-ngtx="bend-inner-radius:die-width-dropdown"
    >
      <xui-value #dieWidthInCmOrInch [binding]="selectedDieWidth.id">
        <xui-length-converter
          imperialTargetUnit="inch"
          metricTargetUnit="mm"
        ></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="2"
          [unit]="dieWidthInCmOrInch.unit"
        ></xui-number-formatter>
      </xui-value>
      <xui-dropdown-item
        (click)="
          selectedDieWidth = dieWidth;
          dieWidthForCalculation = dieWidth.id;
          onSelectItem();
          dieWidthDropDown.close()
        "
        *ngFor="let dieWidth of dieWidths"
        [class.invalid-option]="!dieWidth.isValid"
      >
        <xui-value #dieWidthInCentimeterOrInch [binding]="dieWidth.id">
          <xui-length-converter
            imperialTargetUnit="inch"
            metricTargetUnit="mm"
          ></xui-length-converter>
          <xui-number-formatter
            [maxDecimals]="2"
            [unit]="dieWidthInCentimeterOrInch.unit"
          ></xui-number-formatter>
        </xui-value>
        {{ dieWidthInCentimeterOrInch.value }}
      </xui-dropdown-item>
    </xui-dropdown>
  </div>

  <ng-container *ngIf="!selectedDieWidth.isValid && selectedSheetThickness.isValid">
    <span class="calculationError">
      {{ translations.CALCULATIONS.ERROR_MESSAGES.UNKNOWN_PARAMETER_COMBINATION | translate }}
    </span>
  </ng-container>

  <!-- Upper tool radius -->
  <div class="dropdown">
    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.UPPER_TOOL_RADIUS | translate }}
    </span>
    <xui-dropdown
      #upperToolRadiusDropDown
      [class.invalid-selection]="!selectedUpperToolRadius.isValid"
      [label]="upperToolRadiusInCmOrInch.value"
      data-ngtx="bend-inner-radius:upper-tool-radius"
    >
      <xui-value #upperToolRadiusInCmOrInch [binding]="selectedUpperToolRadius.id">
        <xui-length-converter
          imperialTargetUnit="inch"
          metricTargetUnit="mm"
        ></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="2"
          [unit]="upperToolRadiusInCmOrInch.unit"
        ></xui-number-formatter>
      </xui-value>
      <xui-dropdown-item
        (click)="
          selectedUpperToolRadius = upperToolRadius;
          upperToolRadiusForCalculation = upperToolRadius.id;
          onSelectItem();
          upperToolRadiusDropDown.close()
        "
        *ngFor="let upperToolRadius of upperToolRadii"
        [class.invalid-option]="!upperToolRadius.isValid"
      >
        <xui-value #upperToolRadiusInCentimeterOrInch [binding]="upperToolRadius.id">
          <xui-length-converter
            imperialTargetUnit="inch"
            metricTargetUnit="mm"
          ></xui-length-converter>
          <xui-number-formatter
            [maxDecimals]="2"
            [unit]="upperToolRadiusInCentimeterOrInch.unit"
          ></xui-number-formatter>
        </xui-value>
        {{ upperToolRadiusInCentimeterOrInch.value }}
      </xui-dropdown-item>
    </xui-dropdown>
  </div>

  <ng-container
    *ngIf="
      !selectedUpperToolRadius.isValid && selectedSheetThickness.isValid && selectedDieWidth.isValid
    "
  >
    <span class="calculationError">
      {{ translations.CALCULATIONS.ERROR_MESSAGES.UNKNOWN_PARAMETER_COMBINATION | translate }}
    </span>
  </ng-container>

  <!-- Bending angle -->
  <div class="dropdown">
    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.BENDING_ANGLE | translate }}
    </span>
    <xui-dropdown
      #bendingAngleDropDown
      [class.invalid-selection]="!selectedBendingAngle.isValid"
      [label]="selectedBendingAngleValue.value"
      data-ngtx="bend-inner-radius:upper-tool-radius"
    >
      <xui-value #selectedBendingAngleValue [binding]="selectedBendingAngle.id">
        <xui-number-formatter [maxDecimals]="0" [unit]="'°'"></xui-number-formatter>
      </xui-value>
      <xui-dropdown-item
        (click)="
          selectedBendingAngle = bendingAngle;
          bendingAngleForCalculation = bendingAngle.id;
          onSelectItem();
          bendingAngleDropDown.close()
        "
        *ngFor="let bendingAngle of bendingAngles"
        [class.invalid-option]="!bendingAngle.isValid"
      >
        <xui-value #bendingAngleDropdownItemValue [binding]="bendingAngle.id">
          <xui-number-formatter [maxDecimals]="0" [unit]="'°'"></xui-number-formatter>
        </xui-value>
        {{ bendingAngleDropdownItemValue.value }}
      </xui-dropdown-item>
    </xui-dropdown>
  </div>

  <ng-container
    *ngIf="
      !selectedBendingAngle.isValid &&
      selectedSheetThickness.isValid &&
      selectedDieWidth.isValid &&
      selectedUpperToolRadius.isValid
    "
  >
    <span class="calculationError">
      {{ translations.CALCULATIONS.ERROR_MESSAGES.UNKNOWN_PARAMETER_COMBINATION | translate }}
    </span>
  </ng-container>

  <!-- Calculate -->
  <div class="calculate-button">
    <xui-button
      (click)="calculate()"
      [class.disabled]="
        !(
          selectedBendingAngle.isValid &&
          selectedSheetThickness.isValid &&
          selectedDieWidth.isValid &&
          selectedUpperToolRadius.isValid
        )
      "
      xuiClickUsageTracking="{{ uiElementIds.CALCULATE_BEND_INNER_RADIUS }}"
    >
      {{ translations.CALCULATIONS.INPUT_LABELS.CALCULATE | translate }}
    </xui-button>
  </div>

  <div *ngIf="calculationResult$ | async as calculationResult">
    <div *ngIf="calculationResult.bendInnerRadius !== undefined" class="calculation-result">
      <xui-caption-label [caption]="translations.CALCULATIONS.TYPES.BEND_INNER_RADIUS | translate">
        <xui-value
          #bendInnerRadiusResultValue
          [(binding)]="calculationResult.bendInnerRadius"
          [fallbackValue]="0"
        >
          <xui-length-converter
            imperialTargetUnit="inch"
            metricTargetUnit="mm"
          ></xui-length-converter>
          <xui-number-formatter
            [maxDecimals]="2"
            [unit]="bendInnerRadiusResultValue.unit"
          ></xui-number-formatter>
        </xui-value>
        {{ bendInnerRadiusResultValue.value }}
      </xui-caption-label>
    </div>
  </div>
  <div class="result-image-block">
    <lsb-bend-inner-radius-picture
      [bendInnerRadius]="bendInnerRadiusForImage == -1 ? '' : bendInnerRadiusResultImageValue.value"
      [dieWidth]="dieWidthImageValue.value"
    >
      <xui-value #dieWidthImageValue [(binding)]="dieWidthForCalculation" [fallbackValue]="0">
        <xui-length-converter imperialTargetUnit="mm" metricTargetUnit="mm"></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="2"
          [unit]="dieWidthImageValue.unit"
        ></xui-number-formatter>
      </xui-value>
      <xui-value
        #bendInnerRadiusResultImageValue
        [(binding)]="bendInnerRadiusForImage"
        [fallbackValue]="0"
      >
        <xui-length-converter
          imperialTargetUnit="inch"
          metricTargetUnit="mm"
        ></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="2"
          [unit]="bendInnerRadiusResultImageValue.unit"
        ></xui-number-formatter>
      </xui-value>
    </lsb-bend-inner-radius-picture>
  </div>
</div>
