<ng-container *ngIf="filteredParts$ | async as parts">
  <lsb-list class="parts" *ngIf="parts.length > 0">
    <lsb-list-item
      *ngFor="let part of parts"
      [flat]="!selectableItems"
      [selected]="part === selectedPart"
      (click)="selectionChange.emit(part)"
    >
      <lsb-part-list-item
        [part]="part"
        [hideKeywords]="hideKeywords"
        (keywordSelect)="openKeyword.emit($event)"
        (select)="openPart.emit($event)"
      ></lsb-part-list-item>
    </lsb-list-item>
  </lsb-list>
</ng-container>
