export const SHEET_THICKNESSES = [
  0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5, 3, 3.5, 4, 4.5, 5, 6, 7, 8, 10, 12, 15, 20,
];

export const UPPER_TOOL_RADII = [
  0.2, 0.3, 0.5, 0.6, 1, 1.5, 1.6, 2, 2.3, 2.5, 3, 3.2, 4, 4.7, 5, 6, 6.35, 8, 10, 12.5, 15, 17.5,
  20, 25, 30, 35, 40, 45, 50,
];

export const DIE_WIDTHS = [
  4, 5, 6, 8, 10, 12, 14, 16, 20, 24, 30, 40, 50, 60, 70, 80, 90, 100, 120, 150, 200,
];

export const BENDING_ANGLES = [30, 80, 84, 90];

export const GAUGES: number[] = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
];
