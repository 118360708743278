import { LegLength } from '../model/leg-length';

export const LEG_LENGTHS: LegLength[] = [
  {
    dieWidth: 4,
    matrix: 'EV/S-W4/30°',
    legLength: 3.3,
  },
  {
    dieWidth: 4,
    matrix: 'EV/S-W4/84°',
    legLength: 3.1,
  },
  {
    dieWidth: 4,
    matrix: 'EV/S-W4/90°',
    legLength: 3.1,
  },
  {
    dieWidth: 5,
    matrix: 'EV/S-W5/30°',
    legLength: 4.1,
  },
  {
    dieWidth: 5,
    matrix: 'EV/S-W5/30° R2',
    legLength: 4.9,
  },
  {
    dieWidth: 5,
    matrix: 'EV/S-W5/84°',
    legLength: 3.8,
  },
  {
    dieWidth: 5,
    matrix: 'EV/S-W5/90°',
    legLength: 3.8,
  },
  {
    dieWidth: 6,
    matrix: 'EV001 30°',
    legLength: 4.8,
  },
  {
    dieWidth: 6,
    matrix: 'EV001/H 30°',
    legLength: 4.8,
  },
  {
    dieWidth: 6,
    matrix: 'EV001 R3 30°',
    legLength: 6.4,
  },
  {
    dieWidth: 6,
    matrix: 'EV001/S 30°',
    legLength: 4.8,
  },
  {
    dieWidth: 6,
    matrix: 'EV W6/84°',
    legLength: 4.5,
  },
  {
    dieWidth: 6,
    matrix: 'EV/H W6/84°',
    legLength: 4.5,
  },
  {
    dieWidth: 6,
    matrix: 'EV/S W6/84°',
    legLength: 4.5,
  },
  {
    dieWidth: 6,
    matrix: 'EV020 86°',
    legLength: 4.5,
  },
  {
    dieWidth: 6,
    matrix: 'EV020/S 86°',
    legLength: 4.5,
  },
  {
    dieWidth: 6,
    matrix: 'EV040 90°',
    legLength: 4.5,
  },
  {
    dieWidth: 6,
    matrix: 'EV040/S 90°',
    legLength: 4.5,
  },
  {
    dieWidth: 8,
    matrix: 'EV002 30°',
    legLength: 6.3,
  },
  {
    dieWidth: 8,
    matrix: 'EV002/H 30°',
    legLength: 6.3,
  },
  {
    dieWidth: 8,
    matrix: 'EV002 R3 30°',
    legLength: 8,
  },
  {
    dieWidth: 8,
    matrix: 'EV002/S 30°',
    legLength: 6.3,
  },
  {
    dieWidth: 8,
    matrix: 'EV W8/84°',
    legLength: 5.7,
  },
  {
    dieWidth: 8,
    matrix: 'EV/H W8/84°',
    legLength: 5.7,
  },
  {
    dieWidth: 8,
    matrix: 'EV/S W8/84°',
    legLength: 5.7,
  },
  {
    dieWidth: 8,
    matrix: 'EV021 86°',
    legLength: 5.7,
  },
  {
    dieWidth: 8,
    matrix: 'EV021/S 86°',
    legLength: 5.7,
  },
  {
    dieWidth: 8,
    matrix: 'EV041 90°',
    legLength: 5.7,
  },
  {
    dieWidth: 8,
    matrix: 'EV041/S 90°',
    legLength: 5.7,
  },
  {
    dieWidth: 8,
    matrix: 'KEV W8/30°',
    legLength: 7.6,
  },
  {
    dieWidth: 10,
    matrix: 'EV003 30°',
    legLength: 7.8,
  },
  {
    dieWidth: 10,
    matrix: 'EV003/H 30°',
    legLength: 7.8,
  },
  {
    dieWidth: 10,
    matrix: 'EV003 R3 30°',
    legLength: 9.5,
  },
  {
    dieWidth: 10,
    matrix: 'EV003/S 30°',
    legLength: 7.8,
  },
  {
    dieWidth: 10,
    matrix: 'EV W10/84°',
    legLength: 7.1,
  },
  {
    dieWidth: 10,
    matrix: 'EV/H W10/84°',
    legLength: 7.1,
  },
  {
    dieWidth: 10,
    matrix: 'EV/S W10/84°',
    legLength: 7.1,
  },
  {
    dieWidth: 10,
    matrix: 'EV022 86°',
    legLength: 7.1,
  },
  {
    dieWidth: 10,
    matrix: 'EV022/S 86°',
    legLength: 7.1,
  },
  {
    dieWidth: 10,
    matrix: 'EV042 90°',
    legLength: 7,
  },
  {
    dieWidth: 10,
    matrix: 'EV042/S 90°',
    legLength: 7,
  },
  {
    dieWidth: 10,
    matrix: 'KEV W10/30°',
    legLength: 8.7,
  },
  {
    dieWidth: 12,
    matrix: 'EV004 30°',
    legLength: 9.3,
  },
  {
    dieWidth: 12,
    matrix: 'EV004/H 30°',
    legLength: 9.3,
  },
  {
    dieWidth: 12,
    matrix: 'EV004 R3 30°',
    legLength: 10.3,
  },
  {
    dieWidth: 12,
    matrix: 'EV004/S 30°',
    legLength: 9.3,
  },
  {
    dieWidth: 12,
    matrix: 'EV W12/84°',
    legLength: 8.2,
  },
  {
    dieWidth: 12,
    matrix: 'EV/H W12/84°',
    legLength: 8.2,
  },
  {
    dieWidth: 12,
    matrix: 'EV/S W12/84°',
    legLength: 8.2,
  },
  {
    dieWidth: 12,
    matrix: 'EV023 86°',
    legLength: 8.2,
  },
  {
    dieWidth: 12,
    matrix: 'EV023/S 86°',
    legLength: 8.2,
  },
  {
    dieWidth: 12,
    matrix: 'EV043 90°',
    legLength: 8.2,
  },
  {
    dieWidth: 12,
    matrix: 'EV043/S 90°',
    legLength: 8.2,
  },
  {
    dieWidth: 14,
    matrix: 'KEV W12/30°',
    legLength: 10.5,
  },
  {
    dieWidth: 14,
    matrix: 'EV/S W14/84°',
    legLength: 10.3,
  },
  {
    dieWidth: 16,
    matrix: 'EV005 30°',
    legLength: 12.1,
  },
  {
    dieWidth: 16,
    matrix: 'EV005/H 30°',
    legLength: 12.1,
  },
  {
    dieWidth: 16,
    matrix: 'EV005 R3 30°',
    legLength: 13.5,
  },
  {
    dieWidth: 16,
    matrix: 'KEV W16/30°',
    legLength: 12.8,
  },
  {
    dieWidth: 16,
    matrix: 'EV W16/84°',
    legLength: 11.1,
  },
  {
    dieWidth: 16,
    matrix: 'EV/H W16/84°',
    legLength: 11.1,
  },
  {
    dieWidth: 16,
    matrix: 'EV044 90°',
    legLength: 11,
  },
  {
    dieWidth: 16,
    matrix: 'EV024 86°',
    legLength: 11,
  },
  {
    dieWidth: 20,
    matrix: 'EV006 30°',
    legLength: 15.7,
  },
  {
    dieWidth: 20,
    matrix: 'EV006/H 30°',
    legLength: 15.7,
  },
  {
    dieWidth: 20,
    matrix: 'KEV W20/30°',
    legLength: 15.2,
  },
  {
    dieWidth: 20,
    matrix: 'EV025 86°',
    legLength: 15.2,
  },
  {
    dieWidth: 20,
    matrix: 'EV W20/84°',
    legLength: 14.5,
  },
  {
    dieWidth: 20,
    matrix: 'EV/H W20/84°',
    legLength: 14.5,
  },
  {
    dieWidth: 24,
    matrix: 'EV007 30°',
    legLength: 19.1,
  },
  {
    dieWidth: 24,
    matrix: 'EV007/H 30°',
    legLength: 19.1,
  },
  {
    dieWidth: 24,
    matrix: 'KEV W24/30°',
    legLength: 17.3,
  },
  {
    dieWidth: 24,
    matrix: 'EV026 86°',
    legLength: 17.3,
  },
  {
    dieWidth: 24,
    matrix: 'EV W24/80°',
    legLength: 19,
  },
  {
    dieWidth: 24,
    matrix: 'EV/H W24/84°',
    legLength: 19,
  },
  {
    dieWidth: 30,
    matrix: 'EV W30/30° R3',
    legLength: 23,
  },
  {
    dieWidth: 30,
    matrix: 'EV027 86°',
    legLength: 23,
  },
  {
    dieWidth: 30,
    matrix: 'EV W30/80°',
    legLength: 23.7,
  },
  {
    dieWidth: 30,
    matrix: 'EV/H W30/80°',
    legLength: 23.7,
  },
  {
    dieWidth: 40,
    matrix: 'EV W40/30°',
    legLength: 31.5,
  },
  {
    dieWidth: 40,
    matrix: 'EV028 86°',
    legLength: 31.5,
  },
  {
    dieWidth: 40,
    matrix: 'EV W40/80°',
    legLength: 29.8,
  },
  {
    dieWidth: 40,
    matrix: 'EV/H W40/80°',
    legLength: 29.8,
  },
  {
    dieWidth: 50,
    matrix: 'EV W50/30°',
    legLength: 38,
  },
  {
    dieWidth: 50,
    matrix: 'EV W50/80°',
    legLength: 35.5,
  },
  {
    dieWidth: 50,
    matrix: 'EV/H W50/80°',
    legLength: 35.5,
  },
  {
    dieWidth: 50,
    matrix: 'EV029 86°',
    legLength: 35.5,
  },
  {
    dieWidth: 60,
    matrix: 'EV W60/80°',
    legLength: 42,
  },
  {
    dieWidth: 70,
    matrix: 'EV W70/80°',
    legLength: 48,
  },
  {
    dieWidth: 80,
    matrix: 'EV W80/80°',
    legLength: 54,
  },
  {
    dieWidth: 90,
    matrix: 'EV W90/80°',
    legLength: 65,
  },
  {
    dieWidth: 100,
    matrix: 'EV W100/80°',
    legLength: 68,
  },
  {
    dieWidth: 120,
    matrix: 'EV W120/60°',
    legLength: 82,
  },
  {
    dieWidth: 12,
    matrix: 'RBK 300',
    legLength: 10.5,
  },
  {
    dieWidth: 8,
    matrix: 'RB 200',
    legLength: 6.1,
  },
  {
    dieWidth: 12,
    matrix: 'RB 300',
    legLength: 8.5,
  },
  // {
  //   dieWidth: 12,
  //   matrix: 'RBK 300',
  //   legLength: 11,
  // },
  {
    dieWidth: 16,
    matrix: 'RB 400',
    legLength: 13.2,
  },
  {
    dieWidth: 24,
    matrix: 'RB 500',
    legLength: 18.3,
  },
  {
    dieWidth: 30,
    matrix: 'RB 600',
    legLength: 23.5,
  },
];
