import { Injectable } from '@angular/core';
import { LegLengthCalculationResult } from '../model/leg-length-calculation-result';
import { BehaviorSubject } from 'rxjs';
import { LEG_LENGTHS } from '../data/leg-lengths';

@Injectable()
export class LegLengthCalculationService {
  private calculationResult: BehaviorSubject<LegLengthCalculationResult | undefined> =
    new BehaviorSubject<LegLengthCalculationResult | undefined>(undefined);

  public calculationResult$ = this.calculationResult.asObservable();

  constructor() {}

  public calculate(dieWidth: number): void {
    const legLength = LEG_LENGTHS.filter((l) => l.dieWidth === dieWidth) ?? [];
    this.calculationResult.next({ legLengths: legLength });
  }
}
