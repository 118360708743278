import { Component, Input, OnInit } from '@angular/core';
import { TableInfo } from '../../model/table-result/table-info';
import { TranslationHelper } from '../../../shared/helpers';

@Component({
  selector: 'lsb-result-table',
  templateUrl: './result-table.component.html',
  styleUrls: ['./result-table.component.scss'],
})
export class ResultTableComponent implements OnInit {
  @Input()
  public tableData?: TableInfo;

  public rowsScaffold: number[] = [];
  public columnsScaffold: number[] = [];

  constructor(public translations: TranslationHelper) {}

  ngOnInit(): void {
    if (!this.tableData) {
      return;
    }
    for (let i = 0; i < this.tableData.rows; i++) {
      this.rowsScaffold.push(i);
    }
    for (let i = 0; i < this.tableData.columns; i++) {
      this.columnsScaffold.push(i);
    }
  }

  public cellValue(row: number, col: number): string | undefined {
    return this.tableData?.cells.find((c) => c.row === row && c.column === col)?.value;
  }

  public cellBackground(row: number, col: number): string | undefined {
    return this.tableData?.cells.find((c) => c.row === row && c.column === col)?.backgroundColor;
  }
}
