import { Component, OnInit } from '@angular/core';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import { merge, Observable, Subject } from 'rxjs';
import { LegLengthCalculationResult } from '../../model/leg-length-calculation-result';
import { TranslationHelper } from '../../../shared/helpers';
import { TranslateService } from '@ngx-translate/core';
import { LegLengthCalculationService } from '../../services/leg-length-calculation.service';
import { LEG_LENGTHS } from '../../data/leg-lengths';

@Component({
  selector: 'lsb-leg-length',
  templateUrl: './leg-length.component.html',
  styleUrls: ['../../styles/calculations-base-style.scss', './leg-length.component.scss'],
  providers: [LegLengthCalculationService],
})
export class LegLengthComponent implements OnInit {
  public readonly uiElementIds = UiElementIds;

  public dieWidths: number[];
  public selectedDieWidth: number;

  private clearResultSubject: Subject<LegLengthCalculationResult | undefined> = new Subject<
    LegLengthCalculationResult | undefined
  >();
  public calculationResult$: Observable<LegLengthCalculationResult | undefined> = merge(
    this.clearResultSubject.asObservable(),
    this.calculationService.calculationResult$,
  );

  constructor(
    public translations: TranslationHelper,
    private translateService: TranslateService,
    private calculationService: LegLengthCalculationService,
  ) {}

  ngOnInit(): void {
    const distinct = (value: number, index: number, array: number[]) =>
      array.indexOf(value) === index;

    this.dieWidths = LEG_LENGTHS.map((l) => l.dieWidth).filter(distinct);
    this.selectedDieWidth = this.dieWidths[0];
  }

  public clearResult(): void {
    this.clearResultSubject.next(undefined);
  }

  public calculate(): void {
    this.calculationService.calculate(+this.selectedDieWidth);
  }
}
