import { BendingForceCalculationType } from '../../app/calculations/model/bending-force-calculation-type';
import { CalculationType } from '../../app/calculations/model/calculation-type';
import { Material } from '../../app/calculations/model/material';
import { MountHeightCalculationType } from '../../app/calculations/model/mount-height/mount-height-calculation-type';
import { PressingForceTableToolType } from '../../app/calculations/model/pressing-force-table-tool-type';
import { ToolCategory } from '../../app/calculations/model/tool-category';

export default {
  XGUIDE: {
    DASHBOARD: {
      GETTING_STARTED: {
        TITLE: 'TITLE',
        TAKE_THE_TOUR: 'TAKE_THE_TOUR',
      },
    },
    MEDIA: {
      VIDEO_NOT_SUPPORTED: 'VIDEO_NOT_SUPPORTED',
    },
    PRIVACY: {
      TITLE: 'TITLE',
      PARAGRAPH1: 'PARAGRAPH1',
      CHANGE_SETTINGS_LINK: 'CHANGE_SETTINGS_LINK',
      PARAGRAPH2: 'PARAGRAPH2',
      PRIVACY_POLICY: 'PRIVACY_POLICY',
      PRIVACY_POLICY_HREF: 'PRIVACY_POLICY_HREF',
      IMPRINT: 'IMPRINT',
      DETAILED: {
        PARAGRAPH1: 'PARAGRAPH1',
        PARAGRAPH2: 'PARAGRAPH2',
        PARAGRAPH3: 'PARAGRAPH3',
        ALLOW_COOKIES_LABEL: 'ALLOW_COOKIES_LABEL',
        FURTHER_DETAILS: 'FURTHER_DETAILS',
      },
    },
    DIALOG: {
      OK: 'OK',
      SAVE: 'SAVE',
      BACK: 'BACK',
      NEXT: 'NEXT',
      CANCEL: 'CANCEL',
      FINISH: 'FINISH',
    },
    MENU: { CONTACT_US_BUTTON: 'CONTACT_US_BUTTON' },
  },
  ENUMS: {
    SYSTEM_OF_UNITS: {
      METRIC: 'METRIC',
      IMPERIAL: 'IMPERIAL',
    },
    CURRENCY: {
      EURO: 'EURO',
      USDOLLAR: 'USDOLLAR',
    },
  },
  TEMPLATES: {
    HINT: 'HINT',
  },
  UNSUPPORTED_BROWSER: {
    TITLE: 'TITLE',
    MESSAGE: 'MESSAGE',
  },
  DIALOG: {
    BACK: 'BACK',
    NEXT: 'NEXT',
    CANCEL: 'CANCEL',
    FINISH: 'FINISH',
    OK: 'OK',
    SAVE: 'SAVE',
  },
  MAIN_MENU: {
    BENDING_PRINCIPLES: 'BENDING_PRINCIPLES',
    PARTS: 'PARTS',
    CALCULATIONS: 'CALCULATIONS',
  },
  DASHBOARD: {
    GETTING_STARTED: {
      TOUR_VIDEO_URL: 'TOUR_VIDEO_URL',
    },
    WELCOME: {
      DESCRIPTION: 'DESCRIPTION',
    },
    NEWS: {
      TITLE: 'TITLE',
      NO_NEWS: 'NO_NEWS',
    },
  },
  ABOUT: {
    IMPRINT: 'IMPRINT',
    DATA_PRIVACY: 'DATA_PRIVACY',
    COPYRIGHT: 'COPYRIGHT',
    SETTINGS: 'SETTINGS',
  },
  SETTINGS: {
    LANGUAGE: 'LANGUAGE',
    UNIT_SYSTEM: 'UNIT_SYSTEM',
    CURRENCY: 'CURRENCY',
  },
  IMPRINT: {
    OPERATOR: 'OPERATOR',
    MANAGEMENT: 'MANAGEMENT',
    REGISTER_COURT: 'REGISTER_COURT',
  },
  DATA_PRIVACY: {
    TITLE: 'TITLE',
    PARAGRAPH1: 'PARAGRAPH1',
    PARAGRAPH2: 'PARAGRAPH2',
    PARAGRAPH3: 'PARAGRAPH3',
    LINK_URL: 'LINK_URL',
    LINK_TITLE: 'LINK_TITLE',
    AFTER_LINK_TEXT: 'AFTER_LINK_TEXT',
  },
  COPYRIGHT: {
    TITLE: 'TITLE',
    PARAGRAPH1: 'PARAGRAPH1',
    PARAGRAPH2: 'PARAGRAPH2',
  },
  PARTS: {
    MENU: {
      ALL: 'ALL',
    },
    SPECIFICATION: {
      PARTS_COUNT: 'PARTS_COUNT',
      JOINTS_COUNT: 'JOINTS_COUNT',
      BENDINGS_COUNT: 'BENDINGS_COUNT',
    },
    FILTERS: {
      REQUIREMENTS: 'REQUIREMENTS',
      SEAM_GEOMETRY: 'SEAM_GEOMETRY',
      MATERIAL: 'MATERIAL',
      SHEET_THICKNESS: 'SHEET_THICKNESS',
    },
    CLEAR_ALL_TAGS: 'CLEAR_ALL',
    RELATED_PARTS: 'RELATED_PARTS',
    DOWNLOAD_CAD: 'DOWNLOAD_CAD',
    CALCULATION: 'CALCULATION',
    RELATED_BENDING_PRINCIPLES_TITLE: 'RELATED_BENDING_PRINCIPLES_TITLE',
    FIXTURE: {
      ALTERNATIVE: 'ALTERNATIVE',
      PREBINDERS: 'PREBINDERS',
      FIXTURE_FOR: 'FIXTURE_FOR',
    },
  },
  BENDING_PRINCIPLES: {
    DETAILS: {
      MORE_PARTS: 'MORE_PARTS',
      HELP: {
        TITLE: 'TITLE',
        PARAGRAPH_1: 'PARAGRAPH_1',
        PARAGRAPH_2: 'PARAGRAPH_2',
        LINK_HOMEPAGE: 'LINK_HOMEPAGE',
        LINK_HOMEPAGE_URL: 'LINK_HOMEPAGE_URL',
        MAIL_SUBJECT: 'MAIL_SUBJECT',
      },
    },
  },
  SWITCH_COMPONENT: {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
  },
  CALCULATIONS: {
    TYPES: {
      PRESSING_FORCE: CalculationType.PRESSING_FORCE.toString(),
      DIE_WIDTH: CalculationType.DIE_WIDTH.toString(),
      LEG_LENGTH: CalculationType.LEG_LENGTH.toString(),
      BEND_INNER_RADIUS: CalculationType.BEND_INNER_RADIUS.toString(),
      PRESSING_FORCE_TABLE: CalculationType.PRESSING_FORCE_TABLE.toString(),
      CASE_HEIGHT: CalculationType.CASE_HEIGHT.toString(),
      SHEET_WEIGHT: CalculationType.SHEET_WEIGHT.toString(),
      SHEET_WIDTH: CalculationType.SHEET_WIDTH.toString(),
      MOUNT_HEIGHT: CalculationType.MOUNT_HEIGHT.toString(),
      TOOL_WEIGHT: CalculationType.TOOL_WEIGHT.toString(),
    },
    INPUT_LABELS: {
      CALCULATE: 'CALCULATE',
      MATERIAL: 'MATERIAL',
      TENSILE_STRENGTH: 'TENSILE_STRENGTH',
      UPPER_TOOL_RADIUS: 'UPPER_TOOL_RADIUS',
      BENDING_ANGLE: 'BENDING_ANGLE',
      SHEET_THICKNESS: BendingForceCalculationType.SHEET_THICKNESS.toString(),
      BENDING_LENGTH: BendingForceCalculationType.BENDING_LENGTH.toString(),
      USER_DEFINED: 'USER_DEFINED',
      TOOL_TYPE: 'TOOL_TYPE',
      LENGTH: 'LENGTH',
      WIDTH: 'WIDTH',
      GAUGE: 'GAUGE',
      INNER_BOX_HEIGHT: 'INNER_BOX_HEIGHT',
      UPPER_TOOL_HEIGHT: 'UPPER_TOOL_HEIGHT',
      HALF_PRESS_BAR_DISTANCE: 'HALF_PRESS_BAR_DISTANCE',
      MACHINE: 'MACHINE',
      MACHINE_HEIGHT: 'MACHINE_HEIGHT',
      TOOL_LENGTH: 'TOOL_LENGTH',
      ADAPTER: 'ADAPTER',
      NO_ADAPTER: 'NO_ADAPTER',
      ADAPTER_HEIGHT: 'ADAPTER_HEIGHT',
      UPPER_TOOL: 'UPPER_TOOL',
      BOTTOM_TOOL: 'BOTTOM_TOOL',
      BOTTOM_TOOL_HEIGHT: 'BOTTOM_TOOL_HEIGHT',
      DESIRED_REMAINING_OPENING: 'DESIRED_REMAINING_OPENING',
      TOOL_CATEGORY: 'TOOL_CATEGORY',
    },
    RESULT_LABELS: {
      SHEET_WEIGHT: 'SHEET_WEIGHT',
      MINIMAL_DIE_WIDTH: 'MINIMAL_DIE_WIDTH',
      OPTIMAL_DIE_WIDTH: 'OPTIMAL_DIE_WIDTH',
      MAXIMAL_DIE_WIDTH: 'MAXIMAL_DIE_WIDTH',
      WEIGHT: 'WEIGHT',
      DIE: 'DIE',
    },
    MATERIAL: {
      STAINLESS_STEEL: Material.STAINLESS_STEEL.toString(),
      STEEL: Material.STEEL.toString(),
      ALUMINUM: Material.ALUMINUM.toString(),
      X5CrNi18_10: Material.X5CrNi18_10.toString(),
      ALMG3: Material.ALMG3.toString(),
      DC_01: Material.DC_01.toString(),
      DC_03: Material.DC_03.toString(),
      DC_04: Material.DC_04.toString(),
      S_235_JR: Material.S_235_JR.toString(),
      S_355_JR: Material.S_355_JR.toString(),
      S_355_NC: Material.S_355_NC.toString(),
      S_355_MC: Material.S_355_MC.toString(),
      S_420_NC: Material.S_420_NC.toString(),
      S_420_MC: Material.S_420_MC.toString(),
      S_460_MC: Material.S_460_MC.toString(),
      S_500_MC: Material.S_500_MC.toString(),
      S_550_MC: Material.S_550_MC.toString(),
      S_600_MC: Material.S_600_MC.toString(),
      S_650_MC: Material.S_650_MC.toString(),
      S_700_MC: Material.S_700_MC.toString(),
      WELDOX_500: Material.WELDOX_500.toString(),
      WELDOX_700: Material.WELDOX_700.toString(),
      WELDOX_900: Material.WELDOX_900.toString(),
      WELDOX_1100: Material.WELDOX_1100.toString(),
      HARDOX_400: Material.HARDOX_400.toString(),
      HARDOX_450: Material.HARDOX_450.toString(),
      HARDOX_500: Material.HARDOX_500.toString(),
    },
    ERROR_MESSAGES: {
      SHOULD_BE_POSITIVE: 'SHOULD_BE_POSITIVE',
      UNKNOWN_PARAMETER_COMBINATION: 'UNKNOWN_PARAMETER_COMBINATION',
    },
    TOOL_TYPE: {
      STANDARD_TOOL: PressingForceTableToolType.STANDARD_TOOL.toString(),
      FOLDING: PressingForceTableToolType.FOLDING.toString(),
      ROLL_BEND: PressingForceTableToolType.ROLL_BEND.toString(),
    },
    PRESSING_FORCE_TABLE: {
      PRESSING_FORCE_SPECIFICATION: 'PRESSING_FORCE_SPECIFICATION',
      OPTIMUM_DIE_WIDTH: 'OPTIMUM_DIE_WIDTH',
      FOLDING_PRESSING_FORCE: 'FOLDING_PRESSING_FORCE',
      FORGING_PRESSING_FORCE: 'FORGING_PRESSING_FORCE',
      OPTIMAL_FOR_R1: 'OPTIMAL_FOR_R1',
      OPTIMAL_FOR_R4: 'OPTIMAL_FOR_R4',
      SHEET_THICKNESS_LEGEND: 'SHEET_THICKNESS_LEGEND',
      DIE_WIDTH_LEGEND: 'DIE_WIDTH_LEGEND',
      BENDING_LENGTH_LEGEND: 'BENDING_LENGTH_LEGEND',
      UPPER_TOOL_LEGEND: 'UPPER_TOOL_LEGEND',
    },
    TOOLTIPS: {
      DIE_WIDTH_CALCULATION_TOOL_TIP: 'DIE_WIDTH_CALCULATION_TOOL_TIP',
      CASE_HEIGHT_TOOLTIP: 'CASE_HEIGHT_TOOLTIP',
      MOUNT_HEIGHT_TOOLTIP: 'MOUNT_HEIGHT_TOOLTIP',
    },
    MOUNT_HEIGHT_CALCULATION_TYPE: {
      REMAINING_OPENING: MountHeightCalculationType.REMAINING_OPENING.toString(),
      UPPER_TOOL_HEIGHT: MountHeightCalculationType.UPPER_TOOL_HEIGHT.toString(),
      MACHINE_SELECTION: MountHeightCalculationType.MACHINE_SELECTION.toString(),
    },
    TOOL_CATEGORY: {
      ADAPTER: ToolCategory.ADAPTER.toString(),
      DIE: ToolCategory.DIE.toString(),
      INSERT: ToolCategory.INSERT.toString(),
      LIGHTWEIGHT_TOOLING: ToolCategory.LIGHTWEIGHT_TOOLING.toString(),
      PUNCH: ToolCategory.PUNCH.toString(),
    },
    UPPER_TOOLS: {
      H120: 'H120',
      H140: 'H140',
      H220: 'H220',
      H240: 'H240',
      H300: 'H300',
      H100: 'H100',
      H90: 'H90',
    },
  },
};
