import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ToolWeightCalculationService {
  private calculationResult: BehaviorSubject<number | undefined> = new BehaviorSubject<
    number | undefined
  >(undefined);
  public calculationResult$ = this.calculationResult.asObservable();
  constructor() {}

  public calculate(toolSpecificWeight: number, toolLength: number) {
    const finalWeight = (+toolSpecificWeight * +toolLength) / 1000;
    this.calculationResult.next(finalWeight);
  }
}
