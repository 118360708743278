import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  ALUMINUM_SHEET_THICKNESSES,
  STAINLESS_STEEL_SHEET_THICKNESSES,
  STEEL_SHEET_THICKNESSES,
} from '../data/calculated-sheet-thickness';
import { Material } from '../model/material';
import { SheetThicknessCalculationResult } from '../model/sheet-thickness-calculation-result';

@Injectable()
export class SheetThicknessCalculationService {
  private calculationResult: BehaviorSubject<SheetThicknessCalculationResult | undefined> =
    new BehaviorSubject<SheetThicknessCalculationResult | undefined>(undefined);
  public calculationResult$ = this.calculationResult.asObservable();

  constructor() {}

  public calculate(material: Material, gauge: number): void {
    let errorMessage = undefined;
    let sheetThickness: number | undefined;

    if (gauge < 1 || gauge > 26) {
      errorMessage = 'gauge must be between 1 and 26';
    }

    switch (material) {
      case Material.STEEL:
        sheetThickness = STEEL_SHEET_THICKNESSES.get(gauge);
        break;
      case Material.STAINLESS_STEEL:
        sheetThickness = STAINLESS_STEEL_SHEET_THICKNESSES.get(gauge);
        break;
      case Material.ALUMINUM:
        sheetThickness = ALUMINUM_SHEET_THICKNESSES.get(gauge);
        break;
    }

    this.calculationResult.next({
      label: 'CalculationDone!',
      error: errorMessage,
      sheetThickness: sheetThickness,
    });
  }

  public clearResult(): void {
    this.calculationResult.next(undefined);
  }
}
