import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lsb-die-width-picture',
  templateUrl: './die-width-picture.component.svg',
  styleUrls: [],
})
export class DieWidthPictureComponent implements OnInit {
  @Input() dieWidth: string;
  @Input() sheetThickness: string;
  constructor() {}

  ngOnInit(): void {}
}
