import { MediaType, Part, PartConfigurationDetails, PartDetails } from '../types';
import { PartInfo } from '../types/part-info';
import { MOCK_KEYWORDS } from './base.mock-data';
import { MOCK_BENDING_PRINCIPLES } from './welding-principles.mock-data';

const sampleVideosBaseUrl = 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample';

export const MOCK_PART_CONFIGURATION_DETAILS: PartConfigurationDetails = {
  availableForCalculation: true,
  amountSeams: 9,
  batchSize: 250,
  fixtureCostsInEur_arc: 6000,
  fixtureCostsInEur_laser: 6000,
  loadingTimeInSec_arc: 60,
  loadingTimeInSec_laser: 0,
  measureTimeInSec_arc: 0,
  measureTimeInSec_laser: 0,
  programmingTimeInSec_arc: 10,
  programmingTimeInSec_laser: 60,
  reworkTimeInSec_arc: 120,
  reworkTimeInSec_laser: 0,
  robotWelding: true,
  setupTimePerBatchInSec_arc: 5,
  setupTimePerBatchInSec_laser: 10,
  tackingTimeInSec_arc: 60,
  tackingTimeInSec_laser: 0,
  totalPieces: 1000,
  totalSeamLengthInMillimeters: 0,
  weldingSpeedInMillimetersPerSec_arc: 1,
  weldingSpeedInMillimetersPerSec_laser: 3,
};

/** Some video URLs borrowed from https://gist.github.com/jsturgis/3b19447b304616f18657 */
export const MOCK_VIDEOS = [
  {
    src: `${sampleVideosBaseUrl}/BigBuckBunny.mp4`,
    thumb: `${sampleVideosBaseUrl}/images/BigBuckBunny.jpg`,
  },
  {
    src: `${sampleVideosBaseUrl}/ForBiggerEscapes.mp4`,
    thumb: `${sampleVideosBaseUrl}/images/ForBiggerEscapes.jpg`,
  },
  {
    src: `${sampleVideosBaseUrl}/ForBiggerBlazes.mp4`,
    thumb: `${sampleVideosBaseUrl}/images/ForBiggerBlazes.jpg`,
  },
  {
    src: `${sampleVideosBaseUrl}/ForBiggerMeltdowns.mp4`,
    thumb: `${sampleVideosBaseUrl}/images/ForBiggerMeltdowns.jpg`,
  },
];

export const MOCK_PART_CONSOLE_COVER: Part = {
  id: 'console-cover',
  category: 'Abdeckung',
  name: 'Konsolenabdeckung',
  image: 'https://via.placeholder.com/150',
  material: 'Baustahl',
  partsCount: 4,
  jointsCount: 9,
  bendingsCount: 5,
  keywords: [
    ...MOCK_KEYWORDS,
    { id: 'B', displayText: 'Tiefschweißnaht' },
    { id: 'C', displayText: 'Kehlnaht' },
    { id: 'D', displayText: 'Biegetoleranzen' },
    { id: 'E', displayText: 'Mantel-Deckel-Prinzip' },
  ],
  requirements: ['Wasserdicht', 'Entnehmbarer Kern'],
  seamGeometry: [{ id: 'C', displayText: 'Kehlnaht' }],
  sheetThicknessInMillimeters: 1.5,
  ...MOCK_PART_CONFIGURATION_DETAILS,
};

export const MOCK_PART_INFO_SUCKER_ADAPTER: PartInfo = {
  id: 'sucker-adapter',
  name: 'Absaugflansch',
  image: 'https://via.placeholder.com/150/FF0000',
};

export const MOCK_PART_SUCKER_ADAPTER: Part = {
  ...MOCK_PART_INFO_SUCKER_ADAPTER,
  category: 'Abdeckung',
  material: 'Leichtmetall',
  partsCount: 4,
  jointsCount: 5,
  bendingsCount: 1,
  keywords: [
    MOCK_KEYWORDS[0],
    { id: 'G', displayText: 'Ecknaht' },
    { id: 'D', displayText: 'Biegetoleranzen' },
    { id: 'E', displayText: 'Mantel-Deckel-Prinzip' },
  ],
  requirements: ['Wasserdicht', 'Kratzerschutz'],
  seamGeometry: [{ id: 'G', displayText: 'Ecknaht' }],
  sheetThicknessInMillimeters: 2,
  ...MOCK_PART_CONFIGURATION_DETAILS,
};

export const MOCK_PART_WATER_TANK: Part = {
  id: 'water-tank',
  category: 'Tank',
  name: 'Wassertank',
  image: 'https://via.placeholder.com/150',
  material: 'Baustahl',
  partsCount: 3,
  jointsCount: 10,
  bendingsCount: 4,
  keywords: [
    { id: 'B', displayText: 'Tiefschweißnaht' },
    { id: 'F', displayText: 'Blechvorrichtung' },
    { id: 'G', displayText: 'I-Naht' },
    { id: 'H', displayText: 'Rundnaht' },
  ],
  requirements: ['Strukturbauteil', 'Mehrspannvorrichtung'],
  seamGeometry: [{ id: 'G', displayText: 'Ecknaht' }],
  sheetThicknessInMillimeters: 3,
  ...MOCK_PART_CONFIGURATION_DETAILS,
};

export const MOCK_PARTS: Part[] = [
  MOCK_PART_CONSOLE_COVER,
  MOCK_PART_SUCKER_ADAPTER,
  MOCK_PART_WATER_TANK,
];

export const MOCK_PART_DETAILS: PartDetails = {
  ...MOCK_PART_CONSOLE_COVER,
  description:
    'Als Gregor Samsa eines Morgens aus unruhigen Träumen erwachte, fand er sich in seinem Bett zu einem ungeheueren Ungeziefer verwandelt. Er lag auf seinem panzerartig harten Rücken und sah, wenn er den Kopf ein wenig hob, seinen gewölbten, braunen, von bogenförmigen Versteifungen geteilten Bauch, auf dessen Höhe sich die Bettdecke, zum gänzlichen Niedergleiten bereit, kaum noch erhalten konnte. Seine vielen, im Vergleich zu seinem sonstigen Umfang kläglich dünnen Beine flimmerten ihm hilflos vor den Augen.',
  gallery: [
    {
      type: MediaType.IMAGE,
      src: 'https://via.placeholder.com/300/0000FF',
      description: 'Image 1 Description with *bold* text\nand new lines\nline3',
    },
    {
      type: MediaType.IMAGE,
      src: 'https://via.placeholder.com/300/00FF00',
      description: 'Image 2 Description',
    },
    {
      type: MediaType.IMAGE,
      src: 'https://via.placeholder.com/300/FF0000',
      description: 'Image 3 Description',
    },
  ],
  cadFileUrl: 'https://via.placeholder.com/300',
  relatedParts: [MOCK_PART_INFO_SUCKER_ADAPTER],
  weldingPrinciples: MOCK_BENDING_PRINCIPLES,
  cadFileUrlFixture: 'https://via.placeholder.com/400',
  fixtureGallery: [
    {
      type: MediaType.IMAGE,
      src: 'https://via.placeholder.com/400/0000FF',
      description: '*Image* Fixture 1\nDescription',
    },
    {
      type: MediaType.VIDEO,
      src: MOCK_VIDEOS[0].src,
    },
    {
      type: MediaType.IMAGE,
      src: 'https://via.placeholder.com/400/FF0000',
      description: 'Image Fixture 3 Description',
    },
  ],
  relatedFixturePrinciples: MOCK_BENDING_PRINCIPLES,
};
