import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { TranslationHelper } from '../../shared/helpers';
import { checkForNegativeValue } from '../helpers/verification';
import { BendingForceCalculationType } from '../model/bending-force-calculation-type';
import { PressingForceCalculationResult } from '../model/pressing-force-calculation-result';

/**
 * This service is used to do the calculations for the pressing force.
 */
@Injectable()
export class PressingForceCalculationService {
  private calculationResult: BehaviorSubject<PressingForceCalculationResult | undefined> =
    new BehaviorSubject<PressingForceCalculationResult | undefined>(undefined);
  public calculationResult$ = this.calculationResult.asObservable();

  constructor(
    private translations: TranslationHelper,
    private translateService: TranslateService,
  ) {}

  public calculatePressingForce(
    tensileStrength: number,
    sheetThickness: number,
    upperToolRadius: number,
    dieWidth: number,
    bendingLength: number,
    bendingAngle: number,
  ): void {
    const errorMessages = checkForNegativeValue(
      this.translations,
      this.translateService,
      undefined,
      tensileStrength,
      sheetThickness,
      upperToolRadius,
      dieWidth,
      bendingLength,
      bendingAngle,
    );
    if (errorMessages.length > 0) {
      this.calculationResult.next({
        type: BendingForceCalculationType.PRESSING_FORCE,
        pressingForce: 0,
        sheetThickness: sheetThickness,
        dieWidth: dieWidth,
        bendingLength: bendingLength,
        errorMessages,
      });
      return;
    }

    const pressingForce =
      (1.33 * (bendingLength / 1000) * tensileStrength * (sheetThickness * sheetThickness)) /
      (dieWidth - 2 * (Math.cos(this.degToRad(bendingAngle) / 2) * upperToolRadius));

    this.calculationResult.next({
      type: BendingForceCalculationType.PRESSING_FORCE,
      pressingForce: pressingForce,
      sheetThickness: sheetThickness,
      dieWidth: dieWidth,
      bendingLength: bendingLength,
      errorMessages: undefined,
    });
  }

  public calculateSheetThickness(
    tensileStrength: number,
    pressingForce: number,
    upperToolRadius: number,
    dieWidth: number,
    bendingLength: number,
    bendingAngle: number,
  ): void {
    const errorMessages = checkForNegativeValue(
      this.translations,
      this.translateService,
      pressingForce,
      tensileStrength,
      undefined,
      upperToolRadius,
      dieWidth,
      bendingLength,
      bendingAngle,
    );
    if (errorMessages.length > 0) {
      this.calculationResult.next({
        type: BendingForceCalculationType.SHEET_THICKNESS,
        pressingForce: pressingForce,
        sheetThickness: 0,
        dieWidth: dieWidth,
        bendingLength: bendingLength,
        errorMessages,
      });
      return;
    }

    const sheetThickness = Math.sqrt(
      (pressingForce *
        (dieWidth - 2 * (Math.cos(this.degToRad(bendingAngle) / 2) * upperToolRadius))) /
        (1.33 * (bendingLength / 1000) * tensileStrength),
    );

    this.calculationResult.next({
      type: BendingForceCalculationType.SHEET_THICKNESS,
      pressingForce: pressingForce,
      sheetThickness: sheetThickness,
      dieWidth: dieWidth,
      bendingLength: bendingLength,
      errorMessages: undefined,
    });
  }

  public calculateBendingLength(
    tensileStrength: number,
    pressingForce: number,
    sheetThickness: number,
    upperToolRadius: number,
    dieWidth: number,
    bendingAngle: number,
  ): void {
    const errorMessages = checkForNegativeValue(
      this.translations,
      this.translateService,
      pressingForce,
      tensileStrength,
      sheetThickness,
      upperToolRadius,
      dieWidth,
      undefined,
      bendingAngle,
    );
    if (errorMessages.length > 0) {
      this.calculationResult.next({
        type: BendingForceCalculationType.BENDING_LENGTH,
        pressingForce: pressingForce,
        sheetThickness: sheetThickness,
        dieWidth: dieWidth,
        bendingLength: 0,
        errorMessages,
      });
      return;
    }

    const bendingLength =
      (1000 *
        (pressingForce *
          (dieWidth - 2 * Math.cos(this.degToRad(bendingAngle) / 2) * upperToolRadius))) /
      (1.33 * tensileStrength * (sheetThickness * sheetThickness));

    this.calculationResult.next({
      type: BendingForceCalculationType.BENDING_LENGTH,
      pressingForce: pressingForce,
      sheetThickness: sheetThickness,
      dieWidth: dieWidth,
      bendingLength: bendingLength,
      errorMessages: undefined,
    });
  }

  private degToRad(numberInDeg: number): number {
    const numberInRad = numberInDeg * (Math.PI / 180);
    return numberInRad;
  }
}
