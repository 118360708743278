<div class="input-container">
  <span class="dropdown-label">
    {{ translations.CALCULATIONS.INPUT_LABELS.MATERIAL | translate }}
  </span>
  <xui-dropdown
    #materialDropDown
    class="drop-down"
    [label]="selectedMaterialName()"
    data-ngtx="sheet-thickness:material"
  >
    <xui-dropdown-item
      *ngFor="let material of materials"
      (click)="selectedMaterial = material.id; materialDropDown.close(); clearResult()"
    >
      {{ material?.name }}
    </xui-dropdown-item>
  </xui-dropdown>

  <xui-input-field
    data-ngtx="length"
    label="{{ translations.CALCULATIONS.INPUT_LABELS.LENGTH | translate }}"
    [(text)]="lengthValue.value"
  >
    <xui-value #lengthValue lsbValidateRequired [(binding)]="length" [fallbackValue]="length">
      <xui-length-converter metricTargetUnit="mm" imperialTargetUnit="inch"></xui-length-converter>
      <xui-number-formatter [maxDecimals]="2" [unit]="lengthValue.unit"></xui-number-formatter>
    </xui-value>
  </xui-input-field>

  <xui-input-field
    data-ngtx="width"
    label="{{ translations.CALCULATIONS.INPUT_LABELS.WIDTH | translate }}"
    [(text)]="widthValue.value"
  >
    <xui-value #widthValue lsbValidateRequired [(binding)]="width" [fallbackValue]="width">
      <xui-length-converter metricTargetUnit="mm" imperialTargetUnit="inch"></xui-length-converter>
      <xui-number-formatter [maxDecimals]="2" [unit]="widthValue.unit"></xui-number-formatter>
    </xui-value>
  </xui-input-field>

  <xui-input-field
    data-ngtx="sheetThickness"
    label="{{ translations.CALCULATIONS.INPUT_LABELS.SHEET_THICKNESS | translate }}"
    [(text)]="sheetThicknessValue.value"
  >
    <xui-value
      #sheetThicknessValue
      lsbValidateRequired
      [(binding)]="sheetThickness"
      [fallbackValue]="sheetThickness"
    >
      <xui-length-converter metricTargetUnit="mm" imperialTargetUnit="inch"></xui-length-converter>
      <xui-number-formatter
        [maxDecimals]="2"
        [unit]="sheetThicknessValue.unit"
      ></xui-number-formatter>
    </xui-value>
  </xui-input-field>

  <span class="calculationError" *ngIf="calculationError$ | async as error">
    {{ error }}
  </span>

  <!-- Calculate -->
  <div class="calculate-button">
    <xui-button
      (click)="calculate()"
      xuiClickUsageTracking="{{ uiElementIds.CALCULATE_SHEET_THICKNESS }}"
    >
      {{ translations.CALCULATIONS.INPUT_LABELS.CALCULATE | translate }}
    </xui-button>
  </div>

  <div
    *ngIf="calculationService.calculationResult$ | async as calculationResult"
    class="calculation-result"
  >
    <div *ngIf="!calculationResult.error">
      <xui-caption-label
        class="calculation-result-label"
        [caption]="translations.CALCULATIONS.RESULT_LABELS.SHEET_WEIGHT | translate"
      >
        <xui-value #sheetWeight [(binding)]="calculationResult.sheetWeight" [fallbackValue]="0">
          <xui-weight-converter
            metricTargetUnit="kg"
            imperialTargetUnit="lbs"
          ></xui-weight-converter>
          <xui-number-formatter [maxDecimals]="2" [unit]="sheetWeight.unit"></xui-number-formatter>
        </xui-value>
        {{ sheetWeight.value }}
      </xui-caption-label>
    </div>
  </div>
</div>
