/*
Verbleibende Öffnung - Remaining opening
Oberwerkzeughöhe - Upper tool height
Maschinenauswahl - Machine selection
 */

export enum MountHeightCalculationType {
  REMAINING_OPENING = 'REMAINING_OPENING',
  UPPER_TOOL_HEIGHT = 'UPPER_TOOL_HEIGHT',
  MACHINE_SELECTION = 'MACHINE_SELECTION',
}
