import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MountHeightCalculationResult } from '../model/mount-height/mount-height-calculation-result';
import { MountHeightPossibilityMark } from '../model/mount-height/mount-height-possibility-mark';

@Injectable()
export class MountHeightCalculationService {
  // NOTE: MachineHeight = (UpperToolHeight + AdapterHeight) + DesiredRemainingOpening + BottomTool

  private calculationResult: BehaviorSubject<MountHeightCalculationResult | undefined> =
    new BehaviorSubject<MountHeightCalculationResult | undefined>(undefined);

  public calculationResult$ = this.calculationResult.asObservable();

  constructor() {}

  public calculateRemainOpening(
    machineHeight: number,
    adapterHeight: number,
    upperToolHeight: number,
    bottomToolHeight: number,
  ) {
    const desiredRemainingOpening =
      machineHeight - adapterHeight - upperToolHeight - bottomToolHeight;
    const possibility = this.possibilityRate(desiredRemainingOpening);

    this.calculationResult.next({
      remainOpening: desiredRemainingOpening,
      possibility,
    });
  }

  public calculateUpperToolHeight(
    machineHeight: number,
    bottomToolHeight: number,
    desiredRemainingOpening: number,
  ) {
    const upperToolHeight = machineHeight - desiredRemainingOpening - bottomToolHeight;
    const possibility = this.possibilityRate(upperToolHeight);
    this.calculationResult.next({
      upperToolHeight,
      possibility,
    });
  }

  public calculateMachineMinMountHeight(
    adapterHeight: number,
    upperToolHeight: number,
    bottomToolHeight: number,
    desiredRemainingOpening: number,
  ) {
    const machineMinimumMountHeight =
      upperToolHeight + adapterHeight + desiredRemainingOpening + bottomToolHeight;
    const possibility = this.possibilityRate(machineMinimumMountHeight);
    this.calculationResult.next({
      machineMinimumMountHeight,
      possibility,
    });
  }

  private possibilityRate = (dimension: number): MountHeightPossibilityMark => {
    if (dimension <= 5) {
      return MountHeightPossibilityMark.NOT_POSSIBLE;
    } else if (dimension > 5 && dimension <= 30) {
      return MountHeightPossibilityMark.NOT_IDEAL;
    }
    return MountHeightPossibilityMark.POSSIBLE;
  };
}
