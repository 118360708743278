import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { Media, MediaType, PartDetails, WeldingPrinciple } from '../../../../../shared';
import { TranslationHelper } from '../../shared/helpers/translation.helpers';
import { hasType } from '../../shared/helpers/type-helper';
import { CadUrlPipe } from '../../shared/pipe/cad-url.pipe';
import { PartsService } from '../../shared/services/parts/parts.service';
import { UiElementIds } from '../../shared/usage-tracking/ui-element-ids';

@Component({
  selector: 'lsb-part-detail',
  templateUrl: './part-detail.component.html',
  styleUrls: ['./part-detail.component.scss'],
})
export class PartDetailComponent implements OnInit {
  public readonly uiElementIds = UiElementIds;

  public partDetails$: Observable<PartDetails>;
  public relatedWeldingPrinciples$: Observable<WeldingPrinciple[]> | undefined;
  public updatePrinciples$: Subject<void> = new Subject();
  public isAvailableForCalculation$ = of(false);
  public selectedMediaIndex = 0;
  public relatedWeldingPrinciplesToShow = 4;

  private cadUrl: string;
  private cadFileName: string;

  constructor(
    public translations: TranslationHelper,
    private route: ActivatedRoute,
    private router: Router,
    private partsService: PartsService,
  ) {}

  ngOnInit(): void {
    this.partDetails$ = this.route.paramMap.pipe(
      map((params) => params.get('id')),
      filter(hasType('string')),
      switchMap((partId) => this.partsService.getPartDetails(partId)),
      tap((part) => {
        const cadUrlPipe = new CadUrlPipe();
        this.selectedMediaIndex = 0;

        this.cadUrl = cadUrlPipe.transform(part.cadFileUrl);
        this.cadFileName = `${part.name}.STEP`;
      }),
    );

    this.isAvailableForCalculation$ = this.partDetails$.pipe(
      map((part) => part.availableForCalculation),
    );

    const principles$ = this.partDetails$.pipe(
      filter((details) => details.weldingPrinciples !== undefined),
      map((details) => details.weldingPrinciples),
    );

    const initialPrinciples$ = principles$.pipe(
      map((principles) => principles.slice(0, this.relatedWeldingPrinciplesToShow)),
    );

    // Wait for update trigger to fetch remaining related principles
    const remainingParts$ = combineLatest([this.updatePrinciples$, principles$]).pipe(
      map(([, principles]) => principles.slice(this.relatedWeldingPrinciplesToShow)),
    );

    this.relatedWeldingPrinciples$ = combineLatest([
      initialPrinciples$.pipe(startWith([])),
      remainingParts$.pipe(startWith([])),
    ]).pipe(map(([initialParts, remainingParts]) => [...initialParts, ...remainingParts]));
  }

  public asImageMedia(src: string): Media {
    return { src, type: MediaType.IMAGE };
  }

  public selectRelatedPart(partId: string) {
    this.router.navigate(['parts', partId]);
  }

  public downloadCAD(): void {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', this.cadUrl);
    link.setAttribute('download', this.cadFileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public selectWeldingPrinciple(principleId: string) {
    this.router.navigate(['bending-principles/details', principleId]);
  }
}
