import { Material } from '../model/material';

export const BEND_INNER_RADIUS_CALCULATIONS = [
  {
    material: Material.STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 30,
    bendInnerRadius: 0.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 60,
    bendInnerRadius: 0.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 90,
    bendInnerRadius: 1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 120,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 30,
    bendInnerRadius: 0.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 60,
    bendInnerRadius: 0,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 90,
    bendInnerRadius: 0.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 120,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 0.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 0.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 0.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 0.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 0.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 0.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 2.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 2.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 2.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 2.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 2.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 3.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 1.75,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 1.75,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 4.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 3.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 4.25,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 5.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 0.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 0.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 2.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 2.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 3.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 2.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 2.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 2.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 2.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 3.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 0.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 0.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 2.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 2.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 2.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 2.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 2.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 3.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 2.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 2.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 2.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 2.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 3.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 2.25,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 2.25,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 3.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 3.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 3.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 4.75,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.25,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 1.25,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 21,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 2.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 3.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 3.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 2.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 3.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 4.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 3.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 5.75,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 2.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 3.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 2.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 2.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 3.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 3.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 3.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 4.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 1.25,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 1.75,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 2.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 3.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 2.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 2.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 3.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 4.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 3.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 4.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 5.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 4.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 4.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 6.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 2.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 3.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 4.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 3.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 4.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 5.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 4.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 5.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 6.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 3.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 3.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 4.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 4.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 4.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 4.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 4.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 5.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 5.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 5.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 6.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 1.75,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 2.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 4.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 3.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 3.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 3.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 5.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 4.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 4.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 4.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 6.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 5.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 6.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 9.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 3.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 3.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 3.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 4.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 3.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 3.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 4.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 5.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 4.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 4.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 6.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 5.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 6.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 8.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 3.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 3.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 3.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 4.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 4.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 4.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 4.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 5.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 5.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 4.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 5.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 6.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 6.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 5.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 6.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 8.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 3.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 3.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 3.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 5.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 4.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 4.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 4.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 6.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 5.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 6.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 8.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 4.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 4.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 5.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 4.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 4.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 5.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 6.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 6.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 5.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 6.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 8.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 3.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 3.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 5.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 4.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 4.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 4.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 5.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 7.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 4.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 4.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 4.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 5.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 4.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 4.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 6.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 6.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 7.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 4.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 3.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 4.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 6.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 7.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 7.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 9.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 4.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 4.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 5.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 6.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 6.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 7.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 9.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 3.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 3.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 4.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 6.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 7.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 7.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 9.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 4.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 4.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 5.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 6.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 6.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 8.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 7.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 9.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 5.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 5.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 5.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 6.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 8.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 7.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 9.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 2.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 4.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 6.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 5.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 8.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 7.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 9.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 10.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 9.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 10.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 10,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 10.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 10,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 10.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 10.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 10.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 12.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 4.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 4.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 5.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 7.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 6.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 8.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 7.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 10,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 9.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 11.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 6.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 7.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 6.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 8.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 6.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 7.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 6.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 10.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 6.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 9.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6,
    upperToolRadius: 6.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 11.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 10.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 9.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 10.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 10.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 10.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 10,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 10.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 10.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 10.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 12.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 4.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 4.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 7.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 6.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 8.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 7.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 9.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 9.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 11.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 8.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 8.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 8.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 9.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 8.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 8.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 8.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 10.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 8.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 9.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 8.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 11.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 10.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 12.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 70,
    bendingAngle: 90,
    bendInnerRadius: 11.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 70,
    bendingAngle: 120,
    bendInnerRadius: 13.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 12.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 15.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 13.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 17.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 7.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 10.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 8.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 10.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 70,
    bendingAngle: 90,
    bendInnerRadius: 10.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 70,
    bendingAngle: 120,
    bendInnerRadius: 13.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 11.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 14.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 11.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 9.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 12.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 70,
    bendingAngle: 90,
    bendInnerRadius: 10.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 70,
    bendingAngle: 120,
    bendInnerRadius: 13.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 12.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 15.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 6.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 10.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 8.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 12.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 70,
    bendingAngle: 90,
    bendInnerRadius: 10.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 70,
    bendingAngle: 120,
    bendInnerRadius: 13.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 11.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 14.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 11.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 13.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 70,
    bendingAngle: 90,
    bendInnerRadius: 11.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 70,
    bendingAngle: 120,
    bendInnerRadius: 14.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 12.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 15.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 14,
  },
  {
    material: Material.STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 17.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 10,
    upperToolRadius: 10.0,
    sinkWidth: 70,
    bendingAngle: 90,
    bendInnerRadius: 11.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 10,
    upperToolRadius: 10.0,
    sinkWidth: 70,
    bendingAngle: 120,
    bendInnerRadius: 15,
  },
  {
    material: Material.STEEL,
    sheetThickness: 10,
    upperToolRadius: 10.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 12.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 10,
    upperToolRadius: 10.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 16.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 10,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 14.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 10,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 17.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 9.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 9.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 12.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 70,
    bendingAngle: 90,
    bendInnerRadius: 10.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 70,
    bendingAngle: 120,
    bendInnerRadius: 14,
  },
  {
    material: Material.STEEL,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 12,
  },
  {
    material: Material.STEEL,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 15.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12,
    upperToolRadius: 10.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 15.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12,
    upperToolRadius: 10.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 19.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 18.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 23.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 17,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 21.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 15,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 19.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 18.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 23.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 70,
    bendingAngle: 90,
    bendInnerRadius: 7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 70,
    bendingAngle: 120,
    bendInnerRadius: 7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 12,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 16.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 13.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 17.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 10.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 15.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 10.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 20,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 18.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 23.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 16.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 21.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 15,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 19.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 18.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 23.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 12.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 16,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 13.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 18.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 14,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 14,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 14,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 14,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 14,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 14,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 18.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 24.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 17.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 20.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 19.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 23.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15,
    upperToolRadius: 20.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 21.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15,
    upperToolRadius: 20.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 24.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 20.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 22.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 22.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 26.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 15.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 20.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 18.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 23.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 18.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 21.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 13.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 18.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 18.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 24.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 16.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 20.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 19.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 23.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 20.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 21.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 20.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 25.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 20,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 22.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 21.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 23.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 15.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 21.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 18.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 24.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 17.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 20.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 14.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 19.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 16,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 22,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 18.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 24.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 16.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 20.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 14.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 21,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 10.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 23.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 21.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 25.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 20.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 21.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 19.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 22.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 15.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 21.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 18.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 23.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 18.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 22.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 13.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 19.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 19,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 19,
  },
  {
    material: Material.STEEL,
    sheetThickness: 19,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 22,
  },
  {
    material: Material.STEEL,
    sheetThickness: 19,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 19.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 19,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 23.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 19,
    upperToolRadius: 10.0,
    sinkWidth: 180,
    bendingAngle: 90,
    bendInnerRadius: 27.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 19,
    upperToolRadius: 10.0,
    sinkWidth: 180,
    bendingAngle: 120,
    bendInnerRadius: 34,
  },
  {
    material: Material.STEEL,
    sheetThickness: 19,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 19.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 19,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 20.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 19,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 23.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 19,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 25.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 19,
    upperToolRadius: 20.0,
    sinkWidth: 180,
    bendingAngle: 90,
    bendInnerRadius: 27.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 19,
    upperToolRadius: 20.0,
    sinkWidth: 180,
    bendingAngle: 120,
    bendInnerRadius: 34.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 19,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 18.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 19,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 21,
  },
  {
    material: Material.STEEL,
    sheetThickness: 19,
    upperToolRadius: 4.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 20.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 19,
    upperToolRadius: 4.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 23.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 19,
    upperToolRadius: 4.0,
    sinkWidth: 180,
    bendingAngle: 90,
    bendInnerRadius: 27,
  },
  {
    material: Material.STEEL,
    sheetThickness: 19,
    upperToolRadius: 4.0,
    sinkWidth: 180,
    bendingAngle: 120,
    bendInnerRadius: 34,
  },
  {
    material: Material.STEEL,
    sheetThickness: 20,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 18,
  },
  {
    material: Material.STEEL,
    sheetThickness: 20,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 20.5,
  },
  {
    material: Material.STEEL,
    sheetThickness: 20,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 24.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 20,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 24.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 20,
    upperToolRadius: 10.0,
    sinkWidth: 180,
    bendingAngle: 90,
    bendInnerRadius: 27.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 20,
    upperToolRadius: 10.0,
    sinkWidth: 180,
    bendingAngle: 120,
    bendInnerRadius: 34.7,
  },
  {
    material: Material.STEEL,
    sheetThickness: 20,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 20.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 20,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 21.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 20,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 21.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 20,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 23.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 20,
    upperToolRadius: 20.0,
    sinkWidth: 180,
    bendingAngle: 90,
    bendInnerRadius: 27.9,
  },
  {
    material: Material.STEEL,
    sheetThickness: 20,
    upperToolRadius: 20.0,
    sinkWidth: 180,
    bendingAngle: 120,
    bendInnerRadius: 34.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 20,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 18.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 20,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 21.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 20,
    upperToolRadius: 4.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 20,
  },
  {
    material: Material.STEEL,
    sheetThickness: 20,
    upperToolRadius: 4.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 24.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 20,
    upperToolRadius: 4.0,
    sinkWidth: 180,
    bendingAngle: 90,
    bendInnerRadius: 27.3,
  },
  {
    material: Material.STEEL,
    sheetThickness: 20,
    upperToolRadius: 4.0,
    sinkWidth: 180,
    bendingAngle: 120,
    bendInnerRadius: 34.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 25,
    upperToolRadius: 10.0,
    sinkWidth: 200,
    bendingAngle: 90,
    bendInnerRadius: 27.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 25,
    upperToolRadius: 10.0,
    sinkWidth: 200,
    bendingAngle: 120,
    bendInnerRadius: 31.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 25,
    upperToolRadius: 10.0,
    sinkWidth: 220,
    bendingAngle: 90,
    bendInnerRadius: 28.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 25,
    upperToolRadius: 10.0,
    sinkWidth: 220,
    bendingAngle: 120,
    bendInnerRadius: 34.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 25,
    upperToolRadius: 10.0,
    sinkWidth: 240,
    bendingAngle: 90,
    bendInnerRadius: 29.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 25,
    upperToolRadius: 10.0,
    sinkWidth: 240,
    bendingAngle: 120,
    bendInnerRadius: 36.4,
  },
  {
    material: Material.STEEL,
    sheetThickness: 25,
    upperToolRadius: 20.0,
    sinkWidth: 200,
    bendingAngle: 90,
    bendInnerRadius: 30,
  },
  {
    material: Material.STEEL,
    sheetThickness: 25,
    upperToolRadius: 20.0,
    sinkWidth: 200,
    bendingAngle: 120,
    bendInnerRadius: 32.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 25,
    upperToolRadius: 20.0,
    sinkWidth: 220,
    bendingAngle: 90,
    bendInnerRadius: 28.2,
  },
  {
    material: Material.STEEL,
    sheetThickness: 25,
    upperToolRadius: 20.0,
    sinkWidth: 220,
    bendingAngle: 120,
    bendInnerRadius: 33.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 25,
    upperToolRadius: 20.0,
    sinkWidth: 240,
    bendingAngle: 90,
    bendInnerRadius: 30.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 25,
    upperToolRadius: 20.0,
    sinkWidth: 240,
    bendingAngle: 120,
    bendInnerRadius: 37.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 25,
    upperToolRadius: 4.0,
    sinkWidth: 200,
    bendingAngle: 90,
    bendInnerRadius: 28.6,
  },
  {
    material: Material.STEEL,
    sheetThickness: 25,
    upperToolRadius: 4.0,
    sinkWidth: 200,
    bendingAngle: 120,
    bendInnerRadius: 30.8,
  },
  {
    material: Material.STEEL,
    sheetThickness: 25,
    upperToolRadius: 4.0,
    sinkWidth: 220,
    bendingAngle: 90,
    bendInnerRadius: 27,
  },
  {
    material: Material.STEEL,
    sheetThickness: 25,
    upperToolRadius: 4.0,
    sinkWidth: 220,
    bendingAngle: 120,
    bendInnerRadius: 33.1,
  },
  {
    material: Material.STEEL,
    sheetThickness: 25,
    upperToolRadius: 4.0,
    sinkWidth: 240,
    bendingAngle: 90,
    bendInnerRadius: 30,
  },
  {
    material: Material.STEEL,
    sheetThickness: 25,
    upperToolRadius: 4.0,
    sinkWidth: 240,
    bendingAngle: 120,
    bendInnerRadius: 37.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 30,
    bendInnerRadius: 0.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 60,
    bendInnerRadius: 0.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 90,
    bendInnerRadius: 1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 120,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 30,
    bendInnerRadius: 0.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 60,
    bendInnerRadius: 0.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 90,
    bendInnerRadius: 0.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 120,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 0.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 0.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 0.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 2.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 2.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 2.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 2.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 2.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 3.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 2.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 0.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 2.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 2.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 2.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 3.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 2.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 2.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 2.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 3.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 2.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 2.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 2.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 2.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 2.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 2.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 3.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 2.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 2.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 3.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 2.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 2.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 3.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 2.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 2.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 2.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 3.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 0.75,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 2.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 3.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 2.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 2.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 4.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 0.75,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 2.75,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 3.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 2.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 2.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 2.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 3.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 2.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 2.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 4.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 3.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 2.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 2.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 2.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 5.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 3.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 2.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 3.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 4.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 4.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 7.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 2.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 2.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 4.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 3.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 3.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 3.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 5.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 4.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 4.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 4.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 7.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 3.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 3.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 3.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 4.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 4.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 4.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 4.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 5.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 5.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 5.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 7.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 2.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 4.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 2.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 2.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 3.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 5.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 4.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 3.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 4.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 7.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 5.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 5.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 6.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 8.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 2.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 2.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 3.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 4.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 3.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 3.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 3.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 5.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 4.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 4.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 4.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 6.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 5.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 5.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 6.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 10.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 3.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 3.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 3.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 4.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 4.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 4.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 6.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 4.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 4.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 7.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 6.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 5.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 6.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 8.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 2.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 3.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 3.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 3.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 7.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 5.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 4.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 5.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 8.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 3.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 3.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 4.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 5.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 4.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 4.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 4.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 7.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 5.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 5.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 8.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 3.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 2.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 6.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 4.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 4.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 4.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 8.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 7.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 12.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 4.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 4.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 4.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 6.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 5.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 5.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 5.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 8.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 7.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 12.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 4.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 4.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 7.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 11.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 10.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 14.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 5.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 4.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 5.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 9.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 7.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 11.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 10.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 15.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 3.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 3.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 4.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 8.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 6.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 12.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 9.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 14.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 4.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 4.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 5.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 9.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 6.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 10.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 9.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 14.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 5.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 5.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 8.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 7.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 12.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 9.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 14.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 3.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 3.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 4.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 7.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 6.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 11.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 8.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 13.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 4.25,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 5.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 10.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 9.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 10.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 11.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 10.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 11.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 10.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 14,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 13.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 18.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 4.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 4.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 5.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 7.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 6.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 11.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 8.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 13.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 11.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 16.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 6.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 7.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 6.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 10.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 6.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 9.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 6.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 13,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 6.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 11.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6,
    upperToolRadius: 6.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 17.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6.4,
    upperToolRadius: 10.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 9.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6.4,
    upperToolRadius: 10.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 11.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6.4,
    upperToolRadius: 10.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 11.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6.4,
    upperToolRadius: 10.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 13.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6.4,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 13.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6.4,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 18.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 4.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 4.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 5.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 7.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 6.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 11.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 8.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 12.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 11.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 17.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6.4,
    upperToolRadius: 8.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 8.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6.4,
    upperToolRadius: 8.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 10.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6.4,
    upperToolRadius: 8.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 10,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6.4,
    upperToolRadius: 8.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 14.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6.4,
    upperToolRadius: 8.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 11.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 6.4,
    upperToolRadius: 8.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 18.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 4.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 12.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 16.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 70,
    bendingAngle: 90,
    bendInnerRadius: 14.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 70,
    bendingAngle: 120,
    bendInnerRadius: 20.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 15.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 23.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 19.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 27.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 7.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 11.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 10.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 15,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 70,
    bendingAngle: 90,
    bendInnerRadius: 12.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 70,
    bendingAngle: 120,
    bendInnerRadius: 19.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 15.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 24.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 9.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 13.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 10.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 16.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 70,
    bendingAngle: 90,
    bendInnerRadius: 13.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 70,
    bendingAngle: 120,
    bendInnerRadius: 19.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 15.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 23.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 7.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 11.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 9.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 14.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 70,
    bendingAngle: 90,
    bendInnerRadius: 11.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 70,
    bendingAngle: 120,
    bendInnerRadius: 18.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 13.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 21.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 11.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 16.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 70,
    bendingAngle: 90,
    bendInnerRadius: 12.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 70,
    bendingAngle: 120,
    bendInnerRadius: 19.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 14.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 21.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 17.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 27.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 10,
    upperToolRadius: 10.0,
    sinkWidth: 70,
    bendingAngle: 90,
    bendInnerRadius: 12.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 10,
    upperToolRadius: 10.0,
    sinkWidth: 70,
    bendingAngle: 120,
    bendInnerRadius: 19.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 10,
    upperToolRadius: 10.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 14.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 10,
    upperToolRadius: 10.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 22,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 10,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 16.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 10,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 27.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 7.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 13.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 9.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 13.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 70,
    bendingAngle: 90,
    bendInnerRadius: 11.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 70,
    bendingAngle: 120,
    bendInnerRadius: 18.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 13.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 22.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12,
    upperToolRadius: 10.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 17,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12,
    upperToolRadius: 10.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 28,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 23.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 37,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 21.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 35.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 17.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 27,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 24.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 36.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 12.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 20.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 15,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 24.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12.7,
    upperToolRadius: 10.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 17.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12.7,
    upperToolRadius: 10.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 26,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12.7,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 22.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12.7,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 34.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12.7,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 22.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12.7,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 35,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 16.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 27,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 22.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 35.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 12.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 20.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 14.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 22.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 20.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 34,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 19.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 31,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 26.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 38.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15,
    upperToolRadius: 20.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 24.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15,
    upperToolRadius: 20.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 33.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 23.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 32.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 28.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 40.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 15.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 25.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 21,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 31.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 22.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 32,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 13.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 20.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15.8,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 20.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15.8,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 32,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15.8,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 19.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15.8,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 31.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15.8,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 24,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15.8,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 37,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15.8,
    upperToolRadius: 20.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 24.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15.8,
    upperToolRadius: 20.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 33.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15.8,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 23,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15.8,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 31.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15.8,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 29.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15.8,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 40.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 15.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 25.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 20.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 31.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 19,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 32.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 13.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 23,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 15.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 24.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 20.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 34.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 19,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 31,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 14.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 23.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 20.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 26.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 24.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 33.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 22.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 31.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 19.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 24.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 15,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 23.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 20.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 35,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 18.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 30.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 13.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 22.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 19,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 17.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 19,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 28.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 19,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 24.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 19,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 34.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 19,
    upperToolRadius: 10.0,
    sinkWidth: 180,
    bendingAngle: 90,
    bendInnerRadius: 34,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 19,
    upperToolRadius: 10.0,
    sinkWidth: 180,
    bendingAngle: 120,
    bendInnerRadius: 52.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 19,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 21.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 19,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 28.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 19,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 24.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 19,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 35.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 19,
    upperToolRadius: 20.0,
    sinkWidth: 180,
    bendingAngle: 90,
    bendInnerRadius: 34.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 19,
    upperToolRadius: 20.0,
    sinkWidth: 180,
    bendingAngle: 120,
    bendInnerRadius: 53,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 19,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 24.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 19,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 28.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 19,
    upperToolRadius: 4.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 23.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 19,
    upperToolRadius: 4.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 35,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 19,
    upperToolRadius: 4.0,
    sinkWidth: 180,
    bendingAngle: 90,
    bendInnerRadius: 33.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 19,
    upperToolRadius: 4.0,
    sinkWidth: 180,
    bendingAngle: 120,
    bendInnerRadius: 50.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 20,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 18.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 20,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 26.8,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 20,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 25.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 20,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 33.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 20,
    upperToolRadius: 10.0,
    sinkWidth: 180,
    bendingAngle: 90,
    bendInnerRadius: 33.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 20,
    upperToolRadius: 10.0,
    sinkWidth: 180,
    bendingAngle: 120,
    bendInnerRadius: 53.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 20,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 18.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 20,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 28,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 20,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 23,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 20,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 35.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 20,
    upperToolRadius: 20.0,
    sinkWidth: 180,
    bendingAngle: 90,
    bendInnerRadius: 33.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 20,
    upperToolRadius: 20.0,
    sinkWidth: 180,
    bendingAngle: 120,
    bendInnerRadius: 52,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 20,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 18.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 20,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 25.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 20,
    upperToolRadius: 4.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 20.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 20,
    upperToolRadius: 4.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 31.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 20,
    upperToolRadius: 4.0,
    sinkWidth: 180,
    bendingAngle: 90,
    bendInnerRadius: 32.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 20,
    upperToolRadius: 4.0,
    sinkWidth: 180,
    bendingAngle: 120,
    bendInnerRadius: 50.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 25,
    upperToolRadius: 10.0,
    sinkWidth: 200,
    bendingAngle: 90,
    bendInnerRadius: 27.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 25,
    upperToolRadius: 10.0,
    sinkWidth: 200,
    bendingAngle: 120,
    bendInnerRadius: 40.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 25,
    upperToolRadius: 10.0,
    sinkWidth: 220,
    bendingAngle: 90,
    bendInnerRadius: 30.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 25,
    upperToolRadius: 10.0,
    sinkWidth: 220,
    bendingAngle: 120,
    bendInnerRadius: 49.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 25,
    upperToolRadius: 10.0,
    sinkWidth: 240,
    bendingAngle: 90,
    bendInnerRadius: 37.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 25,
    upperToolRadius: 10.0,
    sinkWidth: 240,
    bendingAngle: 120,
    bendInnerRadius: 54.3,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 25,
    upperToolRadius: 20.0,
    sinkWidth: 200,
    bendingAngle: 90,
    bendInnerRadius: 27.5,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 25,
    upperToolRadius: 20.0,
    sinkWidth: 200,
    bendingAngle: 120,
    bendInnerRadius: 43.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 25,
    upperToolRadius: 20.0,
    sinkWidth: 220,
    bendingAngle: 90,
    bendInnerRadius: 29.9,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 25,
    upperToolRadius: 20.0,
    sinkWidth: 220,
    bendingAngle: 120,
    bendInnerRadius: 48,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 25,
    upperToolRadius: 20.0,
    sinkWidth: 240,
    bendingAngle: 90,
    bendInnerRadius: 34.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 25,
    upperToolRadius: 20.0,
    sinkWidth: 240,
    bendingAngle: 120,
    bendInnerRadius: 56.7,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 25,
    upperToolRadius: 4.0,
    sinkWidth: 200,
    bendingAngle: 90,
    bendInnerRadius: 27.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 25,
    upperToolRadius: 4.0,
    sinkWidth: 200,
    bendingAngle: 120,
    bendInnerRadius: 42.6,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 25,
    upperToolRadius: 4.0,
    sinkWidth: 220,
    bendingAngle: 90,
    bendInnerRadius: 29.1,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 25,
    upperToolRadius: 4.0,
    sinkWidth: 220,
    bendingAngle: 120,
    bendInnerRadius: 46.4,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 25,
    upperToolRadius: 4.0,
    sinkWidth: 240,
    bendingAngle: 90,
    bendInnerRadius: 34.2,
  },
  {
    material: Material.ALUMINUM,
    sheetThickness: 25,
    upperToolRadius: 4.0,
    sinkWidth: 240,
    bendingAngle: 120,
    bendInnerRadius: 55.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 30,
    bendInnerRadius: 0.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 60,
    bendInnerRadius: 0.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 90,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 120,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 30,
    bendInnerRadius: 0.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 60,
    bendInnerRadius: 0.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 90,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 4,
    bendingAngle: 120,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 2.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 2.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.7,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 2.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 0.88,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 0.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 0.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 2.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 2.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 2.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 3.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 30,
    bendInnerRadius: 1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 60,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 90,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 5,
    bendingAngle: 120,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 2.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 2.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 3.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 1.75,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 2.25,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 2.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 3.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 2.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 2.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 2.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 3.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 2.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 2.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 3.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 2.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 2.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 2.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 2.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 3.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 2.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 2.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.25,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 4.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 0.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 0.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 2.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 2.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 2.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 2.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 3.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 2.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 2.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 0.5,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 3.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 30,
    bendInnerRadius: 1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 60,
    bendInnerRadius: 1.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 90,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 6,
    bendingAngle: 120,
    bendInnerRadius: 2.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 30,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 60,
    bendInnerRadius: 1.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 90,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 8,
    bendingAngle: 120,
    bendInnerRadius: 3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 2.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 2.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 2.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 3.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 2.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 2.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 2.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 1.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 3.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 1.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 1.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 2.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 3.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 2.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 2.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 2.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 4.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 3.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 3.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 3.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 2.75,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 3.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 10,
    bendingAngle: 30,
    bendInnerRadius: 2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 10,
    bendingAngle: 60,
    bendInnerRadius: 2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 10,
    bendingAngle: 90,
    bendInnerRadius: 2.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 10,
    bendingAngle: 120,
    bendInnerRadius: 3.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 2.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 2.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 4.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 3.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 3.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 3.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2,
    upperToolRadius: 2.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 5.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 30,
    bendInnerRadius: 1.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 60,
    bendInnerRadius: 2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 90,
    bendInnerRadius: 2.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 12,
    bendingAngle: 120,
    bendInnerRadius: 4.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 3.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 3.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 3.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 5.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 4.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 4.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 4.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 6.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 5.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 5.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 5.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 7.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 3.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 3.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 3.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 4.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 4.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 4.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 6.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 5.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 5.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 2.3,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 7.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 4.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 5.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 4.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 4.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 5.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 6.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 5.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 5.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 2.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 8.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 2.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 2.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 3.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 3.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 3.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 4.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 5.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 5.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 4.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 5.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 6.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 6.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 6.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 6.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 8.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 3.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 3.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 3.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 4.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 4.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 5.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 4.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 5.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 7.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 6.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 6.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 3.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 30,
    bendInnerRadius: 3.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 60,
    bendInnerRadius: 3.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 90,
    bendInnerRadius: 4.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 16,
    bendingAngle: 120,
    bendInnerRadius: 5.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 4.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 4.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 4.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 6.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 5.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 5.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 5.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 7.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 6.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 3.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 3.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 4.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 6.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 4.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 4.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 5.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 7.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 6.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 6.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 8.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 30,
    bendInnerRadius: 4.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 60,
    bendInnerRadius: 4.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 90,
    bendInnerRadius: 4.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 20,
    bendingAngle: 120,
    bendInnerRadius: 6.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 5.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 5.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 7.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 6.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 6.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 3.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 8.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 4.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 4.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 5.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 7.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 6.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 6.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 8.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 8.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 11,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 30,
    bendInnerRadius: 4.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 60,
    bendInnerRadius: 4.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 90,
    bendInnerRadius: 5.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 24,
    bendingAngle: 120,
    bendInnerRadius: 7.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 6.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 6.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 6.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 11.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 5.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 6.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 8.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 8.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 11.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 11.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 14.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 6.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 5.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 6.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 8.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 11.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 11.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 4.5,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 14.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 5.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 5.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 6.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 8.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 8.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 11.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 11.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 14.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 5.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 5.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 6.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 8.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 11.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 11.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 14.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 6.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 6.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 9.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 8.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 11.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 11.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 5,
    upperToolRadius: 5.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 14.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 4.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 4.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 9.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 8.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 11.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 10.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 14.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 1.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 10.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 10.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 10.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 12.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 10.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 11.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 10.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 14.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 13.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 17.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 5.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 5.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 6.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 9.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 8.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 11.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 10.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 14.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 13.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 17.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 6.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 8.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 6.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 11.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 6.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 10.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 6.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 14.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 6.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 13.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6,
    upperToolRadius: 6.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 17.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 10.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 10.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 10.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 13.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 10.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 11.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 10.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 14.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 13.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 17,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 30,
    bendInnerRadius: 4.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 60,
    bendInnerRadius: 5.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 90,
    bendInnerRadius: 6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 30,
    bendingAngle: 120,
    bendInnerRadius: 9.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 8.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 11.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 10.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 14.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 13.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 17,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 8.0,
    sinkWidth: 40,
    bendingAngle: 90,
    bendInnerRadius: 9.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 8.0,
    sinkWidth: 40,
    bendingAngle: 120,
    bendInnerRadius: 12,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 8.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 11.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 8.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 14.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 8.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 13.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 6.4,
    upperToolRadius: 8.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 17,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 13.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 16.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 70,
    bendingAngle: 90,
    bendInnerRadius: 15.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 70,
    bendingAngle: 120,
    bendInnerRadius: 19.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 17.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 22.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 20.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 25.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 10.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 14.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 12.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 14.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 70,
    bendingAngle: 90,
    bendInnerRadius: 15.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 70,
    bendingAngle: 120,
    bendInnerRadius: 19.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 17.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 22.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 10.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 14.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 13,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 16.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 70,
    bendingAngle: 90,
    bendInnerRadius: 15.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 70,
    bendingAngle: 120,
    bendInnerRadius: 20,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 17.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 8,
    upperToolRadius: 8.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 22.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 9.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 13.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 11.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 16.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 70,
    bendingAngle: 90,
    bendInnerRadius: 14.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 70,
    bendingAngle: 120,
    bendInnerRadius: 18.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 17.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 1.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 22.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 13.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 17.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 70,
    bendingAngle: 90,
    bendInnerRadius: 15.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 70,
    bendingAngle: 120,
    bendInnerRadius: 19.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 17.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 22.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 19.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 9.6,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 25.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 10,
    upperToolRadius: 10.0,
    sinkWidth: 70,
    bendingAngle: 90,
    bendInnerRadius: 15,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 10,
    upperToolRadius: 10.0,
    sinkWidth: 70,
    bendingAngle: 120,
    bendInnerRadius: 20.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 10,
    upperToolRadius: 10.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 17.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 10,
    upperToolRadius: 10.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 22.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 10,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 19.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 10,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 25.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 90,
    bendInnerRadius: 10,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 50,
    bendingAngle: 120,
    bendInnerRadius: 15,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 90,
    bendInnerRadius: 12.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 60,
    bendingAngle: 120,
    bendInnerRadius: 18.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 70,
    bendingAngle: 90,
    bendInnerRadius: 14.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 70,
    bendingAngle: 120,
    bendInnerRadius: 19.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 17.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 10,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 22.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12,
    upperToolRadius: 10.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 21.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12,
    upperToolRadius: 10.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 28.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 26.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 34.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 25.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 33.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 21.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 28,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 26.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 34.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 16.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 22.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 19.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 25.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 10.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 21.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 10.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 28,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 26.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 35.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 25.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 33,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 21.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 28.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 26.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 34.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 90,
    bendInnerRadius: 16.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 80,
    bendingAngle: 120,
    bendInnerRadius: 21.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 19.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 12.7,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 25,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 26.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 35.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 23.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 30.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 29.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 36.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15,
    upperToolRadius: 20.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 27,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15,
    upperToolRadius: 20.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 34.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 27.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 33.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 34.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 39.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 20.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 28.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 25.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 34.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 27.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 31.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 18.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 25.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 26,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 34.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 24,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 31,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 27.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 36,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 20.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 27,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 20.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 35.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 25.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 32.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 28,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 35.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 20.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 27.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 25.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 34.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 23.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 29.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 18.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 15.8,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 21.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 20.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 28.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 26,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 34.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 24.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 31.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 19,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 10.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 26.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 23.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 29,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 27,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 35.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 24.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 31.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 21.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 20.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 27.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 90,
    bendInnerRadius: 20.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 100,
    bendingAngle: 120,
    bendInnerRadius: 27.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 90,
    bendInnerRadius: 25.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 120,
    bendingAngle: 120,
    bendInnerRadius: 34.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 25.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 32.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 90,
    bendInnerRadius: 18.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 16,
    upperToolRadius: 4.0,
    sinkWidth: 90,
    bendingAngle: 120,
    bendInnerRadius: 26.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 19,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 23.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 19,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 28.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 19,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 27.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 19,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 35,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 19,
    upperToolRadius: 10.0,
    sinkWidth: 180,
    bendingAngle: 90,
    bendInnerRadius: 40,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 19,
    upperToolRadius: 10.0,
    sinkWidth: 180,
    bendingAngle: 120,
    bendInnerRadius: 51.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 19,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 24,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 19,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 28.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 19,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 31.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 19,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 37.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 19,
    upperToolRadius: 20.0,
    sinkWidth: 180,
    bendingAngle: 90,
    bendInnerRadius: 40,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 19,
    upperToolRadius: 20.0,
    sinkWidth: 180,
    bendingAngle: 120,
    bendInnerRadius: 50.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 19,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 24,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 19,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 29.3,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 19,
    upperToolRadius: 4.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 27.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 19,
    upperToolRadius: 4.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 34.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 19,
    upperToolRadius: 4.0,
    sinkWidth: 180,
    bendingAngle: 90,
    bendInnerRadius: 40,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 19,
    upperToolRadius: 4.0,
    sinkWidth: 180,
    bendingAngle: 120,
    bendInnerRadius: 50.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 20,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 23.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 20,
    upperToolRadius: 10.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 28.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 20,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 27.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 20,
    upperToolRadius: 10.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 35,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 20,
    upperToolRadius: 10.0,
    sinkWidth: 180,
    bendingAngle: 90,
    bendInnerRadius: 39.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 20,
    upperToolRadius: 10.0,
    sinkWidth: 180,
    bendingAngle: 120,
    bendInnerRadius: 50.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 20,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 24.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 20,
    upperToolRadius: 20.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 28.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 20,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 26.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 20,
    upperToolRadius: 20.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 33,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 20,
    upperToolRadius: 20.0,
    sinkWidth: 180,
    bendingAngle: 90,
    bendInnerRadius: 39.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 20,
    upperToolRadius: 20.0,
    sinkWidth: 180,
    bendingAngle: 120,
    bendInnerRadius: 50.5,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 20,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 90,
    bendInnerRadius: 23.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 20,
    upperToolRadius: 4.0,
    sinkWidth: 140,
    bendingAngle: 120,
    bendInnerRadius: 28.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 20,
    upperToolRadius: 4.0,
    sinkWidth: 160,
    bendingAngle: 90,
    bendInnerRadius: 27.1,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 20,
    upperToolRadius: 4.0,
    sinkWidth: 160,
    bendingAngle: 120,
    bendInnerRadius: 34,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 20,
    upperToolRadius: 4.0,
    sinkWidth: 180,
    bendingAngle: 90,
    bendInnerRadius: 38.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 20,
    upperToolRadius: 4.0,
    sinkWidth: 180,
    bendingAngle: 120,
    bendInnerRadius: 51.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 25,
    upperToolRadius: 10.0,
    sinkWidth: 200,
    bendingAngle: 90,
    bendInnerRadius: 39.7,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 25,
    upperToolRadius: 10.0,
    sinkWidth: 200,
    bendingAngle: 120,
    bendInnerRadius: 45.4,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 25,
    upperToolRadius: 10.0,
    sinkWidth: 220,
    bendingAngle: 90,
    bendInnerRadius: 43,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 25,
    upperToolRadius: 10.0,
    sinkWidth: 220,
    bendingAngle: 120,
    bendInnerRadius: 51.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 25,
    upperToolRadius: 10.0,
    sinkWidth: 240,
    bendingAngle: 90,
    bendInnerRadius: 42,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 25,
    upperToolRadius: 10.0,
    sinkWidth: 240,
    bendingAngle: 120,
    bendInnerRadius: 55,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 25,
    upperToolRadius: 20.0,
    sinkWidth: 200,
    bendingAngle: 90,
    bendInnerRadius: 42,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 25,
    upperToolRadius: 20.0,
    sinkWidth: 200,
    bendingAngle: 120,
    bendInnerRadius: 46.9,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 25,
    upperToolRadius: 20.0,
    sinkWidth: 220,
    bendingAngle: 90,
    bendInnerRadius: 40.2,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 25,
    upperToolRadius: 20.0,
    sinkWidth: 220,
    bendingAngle: 120,
    bendInnerRadius: 49.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 25,
    upperToolRadius: 20.0,
    sinkWidth: 240,
    bendingAngle: 90,
    bendInnerRadius: 46,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 25,
    upperToolRadius: 20.0,
    sinkWidth: 240,
    bendingAngle: 120,
    bendInnerRadius: 57,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 25,
    upperToolRadius: 4.0,
    sinkWidth: 200,
    bendingAngle: 90,
    bendInnerRadius: 42,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 25,
    upperToolRadius: 4.0,
    sinkWidth: 200,
    bendingAngle: 120,
    bendInnerRadius: 45.8,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 25,
    upperToolRadius: 4.0,
    sinkWidth: 220,
    bendingAngle: 90,
    bendInnerRadius: 39,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 25,
    upperToolRadius: 4.0,
    sinkWidth: 220,
    bendingAngle: 120,
    bendInnerRadius: 49,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 25,
    upperToolRadius: 4.0,
    sinkWidth: 240,
    bendingAngle: 90,
    bendInnerRadius: 46.6,
  },
  {
    material: Material.STAINLESS_STEEL,
    sheetThickness: 25,
    upperToolRadius: 4.0,
    sinkWidth: 240,
    bendingAngle: 120,
    bendInnerRadius: 56.2,
  },
];
