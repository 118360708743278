import { TableInfo } from '../../model/table-result/table-info';
import {
  BLUE_GREY_BORDER_COLOR,
  BLUE_HEADER_BACKGROUND_COLOR,
  GREEN_LEGEND_COLOR,
  LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
} from './color-constants';

export const STAINLESS_STEEL_PRESSING_FORCE_TABLE: TableInfo = {
  rows: 23,
  columns: 21,
  caption: 'CALCULATIONS.PRESSING_FORCE_TABLE.PRESSING_FORCE_SPECIFICATION',
  borderColor: BLUE_GREY_BORDER_COLOR,
  legend: [
    {
      color: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
      toolTip: 'CALCULATIONS.PRESSING_FORCE_TABLE.DIE_WIDTH_LEGEND',
    },
    {
      color: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
      toolTip: 'CALCULATIONS.PRESSING_FORCE_TABLE.BENDING_LENGTH_LEGEND',
    },
    {
      color: BLUE_HEADER_BACKGROUND_COLOR,
      toolTip: 'CALCULATIONS.PRESSING_FORCE_TABLE.SHEET_THICKNESS_LEGEND',
    },
    {
      color: BLUE_HEADER_BACKGROUND_COLOR,
      toolTip: 'CALCULATIONS.PRESSING_FORCE_TABLE.UPPER_TOOL_LEGEND',
    },
    {
      color: GREEN_LEGEND_COLOR,
      toolTip: 'CALCULATIONS.PRESSING_FORCE_TABLE.OPTIMUM_DIE_WIDTH',
    },
  ],
  cells: [
    // First header row
    {
      row: 0,
      column: 0,
      value: 'w',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 1,
      value: '4',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 2,
      value: '5',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 3,
      value: '6',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 4,
      value: '8',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 5,
      value: '10',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 6,
      value: '12',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 7,
      value: '14',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 8,
      value: '16',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 9,
      value: '20',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 10,
      value: '24',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 11,
      value: '30',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 12,
      value: '40',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 13,
      value: '50',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 14,
      value: '60',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 15,
      value: '70',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 16,
      value: '80',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 17,
      value: '90',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 18,
      value: '100',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 19,
      value: '120',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 20,
      value: '150',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    // Second header row
    {
      row: 1,
      column: 0,
      value: 'b1',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 1,
      value: '3.1',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 2,
      value: '3.8',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 3,
      value: '4.5',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 4,
      value: '5.7',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 5,
      value: '7.1',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 6,
      value: '8.2',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 7,
      value: '10.3',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 8,
      value: '11.1',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 9,
      value: '14.5',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 10,
      value: '19',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 11,
      value: '23.7',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 12,
      value: '29.8',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 13,
      value: '34.5',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 14,
      value: '42',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 15,
      value: '48',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 16,
      value: '54',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 17,
      value: '65',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 18,
      value: '68',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 19,
      value: '82',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 20,
      value: '112.5',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    // Third header row
    {
      row: 2,
      column: 0,
      value: 'b2',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 1,
      value: '3.3',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 2,
      value: '4.1',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 3,
      value: '4.8',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 4,
      value: '6.3',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 5,
      value: '7.8',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 6,
      value: '9.3',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 7,
      value: '11.3',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 8,
      value: '12.1',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 9,
      value: '15.7',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 10,
      value: '19.1',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 11,
      value: '23',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 12,
      value: '31.5',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 13,
      value: '38',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 14,
      value: '',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 15,
      value: '',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 16,
      value: '',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 17,
      value: '',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 18,
      value: '',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 19,
      value: '',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 20,
      value: '',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    // Fourth header row
    {
      row: 3,
      column: 0,
      value: 's/Ri',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 1,
      value: '1.1',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 2,
      value: '1.3',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 3,
      value: '1.6',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 4,
      value: '2',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 5,
      value: '2.4',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 6,
      value: '2.9',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 7,
      value: '3.2',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 8,
      value: '3.9',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 9,
      value: '4.9',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 10,
      value: '5.2',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 11,
      value: '6.5',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 12,
      value: '8.6',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 13,
      value: '10.8',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 14,
      value: '11.8',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 15,
      value: '14.7',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 16,
      value: '17.1',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 17,
      value: '',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 18,
      value: '',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 19,
      value: '',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 20,
      value: '',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    // Data rows
    {
      row: 4,
      column: 0,
      value: '0.5',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 4,
      column: 1,
      value: '71',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 2,
      value: '54',
      isHeader: false,
    },

    {
      row: 4,
      column: 3,
      value: '44',
      isHeader: false,
    },

    {
      row: 4,
      column: 4,
      value: '32',
      isHeader: false,
    },

    {
      row: 5,
      column: 0,
      value: '0.75',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 5,
      column: 1,
      value: '159',
      isHeader: false,
    },

    {
      row: 5,
      column: 2,
      value: '122',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 3,
      value: '99',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 4,
      value: '72',
      isHeader: false,
    },

    {
      row: 5,
      column: 5,
      value: '56',
      isHeader: false,
    },

    {
      row: 5,
      column: 6,
      value: '46',
      isHeader: false,
    },

    {
      row: 6,
      column: 0,
      value: '1',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 6,
      column: 2,
      value: '260',
      isHeader: false,
    },

    {
      row: 6,
      column: 3,
      value: '203',
      isHeader: false,
    },

    {
      row: 6,
      column: 4,
      value: '141',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 5,
      value: '108',
      isHeader: false,
    },

    {
      row: 6,
      column: 6,
      value: '88',
      isHeader: false,
    },

    {
      row: 6,
      column: 7,
      value: '74',
      isHeader: false,
    },

    {
      row: 6,
      column: 8,
      value: '64',
      isHeader: false,
    },

    {
      row: 7,
      column: 0,
      value: '1.25',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 7,
      column: 3,
      value: '317',
      isHeader: false,
    },

    {
      row: 7,
      column: 4,
      value: '221',
      isHeader: false,
    },

    {
      row: 7,
      column: 5,
      value: '169',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 7,
      column: 6,
      value: '137',
      isHeader: false,
    },

    {
      row: 7,
      column: 7,
      value: '116',
      isHeader: false,
    },

    {
      row: 7,
      column: 8,
      value: '100',
      isHeader: false,
    },

    {
      row: 7,
      column: 9,
      value: '78',
      isHeader: false,
    },

    {
      row: 8,
      column: 0,
      value: '1.5',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 8,
      column: 3,
      value: '457',
      isHeader: false,
    },

    {
      row: 8,
      column: 4,
      value: '318',
      isHeader: false,
    },

    {
      row: 8,
      column: 5,
      value: '244',
      isHeader: false,
    },

    {
      row: 8,
      column: 6,
      value: '198',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 8,
      column: 7,
      value: '166',
      isHeader: false,
    },

    {
      row: 8,
      column: 8,
      value: '144',
      isHeader: false,
    },

    {
      row: 8,
      column: 9,
      value: '113',
      isHeader: false,
    },

    {
      row: 9,
      column: 0,
      value: '1.75',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 9,
      column: 4,
      value: '433',
      isHeader: false,
    },

    {
      row: 9,
      column: 5,
      value: '332',
      isHeader: false,
    },

    {
      row: 9,
      column: 6,
      value: '269',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 9,
      column: 7,
      value: '227',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 9,
      column: 8,
      value: '195',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 9,
      column: 9,
      value: '153',
      isHeader: false,
    },

    {
      row: 9,
      column: 10,
      value: '126',
      isHeader: false,
    },

    {
      row: 10,
      column: 0,
      value: '2',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 10,
      column: 5,
      value: '434',
      isHeader: false,
    },

    {
      row: 10,
      column: 6,
      value: '352',
      isHeader: false,
    },

    {
      row: 10,
      column: 7,
      value: '296',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 10,
      column: 8,
      value: '255',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 10,
      column: 9,
      value: '200',
      isHeader: false,
    },

    {
      row: 10,
      column: 10,
      value: '165',
      isHeader: false,
    },

    {
      row: 11,
      column: 0,
      value: '2.5',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 11,
      column: 6,
      value: '550',
      isHeader: false,
    },

    {
      row: 11,
      column: 7,
      value: '462',
      isHeader: false,
    },

    {
      row: 11,
      column: 8,
      value: '399',
      isHeader: false,
    },

    {
      row: 11,
      column: 9,
      value: '313',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 11,
      column: 10,
      value: '258',
      isHeader: false,
    },

    {
      row: 11,
      column: 11,
      value: '204',
      isHeader: false,
    },

    {
      row: 12,
      column: 0,
      value: '3',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 12,
      column: 8,
      value: '574',
      isHeader: false,
    },

    {
      row: 12,
      column: 9,
      value: '451',
      isHeader: false,
    },

    {
      row: 12,
      column: 10,
      value: '371',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 12,
      column: 11,
      value: '293',
      isHeader: false,
    },

    {
      row: 12,
      column: 12,
      value: '217',
      isHeader: false,
    },

    {
      row: 13,
      column: 0,
      value: '3.5',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 13,
      column: 8,
      value: '782',
      isHeader: false,
    },

    {
      row: 13,
      column: 9,
      value: '614',
      isHeader: false,
    },

    {
      row: 13,
      column: 10,
      value: '505',
      isHeader: false,
    },

    {
      row: 13,
      column: 11,
      value: '399',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 13,
      column: 12,
      value: '296',
      isHeader: false,
    },

    {
      row: 13,
      column: 13,
      value: '235',
      isHeader: false,
    },

    {
      row: 14,
      column: 0,
      value: '4',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 14,
      column: 9,
      value: '801',
      isHeader: false,
    },

    {
      row: 14,
      column: 10,
      value: '660',
      isHeader: false,
    },

    {
      row: 14,
      column: 11,
      value: '521',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 14,
      column: 12,
      value: '386',
      isHeader: false,
    },

    {
      row: 14,
      column: 13,
      value: '307',
      isHeader: false,
    },

    {
      row: 14,
      column: 14,
      value: '254',
      isHeader: false,
    },

    {
      row: 15,
      column: 0,
      value: '4.5',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 15,
      column: 9,
      value: '1014',
      isHeader: false,
    },

    {
      row: 15,
      column: 10,
      value: '835',
      isHeader: false,
    },

    {
      row: 15,
      column: 11,
      value: '660',
      isHeader: false,
    },

    {
      row: 15,
      column: 12,
      value: '489',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 15,
      column: 13,
      value: '388',
      isHeader: false,
    },

    {
      row: 15,
      column: 14,
      value: '322',
      isHeader: false,
    },

    {
      row: 15,
      column: 15,
      value: '275',
      isHeader: false,
    },

    {
      row: 16,
      column: 0,
      value: '5',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 16,
      column: 11,
      value: '814',
      isHeader: false,
    },

    {
      row: 16,
      column: 12,
      value: '603',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 16,
      column: 13,
      value: '479',
      isHeader: false,
    },

    {
      row: 16,
      column: 14,
      value: '397',
      isHeader: false,
    },

    {
      row: 16,
      column: 15,
      value: '339',
      isHeader: false,
    },

    {
      row: 16,
      column: 16,
      value: '296',
      isHeader: false,
    },

    {
      row: 17,
      column: 0,
      value: '6',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 17,
      column: 11,
      value: '1172',
      isHeader: false,
    },

    {
      row: 17,
      column: 12,
      value: '869',
      isHeader: false,
    },

    {
      row: 17,
      column: 13,
      value: '690',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 17,
      column: 14,
      value: '572',
      isHeader: false,
    },

    {
      row: 17,
      column: 15,
      value: '489',
      isHeader: false,
    },

    {
      row: 17,
      column: 16,
      value: '426',
      isHeader: false,
    },

    {
      row: 17,
      column: 17,
      value: '378',
      isHeader: false,
    },

    {
      row: 18,
      column: 0,
      value: '7',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 18,
      column: 12,
      value: '1182',
      isHeader: false,
    },

    {
      row: 18,
      column: 13,
      value: '939',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 18,
      column: 14,
      value: '779',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 18,
      column: 15,
      value: '665',
      isHeader: false,
    },

    {
      row: 18,
      column: 16,
      value: '580',
      isHeader: false,
    },

    {
      row: 18,
      column: 17,
      value: '515',
      isHeader: false,
    },

    {
      row: 18,
      column: 18,
      value: '463',
      isHeader: false,
    },

    {
      row: 19,
      column: 0,
      value: '8',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 19,
      column: 13,
      value: '1344',
      isHeader: false,
    },

    {
      row: 19,
      column: 14,
      value: '1096',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 19,
      column: 15,
      value: '926',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 19,
      column: 16,
      value: '801',
      isHeader: false,
    },

    {
      row: 19,
      column: 17,
      value: '706',
      isHeader: false,
    },

    {
      row: 19,
      column: 18,
      value: '632',
      isHeader: false,
    },

    {
      row: 19,
      column: 19,
      value: '521',
      isHeader: false,
    },

    {
      row: 20,
      column: 0,
      value: '10',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 20,
      column: 15,
      value: '1447',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 20,
      column: 16,
      value: '1252',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 20,
      column: 17,
      value: '1104',
      isHeader: false,
    },

    {
      row: 20,
      column: 18,
      value: '987',
      isHeader: false,
    },

    {
      row: 20,
      column: 19,
      value: '814',
      isHeader: false,
    },

    {
      row: 21,
      column: 0,
      value: '12',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 21,
      column: 16,
      value: '1803',
      isHeader: false,
    },

    {
      row: 21,
      column: 17,
      value: '1590',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 21,
      column: 18,
      value: '1421',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 21,
      column: 19,
      value: '1172',
      isHeader: false,
    },

    {
      row: 22,
      column: 0,
      value: '15',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 22,
      column: 17,
      value: '2484',
      isHeader: false,
    },

    {
      row: 22,
      column: 18,
      value: '2220',
      isHeader: false,
    },

    {
      row: 22,
      column: 19,
      value: '1832',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 22,
      column: 20,
      value: '1451',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },
  ],
};
