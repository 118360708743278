<div class="input-container">
  <span class="dropdown-label">
    {{ translations.CALCULATIONS.INPUT_LABELS.MATERIAL | translate }}
  </span>
  <xui-dropdown
    #materialDropDown
    class="drop-down"
    [label]="selectedMaterialName()"
    data-ngtx="sheet-thickness:material"
  >
    <xui-dropdown-item
      *ngFor="let material of materials"
      (click)="selectedMaterial = material.id; materialDropDown.close(); clearResult()"
    >
      {{ material?.name }}
    </xui-dropdown-item>
  </xui-dropdown>

  <span class="dropdown-label">
    {{ translations.CALCULATIONS.INPUT_LABELS.GAUGE | translate }}
  </span>
  <xui-dropdown
    #gaugeDropDown
    class="drop-down"
    [label]="selectedGauge"
    data-ngtx="sheet-thickness:gauge"
  >
    <xui-dropdown-item
      *ngFor="let gauge of gauges"
      (click)="selectedGauge = gauge; gaugeDropDown.close(); clearResult()"
    >
      {{ gauge }}
    </xui-dropdown-item>
  </xui-dropdown>

  <span class="calculationError" *ngIf="calculationError$ | async as error">
    {{ error }}
  </span>

  <!-- Calculate -->
  <div class="calculate-button">
    <xui-button
      (click)="calculate()"
      xuiClickUsageTracking="{{ uiElementIds.CALCULATE_SHEET_THICKNESS }}"
    >
      {{ translations.CALCULATIONS.INPUT_LABELS.CALCULATE | translate }}
    </xui-button>
  </div>

  <div
    *ngIf="calculationService.calculationResult$ | async as calculationResult"
    class="calculation-result"
  >
    <ng-container *ngIf="!calculationResult.error">
      <xui-caption-label [caption]="translations.CALCULATIONS.TYPES.SHEET_WIDTH | translate">
        <xui-value
          #sheetThicknessResultValue
          [(binding)]="calculationResult.sheetThickness"
          [fallbackValue]="0"
        >
          <xui-length-converter
            imperialTargetUnit="inch"
            metricTargetUnit="mm"
          ></xui-length-converter>
          <xui-number-formatter
            [maxDecimals]="2"
            [unit]="sheetThicknessResultValue.unit"
          ></xui-number-formatter>
        </xui-value>
        {{ sheetThicknessResultValue.value }}
      </xui-caption-label>
    </ng-container>
  </div>
</div>
