export const DEFAULT_MACHINE_NAME = 'TruBend 5000 H615';
export const MOUNT_HEIGHT_MACHINE: Map<string, number> = new Map<string, number>([
  ['TruBend 5000 H615', 615],
  ['TruBend 5000 H385', 385],
  ['TruBend 7036 H295', 295],
  ['TruBend 3000 H342', 342],
  ['TruBend 7050 H385', 385],
  ['TruBend 3000 H500', 500],
  ['TrumaBend V H535', 535],
  ['TrumaBend V H385', 385],
  ['TruBend 8000 H475', 475],
  ['TruBend 8000 H675', 675],
  ['TruBend 8000 H875', 875],
]);

export const MOUNT_HEIGHT_ADAPTER: Map<string, number> = new Map<string, number>([
  ['MF/S H60', 60],
  ['MF/S H80', 80],
  ['MF/S H100', 100],
  ['MF/S H150', 150],
  ['MF/S H200', 200],
]);

export const MOUNT_HEIGHT_UPPER_TOOLS: Map<string, number> = new Map<string, number>([
  ['CALCULATIONS.UPPER_TOOLS.H120', 120],
  ['CALCULATIONS.UPPER_TOOLS.H140', 140],
  ['CALCULATIONS.UPPER_TOOLS.H220', 220],
  ['CALCULATIONS.UPPER_TOOLS.H240', 240],
  ['CALCULATIONS.UPPER_TOOLS.H300', 300],
  ['CALCULATIONS.UPPER_TOOLS.H100', 220],
  ['CALCULATIONS.UPPER_TOOLS.H90', 170],
]);

export const MOUNT_HEIGHT_BOTTOM_TOOLS: Map<string, number> = new Map<string, number>([
  ['EV H100', 100],
  ['EV H120', 120],
  ['EV/H H150', 150],
  ['FEV H110', 110],
  ['RollBend H100', 100],
  ['VLMBN H185 (TruBend 5000,8000)', 185],
  ['VLMDN H195 (TruBend 5000,8000)', 195],
]);
