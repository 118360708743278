import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { merge, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TranslationHelper } from '../../../shared/helpers';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import {
  bendingForceCalculationTypeToTranslation,
  materialToTranslation,
} from '../../helpers/enum-to-translation';
import { BendingForceCalculationType } from '../../model/bending-force-calculation-type';
import {
  BENDING_ANGLES,
  DIE_WIDTHS,
  SHEET_THICKNESSES,
  UPPER_TOOL_RADII,
} from '../../model/default-dimensions';
import { DropdownItemValue } from '../../model/dropdown-item-value';
import { Material } from '../../model/material';
import { PressingForceCalculationResult } from '../../model/pressing-force-calculation-result';
import { TensileStrengths } from '../../model/tensile-strength';
import { PressingForceCalculationService } from '../../services/pressing-force-calculation.service';

export const DEFAULT_SHEET_THICKNESS = 1;
export const DEFAULT_DIE_WIDTH = 24;
export const DEFAULT_PRESSING_FORCE = null;
export const DEFAULT_BENDING_LENGTH = 1000;

@Component({
  selector: 'lsb-pressing-force',
  templateUrl: './pressing-force.component.html',
  styleUrls: ['../../styles/calculations-base-style.scss', './pressing-force.component.scss'],
  providers: [PressingForceCalculationService],
})
export class PressingForceComponent implements OnInit {
  public readonly uiElementIds = UiElementIds;

  // provides enum reference in template
  public bendingForceCalculationTypes = BendingForceCalculationType;

  public calculationTypes: DropdownItemValue<BendingForceCalculationType>[] = [];
  public selectedCalculationType: BendingForceCalculationType =
    BendingForceCalculationType.PRESSING_FORCE;

  public materials: DropdownItemValue<Material>[] = [];
  public selectedMaterial: Material | undefined = Material.STAINLESS_STEEL;
  public isMaterialUserDefined = false;
  public tensileStrength: number = TensileStrengths.get(Material.STAINLESS_STEEL)!;

  public pressingForce: number | null = DEFAULT_PRESSING_FORCE;
  public bendingLength: number | null = DEFAULT_BENDING_LENGTH;

  public dieWidths = DIE_WIDTHS;
  public selectedDieWidth = DEFAULT_DIE_WIDTH;
  public isDieWidthUserDefined = false;
  public dieWidthForCalculation: number | null = DEFAULT_DIE_WIDTH;

  public sheetThicknesses = SHEET_THICKNESSES;
  public selectedSheetThickness: number = 1;
  public isSheetThicknessUserDefined = false;
  public sheetThicknessForCalculation: number | null = DEFAULT_SHEET_THICKNESS;

  public upperToolRadii = UPPER_TOOL_RADII;
  public selectedUpperToolRadius: number = 1;
  public isUpperToolRadiusUserDefined = false;
  public upperToolRadiusForCalculation: number = 1;

  public bendingAngles = BENDING_ANGLES;
  public selectedBendingAngle: number = 90;
  public isBendingAngleUserDefined: boolean = false;
  public bendingAngleForCalculation: number = 90;
  public calculationErrors$ = this.calculationService.calculationResult$.pipe(
    map((r) => r?.errorMessages),
  );
  private clearResultSubject: Subject<PressingForceCalculationResult | undefined> = new Subject<
    PressingForceCalculationResult | undefined
  >();
  public calculationResult$: Observable<PressingForceCalculationResult | undefined> = merge(
    this.clearResultSubject.asObservable(),
    this.calculationService.calculationResult$,
  ).pipe(tap((result) => this.resetInputs(result)));

  constructor(
    public translations: TranslationHelper,
    private translateService: TranslateService,
    private calculationService: PressingForceCalculationService,
  ) {
    for (const calculationType of Object.values(BendingForceCalculationType)) {
      this.calculationTypes.push({
        id: calculationType,
        name: bendingForceCalculationTypeToTranslation(
          calculationType,
          translations,
          translateService,
        ),
      });
    }

    for (const material of Object.values(Material)) {
      this.materials.push({
        id: material,
        name: materialToTranslation(material, translations, translateService),
      });
    }
  }

  ngOnInit(): void {}

  public calculationTypeChanged(newCalculationType: BendingForceCalculationType) {
    this.selectedCalculationType = newCalculationType;
    // Restore defaults
    this.restoreDefaults(newCalculationType);
    this.clearResult();
  }

  public selectedCalculationTypeName(): string {
    return (
      this.calculationTypes.find((m) => m.id === this.selectedCalculationType)?.name ?? 'unknown'
    );
  }

  public selectedMaterialName(): string {
    if (this.selectedMaterial) {
      return this.materials.find((m) => m.id === this.selectedMaterial)?.name ?? 'unknown';
    } else {
      return this.translateService.instant(
        this.translations.CALCULATIONS.INPUT_LABELS.USER_DEFINED,
      );
    }
  }

  public onSelectMaterial(newMaterial: Material) {
    this.selectedMaterial = newMaterial;
    this.isMaterialUserDefined = false;
    this.tensileStrength = TensileStrengths.get(this.selectedMaterial)!;
    this.clearResult();
  }

  public clearResult(): void {
    this.clearResultSubject.next();
  }

  public calculate(): void {
    switch (this.selectedCalculationType) {
      case BendingForceCalculationType.PRESSING_FORCE:
        this.calculationService.calculatePressingForce(
          +this.tensileStrength,
          +this.sheetThicknessForCalculation!,
          +this.upperToolRadiusForCalculation,
          +this.dieWidthForCalculation!,
          +this.bendingLength!,
          +this.bendingAngleForCalculation,
        );
        break;
      case BendingForceCalculationType.SHEET_THICKNESS:
        this.calculationService.calculateSheetThickness(
          +this.tensileStrength,
          +this.pressingForce!,
          +this.upperToolRadiusForCalculation,
          +this.dieWidthForCalculation!,
          +this.bendingLength!,
          +this.bendingAngleForCalculation,
        );
        break;
      case BendingForceCalculationType.BENDING_LENGTH:
        this.calculationService.calculateBendingLength(
          +this.tensileStrength,
          +this.pressingForce!,
          +this.sheetThicknessForCalculation!,
          +this.upperToolRadiusForCalculation,
          +this.dieWidthForCalculation!,
          +this.bendingAngleForCalculation,
        );
        break;
    }
  }

  public resetInputs(calculationResult: PressingForceCalculationResult | undefined): void {
    if (calculationResult) {
      if (calculationResult.type === BendingForceCalculationType.PRESSING_FORCE) {
        this.pressingForce = calculationResult.pressingForce;
      }
      if (calculationResult.type === BendingForceCalculationType.BENDING_LENGTH) {
        this.bendingLength = calculationResult.bendingLength;
      }
      if (calculationResult.type === BendingForceCalculationType.SHEET_THICKNESS) {
        this.sheetThicknessForCalculation = calculationResult.sheetThickness;
      }
    } else {
      if (this.selectedCalculationType === BendingForceCalculationType.PRESSING_FORCE) {
        this.pressingForce = null;
      }
      if (this.selectedCalculationType === BendingForceCalculationType.BENDING_LENGTH) {
        this.bendingLength = null;
      }
      if (
        this.selectedCalculationType === BendingForceCalculationType.SHEET_THICKNESS &&
        this.sheetThicknessForCalculation !== null
      ) {
        this.sheetThicknessForCalculation = null;
      }
    }
  }

  private restoreDefaults(newCalculationType: BendingForceCalculationType): void {
    if (
      newCalculationType !== BendingForceCalculationType.SHEET_THICKNESS &&
      this.sheetThicknessForCalculation === null
    ) {
      this.sheetThicknessForCalculation = DEFAULT_SHEET_THICKNESS;
    }
    if (
      newCalculationType !== BendingForceCalculationType.PRESSING_FORCE &&
      this.pressingForce === null
    ) {
      this.pressingForce = DEFAULT_PRESSING_FORCE;
    }
    if (
      newCalculationType !== BendingForceCalculationType.BENDING_LENGTH &&
      this.bendingLength === null
    ) {
      this.bendingLength = DEFAULT_BENDING_LENGTH;
    }
  }
}
