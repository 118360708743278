import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslationHelper } from '../../../shared/helpers';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import { TOOL_LENGTHS, TOOL_SPECIFIC_WEIGHTS } from '../../data/tool-weight';
import { toolCategoryToTranslation } from '../../helpers/enum-to-translation';
import { DropdownItemValue } from '../../model/dropdown-item-value';
import { ToolCategory } from '../../model/tool-category';
import { ToolType } from '../../model/tool-type';
import { ToolWeightCalculationResult } from '../../model/tool-weight-calculation-result';
import { ToolWeightCalculationService } from '../../services/tool-weight-calculation.service';

@Component({
  selector: 'lsb-tool-weight',
  templateUrl: './tool-weight.component.html',
  styleUrls: ['../../styles/calculations-base-style.scss', './tool-weight.component.scss'],
  providers: [ToolWeightCalculationService],
})
export class ToolWeightComponent implements OnInit {
  public readonly uiElementIds = UiElementIds;

  public toolCategories: DropdownItemValue<ToolCategory>[] = [];
  public selectedToolCategory: ToolCategory;

  public specificWeights: ToolType[];
  public selectedSpecificWeight: ToolType;

  public toolLengths = TOOL_LENGTHS;
  public selectedToolLength = TOOL_LENGTHS[0];

  private clearResultSubject: Subject<ToolWeightCalculationResult | undefined> = new Subject<
    ToolWeightCalculationResult | undefined
  >();
  public calculationResult$: Observable<ToolWeightCalculationResult | undefined> = merge(
    this.clearResultSubject.asObservable(),
    this.calculationService.calculationResult$.pipe(
      map((weight) => {
        return weight
          ? ({
              weight: weight,
            } as ToolWeightCalculationResult)
          : undefined;
      }),
    ),
  );

  constructor(
    public translations: TranslationHelper,
    private translateService: TranslateService,
    private calculationService: ToolWeightCalculationService,
  ) {
    for (const category of Object.values(ToolCategory)) {
      this.toolCategories.push({
        id: category,
        name: toolCategoryToTranslation(category, translations, translateService),
      });
    }

    this.selectedToolCategory = this.toolCategories[0].id;
  }

  ngOnInit(): void {
    this.updateAvailableTools();
  }

  public calculate(): void {
    this.calculationService.calculate(
      this.selectedSpecificWeight.specificWeight,
      this.selectedToolLength,
    );
  }

  public clearResult(): void {
    this.clearResultSubject.next(undefined);
  }

  public selectedToolCategoryName(): string {
    return this.toolCategories.find((c) => c.id === this.selectedToolCategory)?.name ?? 'unknown';
  }

  public updateAvailableTools() {
    this.specificWeights = [
      ...TOOL_SPECIFIC_WEIGHTS.filter((t) => t.category === this.selectedToolCategory),
    ];
    this.selectedSpecificWeight = this.specificWeights[0];
  }
}
