import { Component, OnInit } from '@angular/core';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import { MountHeightCalculationType } from '../../model/mount-height/mount-height-calculation-type';
import { DropdownItemValue } from '../../model/dropdown-item-value';
import { merge, Observable, Subject } from 'rxjs';
import { MountHeightCalculationResult } from '../../model/mount-height/mount-height-calculation-result';
import { TranslationHelper } from '../../../shared/helpers';
import { TranslateService } from '@ngx-translate/core';
import { MountHeightCalculationService } from '../../services/mount-height-calculation.service';
import { mountHeightCalculationTypeToTranslation } from '../../helpers/enum-to-translation';
import {
  DEFAULT_MACHINE_NAME,
  MOUNT_HEIGHT_ADAPTER,
  MOUNT_HEIGHT_BOTTOM_TOOLS,
  MOUNT_HEIGHT_MACHINE,
  MOUNT_HEIGHT_UPPER_TOOLS,
} from '../../data/mount-height';
import { tap } from 'rxjs/operators';

export const DEFAULT_ADAPTER_HEIGHT = 0;
export const DEFAULT_UPPER_TOOL_HEIGHT = 120;
export const DEFAULT_BOTTOM_TOOL_HEIGHT = 100;
export const DEFAULT_REMAINING_OPENING = 0;

@Component({
  selector: 'lsb-mount-height',
  templateUrl: './mount-height.component.html',
  styleUrls: ['../../styles/calculations-base-style.scss', './mount-height.component.scss'],
  providers: [MountHeightCalculationService],
})
export class MountHeightComponent implements OnInit {
  public readonly uiElementIds = UiElementIds;
  public readonly mountHeightCalculationType = MountHeightCalculationType;
  public readonly userDefinedText: string;

  public calculationTypes: DropdownItemValue<MountHeightCalculationType>[] = [];
  public selectedCalculationType: MountHeightCalculationType =
    MountHeightCalculationType.REMAINING_OPENING;

  public machines: string[];
  public selectedMachine: string = DEFAULT_MACHINE_NAME;
  public isMachineUserDefined = false;
  public machineHeight: number | null = MOUNT_HEIGHT_MACHINE.get(DEFAULT_MACHINE_NAME) ?? null;

  public noAdapterLocalized: string;
  public adapters: string[];
  public selectedAdapter: string;
  public isAdapterUserDefined = false;
  public adapterHeight: number | null = DEFAULT_ADAPTER_HEIGHT;

  public upperTools: string[];
  public selectedUpperTool: string;
  public isUpperToolUserDefined = false;
  public upperToolHeight: number | null = DEFAULT_UPPER_TOOL_HEIGHT;

  public bottomTools: string[];
  public selectedBottomTool: string;
  public isBottomToolUserDefined = false;
  public bottomToolHeight: number | null = DEFAULT_BOTTOM_TOOL_HEIGHT;

  public desiredRemainingOpening: number | null = DEFAULT_REMAINING_OPENING;

  private clearResultSubject: Subject<MountHeightCalculationResult | undefined> = new Subject<
    MountHeightCalculationResult | undefined
  >();

  public calculationResult$: Observable<MountHeightCalculationResult | undefined> = merge(
    this.clearResultSubject.asObservable(),
    this.calculationService.calculationResult$,
  ).pipe(
    tap((result) => {
      this.resetInputs(result);
    }),
  );

  constructor(
    public translations: TranslationHelper,
    private translateService: TranslateService,
    private calculationService: MountHeightCalculationService,
  ) {
    this.userDefinedText = translateService.instant(
      translations.CALCULATIONS.INPUT_LABELS.USER_DEFINED,
    );
    this.noAdapterLocalized = translateService.instant(
      translations.CALCULATIONS.INPUT_LABELS.NO_ADAPTER,
    );
    for (const calculationType of Object.values(MountHeightCalculationType)) {
      this.calculationTypes.push({
        id: calculationType,
        name: mountHeightCalculationTypeToTranslation(
          calculationType,
          translations,
          translateService,
        ),
      });
    }
  }

  ngOnInit(): void {
    this.machines = [...MOUNT_HEIGHT_MACHINE.keys()];

    this.adapters = [this.noAdapterLocalized, ...MOUNT_HEIGHT_ADAPTER.keys()];
    this.selectedAdapter = this.noAdapterLocalized;

    this.upperTools = [...MOUNT_HEIGHT_UPPER_TOOLS.keys()];
    this.selectedUpperTool = this.translateService.instant(this.upperTools[0]);

    this.bottomTools = [...MOUNT_HEIGHT_BOTTOM_TOOLS.keys()];
    this.selectedBottomTool = this.bottomTools[0];
  }

  public selectedCalculationTypeName(): string {
    return (
      this.calculationTypes.find((m) => m.id === this.selectedCalculationType)?.name ?? 'unknown'
    );
  }

  public clearResult(): void {
    this.clearResultSubject.next(undefined);
  }

  public calculate(): void {
    switch (this.selectedCalculationType) {
      case MountHeightCalculationType.REMAINING_OPENING:
        this.calculationService.calculateRemainOpening(
          +this.machineHeight!,
          +this.adapterHeight!,
          +this.upperToolHeight!,
          +this.bottomToolHeight!,
        );
        break;
      case MountHeightCalculationType.UPPER_TOOL_HEIGHT:
        this.calculationService.calculateUpperToolHeight(
          +this.machineHeight!,
          +this.bottomToolHeight!,
          +this.desiredRemainingOpening!,
        );
        break;
      case MountHeightCalculationType.MACHINE_SELECTION:
        this.calculationService.calculateMachineMinMountHeight(
          +this.adapterHeight!,
          +this.upperToolHeight!,
          +this.bottomToolHeight!,
          +this.desiredRemainingOpening!,
        );
        break;
    }
  }

  public onSelectMachine(newMachine: string) {
    this.selectedMachine = newMachine;
    this.isMachineUserDefined = false;
    this.machineHeight = MOUNT_HEIGHT_MACHINE.get(newMachine) ?? null;
    this.clearResult();
  }

  public onSelectAdapter(newAdapter: string) {
    this.selectedAdapter = newAdapter;
    this.isAdapterUserDefined = false;
    this.adapterHeight =
      newAdapter === this.noAdapterLocalized ? 0 : MOUNT_HEIGHT_ADAPTER.get(newAdapter) ?? null;
    this.clearResult();
  }

  public onSelectUpperTool(newUpperTool: string) {
    this.selectedUpperTool = this.translateService.instant(newUpperTool);
    this.isUpperToolUserDefined = false;
    this.upperToolHeight = MOUNT_HEIGHT_UPPER_TOOLS.get(newUpperTool) ?? null;
    this.clearResult();
  }

  public onSelectBottomTool(newBottomTool: string) {
    this.selectedBottomTool = newBottomTool;
    this.isBottomToolUserDefined = false;
    this.bottomToolHeight = MOUNT_HEIGHT_BOTTOM_TOOLS.get(newBottomTool) ?? null;
    this.clearResult();
  }

  public calculationTypeChanged(calculationTypeId: MountHeightCalculationType) {
    this.selectedCalculationType = calculationTypeId;
    this.clearResult();
    // Restore defaults
    if (
      calculationTypeId !== MountHeightCalculationType.UPPER_TOOL_HEIGHT &&
      this.upperToolHeight === null
    ) {
      this.upperToolHeight = DEFAULT_UPPER_TOOL_HEIGHT;
    }
    if (
      calculationTypeId !== MountHeightCalculationType.REMAINING_OPENING &&
      this.desiredRemainingOpening === null
    ) {
      this.desiredRemainingOpening = DEFAULT_REMAINING_OPENING;
    }
    if (
      calculationTypeId !== MountHeightCalculationType.MACHINE_SELECTION &&
      this.machineHeight === null
    ) {
      this.machineHeight = MOUNT_HEIGHT_MACHINE.get(DEFAULT_MACHINE_NAME) ?? null;
    }
  }

  public resetInputs(calculationResult: MountHeightCalculationResult | undefined): void {
    if (calculationResult) {
      this.desiredRemainingOpening =
        calculationResult.remainOpening ?? this.desiredRemainingOpening;
      this.machineHeight = calculationResult.machineMinimumMountHeight ?? this.machineHeight;
      this.upperToolHeight = calculationResult.upperToolHeight ?? this.upperToolHeight;
    } else {
      if (this.selectedCalculationType === MountHeightCalculationType.UPPER_TOOL_HEIGHT) {
        this.upperToolHeight = null;
      }
      if (this.selectedCalculationType === MountHeightCalculationType.MACHINE_SELECTION) {
        this.machineHeight = null;
      }
      if (this.selectedCalculationType === MountHeightCalculationType.REMAINING_OPENING) {
        this.desiredRemainingOpening = null;
      }
    }
  }
}
