import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DieWidthCalculationResult } from '../model/die-width-calculation-result';
import { DIE_WIDTH_TO_CALCULATE } from '../data/die-width-to-calculate';

@Injectable()
export class DieWidthCalculationService {
  private calculationResult: BehaviorSubject<DieWidthCalculationResult | undefined> =
    new BehaviorSubject<DieWidthCalculationResult | undefined>(undefined);

  public calculationResult$ = this.calculationResult.asObservable();

  public calculateDieWidth(sheetThickness: number): void {
    const selectedSinkWidthValue = DIE_WIDTH_TO_CALCULATE.find(
      (d) => d.sheetThickness === sheetThickness,
    );
    if (!selectedSinkWidthValue) {
      this.calculationResult.next({
        sheetThickness,
        minimalDieWidth: 0,
        optimalDieWith: 0,
        maximalDieWith: 0,
        error: `Unable to calculate the Die width for thickness ${sheetThickness}`,
      });
    } else {
      this.calculationResult.next(selectedSinkWidthValue);
    }
  }
}
