import { TableInfo } from '../../model/table-result/table-info';
import {
  BLUE_GREY_BORDER_COLOR,
  BLUE_HEADER_BACKGROUND_COLOR,
  GREEN_LEGEND_COLOR,
  LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
} from './color-constants';

export const STEEL_PRESSING_FORCE_TABLE: TableInfo = {
  rows: 23,
  columns: 21,
  caption: 'CALCULATIONS.PRESSING_FORCE_TABLE.PRESSING_FORCE_SPECIFICATION',
  borderColor: BLUE_GREY_BORDER_COLOR,
  legend: [
    {
      color: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
      toolTip: 'CALCULATIONS.PRESSING_FORCE_TABLE.DIE_WIDTH_LEGEND',
    },
    {
      color: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
      toolTip: 'CALCULATIONS.PRESSING_FORCE_TABLE.BENDING_LENGTH_LEGEND',
    },
    {
      color: BLUE_HEADER_BACKGROUND_COLOR,
      toolTip: 'CALCULATIONS.PRESSING_FORCE_TABLE.SHEET_THICKNESS_LEGEND',
    },
    {
      color: BLUE_HEADER_BACKGROUND_COLOR,
      toolTip: 'CALCULATIONS.PRESSING_FORCE_TABLE.UPPER_TOOL_LEGEND',
    },
    {
      color: GREEN_LEGEND_COLOR,
      toolTip: 'CALCULATIONS.PRESSING_FORCE_TABLE.OPTIMUM_DIE_WIDTH',
    },
  ],
  cells: [
    // First header row
    {
      row: 0,
      column: 0,
      value: 'w',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 1,
      value: '4',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 2,
      value: '5',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 3,
      value: '6',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 4,
      value: '8',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 5,
      value: '10',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 6,
      value: '12',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 7,
      value: '14',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 8,
      value: '16',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 9,
      value: '20',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 10,
      value: '24',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 11,
      value: '30',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 12,
      value: '40',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 13,
      value: '50',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 14,
      value: '60',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 15,
      value: '70',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 16,
      value: '80',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 17,
      value: '90',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 18,
      value: '100',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 19,
      value: '120',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 0,
      column: 20,
      value: '150',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    // Second header row
    {
      row: 1,
      column: 0,
      value: 'b1',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 1,
      value: '3.1',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 2,
      value: '3.8',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 3,
      value: '4.5',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 4,
      value: '5.7',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 5,
      value: '7.1',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 6,
      value: '8.2',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 7,
      value: '10.3',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 8,
      value: '11.1',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 9,
      value: '14.5',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 10,
      value: '19',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 11,
      value: '23.7',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 12,
      value: '29.8',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 13,
      value: '34.5',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 14,
      value: '42',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 15,
      value: '48',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 16,
      value: '54',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 17,
      value: '65',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 18,
      value: '68',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 19,
      value: '82',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 1,
      column: 20,
      value: '112.5',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    // Third header row
    {
      row: 2,
      column: 0,
      value: 'b2',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 1,
      value: '3.3',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 2,
      value: '4.1',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 3,
      value: '4.8',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 4,
      value: '6.3',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 5,
      value: '7.8',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 6,
      value: '9.3',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 7,
      value: '11.3',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 8,
      value: '12.1',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 9,
      value: '15.7',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 10,
      value: '19.1',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 11,
      value: '23',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 12,
      value: '31.5',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 13,
      value: '38',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 14,
      value: '',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 15,
      value: '',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 16,
      value: '',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 17,
      value: '',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 18,
      value: '',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 19,
      value: '',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 2,
      column: 20,
      value: '',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    // Fourth header row
    {
      row: 3,
      column: 0,
      value: 's/Ri',
      isHeader: true,
      backgroundColor: BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 1,
      value: '1',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 2,
      value: '1.1',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 3,
      value: '1.4',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 4,
      value: '1.7',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 5,
      value: '2.1',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 6,
      value: '2.5',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 7,
      value: '2.8',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 8,
      value: '3.3',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 9,
      value: '4.1',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 10,
      value: '4.7',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 11,
      value: '4.7',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 12,
      value: '6',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 13,
      value: '7.7',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 14,
      value: '8.7',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 15,
      value: '10.6',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 16,
      value: '12',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 17,
      value: '13.7',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 18,
      value: '15',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 19,
      value: '18.2',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    {
      row: 3,
      column: 20,
      value: '18.5',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },
    // Data rows
    {
      row: 4,
      column: 0,
      value: '0.5',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 4,
      column: 1,
      value: '45',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 2,
      value: '35',
      isHeader: false,
    },

    {
      row: 4,
      column: 3,
      value: '28',
      isHeader: false,
    },

    {
      row: 4,
      column: 4,
      value: '21',
      isHeader: false,
    },

    {
      row: 5,
      column: 0,
      value: '0.75',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 5,
      column: 1,
      value: '102',
      isHeader: false,
    },

    {
      row: 5,
      column: 2,
      value: '78',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 3,
      value: '64',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 4,
      value: '46',
      isHeader: false,
    },

    {
      row: 5,
      column: 5,
      value: '36',
      isHeader: false,
    },

    {
      row: 5,
      column: 6,
      value: '30',
      isHeader: false,
    },

    {
      row: 6,
      column: 0,
      value: '1',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 6,
      column: 2,
      value: '167',
      isHeader: false,
    },

    {
      row: 6,
      column: 3,
      value: '131',
      isHeader: false,
    },

    {
      row: 6,
      column: 4,
      value: '91',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 5,
      value: '70',
      isHeader: false,
    },

    {
      row: 6,
      column: 6,
      value: '57',
      isHeader: false,
    },

    {
      row: 6,
      column: 7,
      value: '48',
      isHeader: false,
    },

    {
      row: 6,
      column: 8,
      value: '41',
      isHeader: false,
    },

    {
      row: 7,
      column: 0,
      value: '1.25',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 7,
      column: 3,
      value: '204',
      isHeader: false,
    },

    {
      row: 7,
      column: 4,
      value: '142',
      isHeader: false,
    },

    {
      row: 7,
      column: 5,
      value: '109',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 7,
      column: 6,
      value: '88',
      isHeader: false,
    },

    {
      row: 7,
      column: 7,
      value: '74',
      isHeader: false,
    },

    {
      row: 7,
      column: 8,
      value: '64',
      isHeader: false,
    },

    {
      row: 7,
      column: 9,
      value: '50',
      isHeader: false,
    },

    {
      row: 8,
      column: 0,
      value: '1.5',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 8,
      column: 3,
      value: '294',
      isHeader: false,
    },

    {
      row: 8,
      column: 4,
      value: '204',
      isHeader: false,
    },

    {
      row: 8,
      column: 5,
      value: '157',
      isHeader: false,
    },

    {
      row: 8,
      column: 6,
      value: '127',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 8,
      column: 7,
      value: '107',
      isHeader: false,
    },

    {
      row: 8,
      column: 8,
      value: '92',
      isHeader: false,
    },

    {
      row: 8,
      column: 9,
      value: '72',
      isHeader: false,
    },

    {
      row: 9,
      column: 0,
      value: '1.75',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 9,
      column: 4,
      value: '278',
      isHeader: false,
    },

    {
      row: 9,
      column: 5,
      value: '213',
      isHeader: false,
    },

    {
      row: 9,
      column: 6,
      value: '173',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 9,
      column: 7,
      value: '146',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 9,
      column: 8,
      value: '126',
      isHeader: false,
    },

    {
      row: 9,
      column: 9,
      value: '99',
      isHeader: false,
    },

    {
      row: 9,
      column: 10,
      value: '81',
      isHeader: false,
    },

    {
      row: 10,
      column: 0,
      value: '2',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 10,
      column: 5,
      value: '279',
      isHeader: false,
    },

    {
      row: 10,
      column: 6,
      value: '226',
      isHeader: false,
    },

    {
      row: 10,
      column: 7,
      value: '190',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 10,
      column: 8,
      value: '164',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 10,
      column: 9,
      value: '129',
      isHeader: false,
    },

    {
      row: 10,
      column: 10,
      value: '106',
      isHeader: false,
    },

    {
      row: 11,
      column: 0,
      value: '2.5',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 11,
      column: 6,
      value: '353',
      isHeader: false,
    },

    {
      row: 11,
      column: 7,
      value: '297',
      isHeader: false,
    },

    {
      row: 11,
      column: 8,
      value: '256',
      isHeader: false,
    },

    {
      row: 11,
      column: 9,
      value: '201',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 11,
      column: 10,
      value: '166',
      isHeader: false,
    },

    {
      row: 11,
      column: 11,
      value: '131',
      isHeader: false,
    },

    {
      row: 12,
      column: 0,
      value: '3',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 12,
      column: 7,
      value: '428',
      isHeader: false,
    },

    {
      row: 12,
      column: 8,
      value: '369',
      isHeader: false,
    },

    {
      row: 12,
      column: 9,
      value: '290',
      isHeader: false,
    },

    {
      row: 12,
      column: 10,
      value: '238',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 12,
      column: 11,
      value: '188',
      isHeader: false,
    },

    {
      row: 12,
      column: 12,
      value: '140',
      isHeader: false,
    },

    {
      row: 13,
      column: 0,
      value: '3.5',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 13,
      column: 8,
      value: '503',
      isHeader: false,
    },

    {
      row: 13,
      column: 9,
      value: '394',
      isHeader: false,
    },

    {
      row: 13,
      column: 10,
      value: '325',
      isHeader: false,
    },

    {
      row: 13,
      column: 11,
      value: '256',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 13,
      column: 12,
      value: '190',
      isHeader: false,
    },

    {
      row: 13,
      column: 13,
      value: '151',
      isHeader: false,
    },

    {
      row: 14,
      column: 0,
      value: '4',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 14,
      column: 9,
      value: '515',
      isHeader: false,
    },

    {
      row: 14,
      column: 10,
      value: '424',
      isHeader: false,
    },

    {
      row: 14,
      column: 11,
      value: '335',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 14,
      column: 12,
      value: '248',
      isHeader: false,
    },

    {
      row: 14,
      column: 13,
      value: '197',
      isHeader: false,
    },

    {
      row: 14,
      column: 14,
      value: '163',
      isHeader: false,
    },

    {
      row: 15,
      column: 0,
      value: '4.5',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 15,
      column: 9,
      value: '652',
      isHeader: false,
    },

    {
      row: 15,
      column: 10,
      value: '537',
      isHeader: false,
    },

    {
      row: 15,
      column: 11,
      value: '424',
      isHeader: false,
    },

    {
      row: 15,
      column: 12,
      value: '314',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 15,
      column: 13,
      value: '249',
      isHeader: false,
    },

    {
      row: 15,
      column: 14,
      value: '207',
      isHeader: false,
    },

    {
      row: 15,
      column: 15,
      value: '177',
      isHeader: false,
    },

    {
      row: 16,
      column: 0,
      value: '5',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 16,
      column: 11,
      value: '523',
      isHeader: false,
    },

    {
      row: 16,
      column: 12,
      value: '388',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 16,
      column: 13,
      value: '308',
      isHeader: false,
    },

    {
      row: 16,
      column: 14,
      value: '255',
      isHeader: false,
    },

    {
      row: 16,
      column: 15,
      value: '218',
      isHeader: false,
    },

    {
      row: 16,
      column: 16,
      value: '190',
      isHeader: false,
    },

    {
      row: 17,
      column: 0,
      value: '6',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 17,
      column: 11,
      value: '754',
      isHeader: false,
    },

    {
      row: 17,
      column: 12,
      value: '558',
      isHeader: false,
    },

    {
      row: 17,
      column: 13,
      value: '443',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 17,
      column: 14,
      value: '368',
      isHeader: false,
    },

    {
      row: 17,
      column: 15,
      value: '314',
      isHeader: false,
    },

    {
      row: 17,
      column: 16,
      value: '274',
      isHeader: false,
    },

    {
      row: 17,
      column: 17,
      value: '243',
      isHeader: false,
    },

    {
      row: 18,
      column: 0,
      value: '7',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 18,
      column: 12,
      value: '760',
      isHeader: false,
    },

    {
      row: 18,
      column: 13,
      value: '604',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 18,
      column: 14,
      value: '501',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 18,
      column: 15,
      value: '428',
      isHeader: false,
    },

    {
      row: 18,
      column: 16,
      value: '373',
      isHeader: false,
    },

    {
      row: 18,
      column: 17,
      value: '331',
      isHeader: false,
    },

    {
      row: 18,
      column: 18,
      value: '297',
      isHeader: false,
    },

    {
      row: 19,
      column: 0,
      value: '8',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 19,
      column: 13,
      value: '864',
      isHeader: false,
    },

    {
      row: 19,
      column: 14,
      value: '705',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 19,
      column: 15,
      value: '595',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 19,
      column: 16,
      value: '515',
      isHeader: false,
    },

    {
      row: 19,
      column: 17,
      value: '454',
      isHeader: false,
    },

    {
      row: 19,
      column: 18,
      value: '406',
      isHeader: false,
    },

    {
      row: 19,
      column: 19,
      value: '335',
      isHeader: false,
    },

    {
      row: 20,
      column: 0,
      value: '10',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 20,
      column: 14,
      value: '1101',
      isHeader: false,
    },

    {
      row: 20,
      column: 15,
      value: '930',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 20,
      column: 16,
      value: '805',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 20,
      column: 17,
      value: '710',
      isHeader: false,
    },

    {
      row: 20,
      column: 18,
      value: '634',
      isHeader: false,
    },

    {
      row: 20,
      column: 19,
      value: '523',
      isHeader: false,
    },

    {
      row: 20,
      column: 20,
      value: '415',
      isHeader: false,
    },

    {
      row: 21,
      column: 0,
      value: '12',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 21,
      column: 15,
      value: '1339',
      isHeader: false,
    },

    {
      row: 21,
      column: 16,
      value: '1159',
      isHeader: false,
    },

    {
      row: 21,
      column: 17,
      value: '1022',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 21,
      column: 18,
      value: '914',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 21,
      column: 19,
      value: '754',
      isHeader: false,
    },

    {
      row: 21,
      column: 20,
      value: '597',
      isHeader: false,
    },

    {
      row: 22,
      column: 0,
      value: '15',
      isHeader: true,
      backgroundColor: LIGHT_BLUE_HEADER_BACKGROUND_COLOR,
    },

    {
      row: 22,
      column: 17,
      value: '1597',
      isHeader: false,
    },

    {
      row: 22,
      column: 18,
      value: '1427',
      isHeader: false,
    },

    {
      row: 22,
      column: 19,
      value: '1178',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },

    {
      row: 22,
      column: 20,
      value: '933',
      isHeader: false,
      backgroundColor: GREEN_LEGEND_COLOR,
    },
  ],
};
