import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lsb-case-height-picture',
  templateUrl: './case-height-picture.component.svg',
  styleUrls: [],
})
export class CaseHeightPictureComponent implements OnInit {
  @Input() halfPressBarDistance: string;
  @Input() upperToolHeight: string;
  @Input() innerBoxHeight: string;

  constructor() {}

  ngOnInit(): void {}
}
