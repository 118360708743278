<div class="input-container">
  <div class="toggle-buttons">
    <xui-button
      (click)="calculationTypeChanged(heightToCalculateType.innerBox); clearResult()"
      type="{{ heightToCalculate === heightToCalculateType.innerBox ? 'primary' : 'secondary' }}"
    >
      {{ translations.CALCULATIONS.INPUT_LABELS.INNER_BOX_HEIGHT | translate }}
    </xui-button>
    <xui-button
      (click)="calculationTypeChanged(heightToCalculateType.upperTool); clearResult()"
      type="{{ heightToCalculate === heightToCalculateType.upperTool ? 'primary' : 'secondary' }}"
    >
      {{ translations.CALCULATIONS.INPUT_LABELS.UPPER_TOOL_HEIGHT | translate }}
    </xui-button>
  </div>
  <xui-input-field
    (focusout)="clearResult()"
    *ngIf="heightToCalculate === heightToCalculateType.upperTool"
    [(text)]="innerBoxHeightValue.value"
    data-ngtx="case-height:innerBoxHeight"
    label="{{ translations.CALCULATIONS.INPUT_LABELS.INNER_BOX_HEIGHT | translate }}"
  >
    <xui-value
      #innerBoxHeightValue
      [(binding)]="innerBoxHeight"
      [fallbackValue]="innerBoxHeight"
      lsbValidateRequired
    >
      <xui-length-converter imperialTargetUnit="inch" metricTargetUnit="mm"></xui-length-converter>
      <xui-number-formatter
        [maxDecimals]="2"
        [unit]="innerBoxHeightValue.unit"
      ></xui-number-formatter>
    </xui-value>
  </xui-input-field>

  <xui-input-field
    (focusout)="clearResult()"
    *ngIf="heightToCalculate === heightToCalculateType.innerBox"
    [(text)]="upperToolHeightValue.value"
    data-ngtx="case-height:upperToolHeight"
    label="{{ translations.CALCULATIONS.INPUT_LABELS.UPPER_TOOL_HEIGHT | translate }}"
  >
    <xui-value
      #upperToolHeightValue
      [(binding)]="upperToolHeight"
      [fallbackValue]="upperToolHeight"
      lsbValidateRequired
    >
      <xui-length-converter imperialTargetUnit="inch" metricTargetUnit="mm"></xui-length-converter>
      <xui-number-formatter
        [maxDecimals]="2"
        [unit]="upperToolHeightValue.unit"
      ></xui-number-formatter>
    </xui-value>
  </xui-input-field>

  <xui-input-field
    (focusout)="clearResult()"
    [(text)]="halfPressBarDistanceValue.value"
    data-ngtx="case-height:halfPressBarDistance"
    label="{{ translations.CALCULATIONS.INPUT_LABELS.HALF_PRESS_BAR_DISTANCE | translate }}"
  >
    <xui-value
      #halfPressBarDistanceValue
      [(binding)]="halfPressBarDistance"
      [fallbackValue]="halfPressBarDistance"
      lsbValidateRequired
    >
      <xui-length-converter imperialTargetUnit="inch" metricTargetUnit="mm"></xui-length-converter>
      <xui-number-formatter
        [maxDecimals]="2"
        [unit]="halfPressBarDistanceValue.unit"
      ></xui-number-formatter>
    </xui-value>
  </xui-input-field>

  <!-- Calculate -->
  <div class="calculate-button button-info-box">
    <xui-button
      (click)="calculate()"
      xuiClickUsageTracking="{{ uiElementIds.CALCULATE_CASE_HEIGHT }}"
    >
      {{ translations.CALCULATIONS.INPUT_LABELS.CALCULATE | translate }}
    </xui-button>
    <xui-icon
      [xuiTooltip]="calculateTooltip"
      class="info-flag"
      iconKey="Tooltip.Information"
    ></xui-icon>

    <xui-tooltip #calculateTooltip>
      <p class="simple-text-tooltip multiline">
        {{ translations.CALCULATIONS.TOOLTIPS.CASE_HEIGHT_TOOLTIP | translate }}
      </p>
    </xui-tooltip>
  </div>

  <div *ngIf="calculationResult$ | async as calculationResult" class="calculation-result">
    <xui-caption-label
      *ngIf="heightToCalculate === heightToCalculateType.innerBox"
      [caption]="translations.CALCULATIONS.INPUT_LABELS.INNER_BOX_HEIGHT | translate"
    >
      <xui-value
        #innerBoxResultValue
        [(binding)]="calculationResult.innerBoxHeight"
        [fallbackValue]="0"
      >
        <xui-length-converter
          imperialTargetUnit="inch"
          metricTargetUnit="mm"
        ></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="0"
          [unit]="innerBoxResultValue.unit"
        ></xui-number-formatter>
      </xui-value>
      {{ innerBoxResultValue.value }}
    </xui-caption-label>

    <xui-caption-label
      *ngIf="heightToCalculate === heightToCalculateType.upperTool"
      [caption]="translations.CALCULATIONS.INPUT_LABELS.UPPER_TOOL_HEIGHT | translate"
    >
      <xui-value
        #upperToolHeightResultValue
        [(binding)]="calculationResult.upperToolHeight"
        [fallbackValue]="0"
      >
        <xui-length-converter
          imperialTargetUnit="inch"
          metricTargetUnit="mm"
        ></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="0"
          [unit]="upperToolHeightResultValue.unit"
        ></xui-number-formatter>
      </xui-value>
      {{ upperToolHeightResultValue.value }}
    </xui-caption-label>
  </div>

  <div class="result-image-block">
    <lsb-case-height-picture
      [halfPressBarDistance]="halfPressBarDistanceImageValue.value"
      [innerBoxHeight]="innerBoxHeightImageValue.value ?? '?'"
      [upperToolHeight]="upperToolImageValue.value ?? '?'"
    >
      <xui-value
        #halfPressBarDistanceImageValue
        [(binding)]="halfPressBarDistance"
        [fallbackValue]="0"
      >
        <xui-length-converter
          imperialTargetUnit="inch"
          metricTargetUnit="mm"
        ></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="2"
          [unit]="halfPressBarDistanceImageValue.unit"
        ></xui-number-formatter>
      </xui-value>
      <xui-value #innerBoxHeightImageValue [(binding)]="innerBoxHeight" [fallbackValue]="0">
        <xui-length-converter
          imperialTargetUnit="inch"
          metricTargetUnit="mm"
        ></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="2"
          [unit]="innerBoxHeightImageValue.unit"
        ></xui-number-formatter>
      </xui-value>
      <xui-value #upperToolImageValue [(binding)]="upperToolHeight" [fallbackValue]="0">
        <xui-length-converter
          imperialTargetUnit="inch"
          metricTargetUnit="mm"
        ></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="0"
          [unit]="upperToolImageValue.unit"
        ></xui-number-formatter>
      </xui-value>
    </lsb-case-height-picture>
  </div>
</div>
