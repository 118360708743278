<div class="input-container">
  <!-- Die Width -->
  <div class="dropdown">
    <span class="dropdown-label">
      {{ translations.CALCULATIONS.TYPES.DIE_WIDTH | translate }}
    </span>
    <xui-dropdown
      #dieWidthDropDown
      [label]="dieWidthInCmOrInch.value"
      data-ngtx="leg-length-force:die-width-dropdown"
    >
      <xui-value #dieWidthInCmOrInch [binding]="selectedDieWidth">
        <xui-length-converter imperialTargetUnit="mm" metricTargetUnit="mm"></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="0"
          [unit]="dieWidthInCmOrInch.unit"
        ></xui-number-formatter>
      </xui-value>
      <xui-dropdown-item
        (click)="selectedDieWidth = dieWidth; clearResult(); dieWidthDropDown.close()"
        *ngFor="let dieWidth of dieWidths"
      >
        <xui-value #dieWidthInCentimeterOrInch [binding]="dieWidth">
          <xui-length-converter
            imperialTargetUnit="mm"
            metricTargetUnit="mm"
          ></xui-length-converter>
          <xui-number-formatter
            [maxDecimals]="0"
            [unit]="dieWidthInCentimeterOrInch.unit"
          ></xui-number-formatter>
        </xui-value>
        {{ dieWidthInCentimeterOrInch.value }}
      </xui-dropdown-item>
    </xui-dropdown>
  </div>

  <!-- Calculate -->
  <div class="calculate-button">
    <xui-button
      (click)="calculate()"
      xuiClickUsageTracking="{{ uiElementIds.CALCULATE_LEG_LENGTH }}"
    >
      {{ translations.CALCULATIONS.INPUT_LABELS.CALCULATE | translate }}
    </xui-button>
  </div>

  <div *ngIf="calculationResult$ | async as calculationResult" class="calculate-button">
    <table>
      <thead>
        <tr>
          <th>{{ translations.CALCULATIONS.RESULT_LABELS.DIE | translate }}</th>
          <th>{{ translations.CALCULATIONS.TYPES.LEG_LENGTH | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let legLength of calculationResult.legLengths">
          <td>{{ legLength.matrix }}</td>
          <td style="text-align: right">
            <xui-value #legLengthResultValue [(binding)]="legLength.legLength" [fallbackValue]="0">
              <xui-length-converter
                imperialTargetUnit="mm"
                metricTargetUnit="mm"
              ></xui-length-converter>
              <xui-number-formatter
                [maxDecimals]="2"
                [unit]="legLengthResultValue.unit"
              ></xui-number-formatter>
            </xui-value>
            {{ legLengthResultValue.value }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="result-image-block">
    <lsb-leg-length-picture
      [dieWidth]="dieWidthImageValue.value"
      [legLength]="translations.CALCULATIONS.TYPES.LEG_LENGTH | translate"
    >
      <xui-value #dieWidthImageValue [(binding)]="selectedDieWidth" [fallbackValue]="0">
        <xui-length-converter imperialTargetUnit="mm" metricTargetUnit="mm"></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="0"
          [unit]="dieWidthImageValue.unit"
        ></xui-number-formatter>
      </xui-value>
    </lsb-leg-length-picture>
  </div>
</div>
