import { TableInfo } from '../../model/table-result/table-info';
import {
  BLUE_GREY_LEGEND_COLOR,
  LIGHT_GREEN_GREY_BORDER_COLOR,
  LIGHT_GREEN_HEADER_COLOR,
} from './color-constants';

export const ROLL_BEND_STEEL_RB200_RB600: TableInfo = {
  rows: 10,
  columns: 7,
  caption: 'CALCULATIONS.PRESSING_FORCE_TABLE.PRESSING_FORCE_SPECIFICATION',
  borderColor: LIGHT_GREEN_GREY_BORDER_COLOR,
  legend: [
    {
      color: BLUE_GREY_LEGEND_COLOR,
      toolTip: 'CALCULATIONS.PRESSING_FORCE_TABLE.OPTIMAL_FOR_R1',
    },
  ],
  cells: [
    {
      row: 0,
      column: 0,
      value: 's',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 0,
      column: 1,
      value: 'OW',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 2,
      value: 'RB 200',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 3,
      value: 'RB 300',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 4,
      value: 'RB 400',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 5,
      value: 'RB 500',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 6,
      value: 'RB 600',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 1,
      column: 0,
      value: '0.5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 1,
      column: 1,
      value: 'R1',
      isHeader: false,
    },

    {
      row: 1,
      column: 2,
      value: '30',
      isHeader: false,
    },

    {
      row: 1,
      column: 3,
      value: '30',
      isHeader: false,
    },

    {
      row: 1,
      column: 4,
      value: '20',
      isHeader: false,
    },

    {
      row: 2,
      column: 0,
      value: '1',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 2,
      column: 1,
      value: 'R1',
      isHeader: false,
    },

    {
      row: 2,
      column: 2,
      value: '100',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 2,
      column: 3,
      value: '60',
      isHeader: false,
    },

    {
      row: 2,
      column: 4,
      value: '40',
      isHeader: false,
    },

    {
      row: 2,
      column: 5,
      value: '30',
      isHeader: false,
    },

    {
      row: 3,
      column: 0,
      value: '1.5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 3,
      column: 1,
      value: 'R1',
      isHeader: false,
    },

    {
      row: 3,
      column: 2,
      value: '240',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 3,
      column: 3,
      value: '130',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 3,
      column: 4,
      value: '90',
      isHeader: false,
    },

    {
      row: 3,
      column: 5,
      value: '50',
      isHeader: false,
    },

    {
      row: 4,
      column: 0,
      value: '2',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 4,
      column: 1,
      value: 'R1',
      isHeader: false,
    },

    {
      row: 4,
      column: 2,
      value: '530',
      isHeader: false,
    },

    {
      row: 4,
      column: 3,
      value: '260',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 4,
      value: '150',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 5,
      value: '100',
      isHeader: false,
    },

    {
      row: 4,
      column: 6,
      value: '80',
      isHeader: false,
    },

    {
      row: 5,
      column: 0,
      value: '2.5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 5,
      column: 1,
      value: 'R1',
      isHeader: false,
    },

    {
      row: 5,
      column: 3,
      value: '460',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 4,
      value: '270',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 5,
      value: '160',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 6,
      value: '130',
      isHeader: false,
    },

    {
      row: 6,
      column: 0,
      value: '3',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 6,
      column: 1,
      value: 'R1',
      isHeader: false,
    },

    {
      row: 6,
      column: 3,
      value: '770',
      isHeader: false,
    },

    {
      row: 6,
      column: 4,
      value: '510',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 5,
      value: '270',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 6,
      value: '180',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 7,
      column: 0,
      value: '4',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 7,
      column: 1,
      value: 'R1',
      isHeader: false,
    },

    {
      row: 7,
      column: 4,
      value: '970',
      isHeader: false,
    },

    {
      row: 7,
      column: 5,
      value: '530',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 7,
      column: 6,
      value: '400',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 8,
      column: 0,
      value: '5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 8,
      column: 1,
      value: 'R4',
      isHeader: false,
    },

    {
      row: 8,
      column: 5,
      value: '880',
      isHeader: false,
    },

    {
      row: 8,
      column: 6,
      value: '660',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 9,
      column: 0,
      value: '6',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 9,
      column: 1,
      value: 'R4',
      isHeader: false,
    },

    {
      row: 9,
      column: 6,
      value: '1050',
      isHeader: false,
    },
  ],
};

export const ROLL_BEND_STAINLESS_STEEL_RB200_RB600: TableInfo = {
  rows: 10,
  columns: 7,
  caption: 'CALCULATIONS.PRESSING_FORCE_TABLE.PRESSING_FORCE_SPECIFICATION',
  borderColor: LIGHT_GREEN_GREY_BORDER_COLOR,
  legend: [
    {
      color: BLUE_GREY_LEGEND_COLOR,
      toolTip: 'CALCULATIONS.PRESSING_FORCE_TABLE.OPTIMAL_FOR_R1',
    },
  ],
  cells: [
    {
      row: 0,
      column: 0,
      value: 's',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 0,
      column: 1,
      value: 'OW',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 2,
      value: 'RB 200',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 3,
      value: 'RB 300',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 4,
      value: 'RB 400',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 5,
      value: 'RB 500',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 6,
      value: 'RB 600',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 1,
      column: 0,
      value: '0.5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 1,
      column: 1,
      value: 'R1',
      isHeader: false,
    },

    {
      row: 1,
      column: 2,
      value: '30',
      isHeader: false,
    },

    {
      row: 1,
      column: 3,
      value: '30',
      isHeader: false,
    },

    {
      row: 1,
      column: 4,
      value: '30',
      isHeader: false,
    },

    {
      row: 2,
      column: 0,
      value: '1',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 2,
      column: 1,
      value: 'R1',
      isHeader: false,
    },

    {
      row: 2,
      column: 2,
      value: '120',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 2,
      column: 3,
      value: '70',
      isHeader: false,
    },

    {
      row: 2,
      column: 4,
      value: '50',
      isHeader: false,
    },

    {
      row: 2,
      column: 5,
      value: '40',
      isHeader: false,
    },

    {
      row: 3,
      column: 0,
      value: '1.5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 3,
      column: 1,
      value: 'R1',
      isHeader: false,
    },

    {
      row: 3,
      column: 2,
      value: '330',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 3,
      column: 3,
      value: '180',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 3,
      column: 4,
      value: '100',
      isHeader: false,
    },

    {
      row: 3,
      column: 5,
      value: '80',
      isHeader: false,
    },

    {
      row: 4,
      column: 0,
      value: '2',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 4,
      column: 1,
      value: 'R1',
      isHeader: false,
    },

    {
      row: 4,
      column: 2,
      value: '800',
      isHeader: false,
    },

    {
      row: 4,
      column: 3,
      value: '400',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 4,
      value: '230',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 5,
      value: '150',
      isHeader: false,
    },

    {
      row: 4,
      column: 6,
      value: '110',
      isHeader: false,
    },

    {
      row: 5,
      column: 0,
      value: '2.5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 5,
      column: 1,
      value: 'R1',
      isHeader: false,
    },

    {
      row: 5,
      column: 3,
      value: '680',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 4,
      value: '400',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 5,
      value: '230',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 6,
      value: '170',
      isHeader: false,
    },

    {
      row: 6,
      column: 0,
      value: '3',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 6,
      column: 1,
      value: 'R1',
      isHeader: false,
    },

    {
      row: 6,
      column: 3,
      value: '1030',
      isHeader: false,
    },

    {
      row: 6,
      column: 4,
      value: '750',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 5,
      value: '380',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 6,
      value: '210',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 7,
      column: 0,
      value: '4',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 7,
      column: 1,
      value: 'R1',
      isHeader: false,
    },

    {
      row: 7,
      column: 4,
      value: '1350',
      isHeader: false,
    },

    {
      row: 7,
      column: 5,
      value: '710',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 7,
      column: 6,
      value: '520',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 8,
      column: 0,
      value: '5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 8,
      column: 1,
      value: 'R4',
      isHeader: false,
    },

    {
      row: 8,
      column: 5,
      value: '1020',
      isHeader: false,
    },

    {
      row: 8,
      column: 6,
      value: '780',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 9,
      column: 0,
      value: '6',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 9,
      column: 1,
      value: 'R4',
      isHeader: false,
    },

    {
      row: 9,
      column: 6,
      value: '1260',
      isHeader: false,
    },
  ],
};

export const ROLL_BEND_ALUMINIUM_RB200_RB600: TableInfo = {
  rows: 10,
  columns: 7,
  caption: 'CALCULATIONS.PRESSING_FORCE_TABLE.PRESSING_FORCE_SPECIFICATION',
  borderColor: LIGHT_GREEN_GREY_BORDER_COLOR,
  legend: [
    {
      color: BLUE_GREY_LEGEND_COLOR,
      toolTip: 'CALCULATIONS.PRESSING_FORCE_TABLE.OPTIMAL_FOR_R1',
    },
  ],
  cells: [
    {
      row: 0,
      column: 0,
      value: 's',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 0,
      column: 1,
      value: 'OW',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 2,
      value: 'RB 200',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 3,
      value: 'RB 300',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 4,
      value: 'RB 400',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 5,
      value: 'RB 500',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 6,
      value: 'RB 600',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 1,
      column: 0,
      value: '0.5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 1,
      column: 1,
      value: 'R1',
      isHeader: false,
    },

    {
      row: 1,
      column: 2,
      value: '20',
      isHeader: false,
    },

    {
      row: 1,
      column: 3,
      value: '20',
      isHeader: false,
    },

    {
      row: 1,
      column: 4,
      value: '10',
      isHeader: false,
    },

    {
      row: 2,
      column: 0,
      value: '1',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 2,
      column: 1,
      value: 'R1',
      isHeader: false,
    },

    {
      row: 2,
      column: 2,
      value: '40',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 2,
      column: 3,
      value: '40',
      isHeader: false,
    },

    {
      row: 2,
      column: 4,
      value: '20',
      isHeader: false,
    },

    {
      row: 2,
      column: 5,
      value: '20',
      isHeader: false,
    },

    {
      row: 3,
      column: 0,
      value: '1.5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 3,
      column: 1,
      value: 'R1',
      isHeader: false,
    },

    {
      row: 3,
      column: 2,
      value: '130',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 3,
      column: 3,
      value: '80',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 3,
      column: 4,
      value: '50',
      isHeader: false,
    },

    {
      row: 3,
      column: 5,
      value: '40',
      isHeader: false,
    },

    {
      row: 4,
      column: 0,
      value: '2',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 4,
      column: 1,
      value: 'R1',
      isHeader: false,
    },

    {
      row: 4,
      column: 2,
      value: '290',
      isHeader: false,
    },

    {
      row: 4,
      column: 3,
      value: '140',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 4,
      value: '70',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 5,
      value: '60',
      isHeader: false,
    },

    {
      row: 4,
      column: 6,
      value: '50',
      isHeader: false,
    },

    {
      row: 5,
      column: 0,
      value: '2.5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 5,
      column: 1,
      value: 'R1',
      isHeader: false,
    },

    {
      row: 5,
      column: 3,
      value: '240',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 4,
      value: '150',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 5,
      value: '100',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 6,
      value: '70',
      isHeader: false,
    },

    {
      row: 6,
      column: 0,
      value: '3',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 6,
      column: 1,
      value: 'R1',
      isHeader: false,
    },

    {
      row: 6,
      column: 3,
      value: '360',
      isHeader: false,
    },

    {
      row: 6,
      column: 4,
      value: '260',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 5,
      value: '170',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 6,
      value: '100',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 7,
      column: 0,
      value: '4',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 7,
      column: 1,
      value: 'R1',
      isHeader: false,
    },

    {
      row: 7,
      column: 4,
      value: '480',
      isHeader: false,
    },

    {
      row: 7,
      column: 5,
      value: '320',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 7,
      column: 6,
      value: '240',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 8,
      column: 0,
      value: '5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 8,
      column: 1,
      value: 'R4',
      isHeader: false,
    },

    {
      row: 8,
      column: 6,
      value: '350',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },
  ],
};

export const ROLL_BEND_STEEL_RB1250: TableInfo = {
  rows: 8,
  columns: 6,
  caption: 'CALCULATIONS.PRESSING_FORCE_TABLE.PRESSING_FORCE_SPECIFICATION',
  borderColor: LIGHT_GREEN_GREY_BORDER_COLOR,
  legend: [
    {
      color: BLUE_GREY_LEGEND_COLOR,
      toolTip: 'CALCULATIONS.PRESSING_FORCE_TABLE.OPTIMAL_FOR_R4',
    },
  ],
  cells: [
    {
      row: 0,
      column: 0,
      value: 's',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 0,
      column: 1,
      value: 'W40',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 2,
      value: 'W50',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 3,
      value: 'W60',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 4,
      value: 'W70',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 5,
      value: 'W80',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 1,
      column: 0,
      value: '3',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 1,
      column: 1,
      value: '105',
      isHeader: false,
    },

    {
      row: 1,
      column: 2,
      value: '85',
      isHeader: false,
    },

    {
      row: 2,
      column: 0,
      value: '4',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 2,
      column: 1,
      value: '240',
      isHeader: false,
    },

    {
      row: 2,
      column: 2,
      value: '190',
      isHeader: false,
    },

    {
      row: 2,
      column: 3,
      value: '160',
      isHeader: false,
    },

    {
      row: 2,
      column: 4,
      value: '130',
      isHeader: false,
    },

    {
      row: 3,
      column: 0,
      value: '5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 3,
      column: 1,
      value: '390',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 3,
      column: 2,
      value: '310',
      isHeader: false,
    },

    {
      row: 3,
      column: 3,
      value: '240',
      isHeader: false,
    },

    {
      row: 3,
      column: 4,
      value: '205',
      isHeader: false,
    },

    {
      row: 3,
      column: 5,
      value: '175',
      isHeader: false,
    },

    {
      row: 4,
      column: 0,
      value: '6',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 4,
      column: 1,
      value: '630',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 2,
      value: '495',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 3,
      value: '400',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 4,
      value: '330',
      isHeader: false,
    },

    {
      row: 4,
      column: 5,
      value: '280',
      isHeader: false,
    },

    {
      row: 5,
      column: 0,
      value: '8',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 5,
      column: 1,
      value: '1060',
      isHeader: false,
    },

    {
      row: 5,
      column: 2,
      value: '830',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 3,
      value: '660',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 4,
      value: '550',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 5,
      value: '460',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 0,
      value: '10',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 6,
      column: 3,
      value: '1150',
      isHeader: false,
    },

    {
      row: 6,
      column: 4,
      value: '970',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 5,
      value: '790',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 7,
      column: 0,
      value: '12',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 7,
      column: 4,
      value: '1390',
      isHeader: false,
    },

    {
      row: 7,
      column: 5,
      value: '1160',
      isHeader: false,
    },
  ],
};

export const ROLL_BEND_STAINLESS_STEEL_RB1250: TableInfo = {
  rows: 10,
  columns: 67,
  caption: 'CALCULATIONS.PRESSING_FORCE_TABLE.PRESSING_FORCE_SPECIFICATION',
  borderColor: LIGHT_GREEN_GREY_BORDER_COLOR,
  legend: [
    {
      color: BLUE_GREY_LEGEND_COLOR,
      toolTip: 'CALCULATIONS.PRESSING_FORCE_TABLE.OPTIMAL_FOR_R4',
    },
  ],
  cells: [
    {
      row: 0,
      column: 0,
      value: 's',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 0,
      column: 1,
      value: 'W40',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 2,
      value: 'W50',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 3,
      value: 'W60',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 4,
      value: 'W70',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 5,
      value: 'W80',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 1,
      column: 0,
      value: '3',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 1,
      column: 1,
      value: '130',
      isHeader: false,
    },

    {
      row: 1,
      column: 2,
      value: '100',
      isHeader: false,
    },

    {
      row: 2,
      column: 0,
      value: '4',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 2,
      column: 1,
      value: '280',
      isHeader: false,
    },

    {
      row: 2,
      column: 2,
      value: '210',
      isHeader: false,
    },

    {
      row: 2,
      column: 3,
      value: '170',
      isHeader: false,
    },

    {
      row: 2,
      column: 4,
      value: '140',
      isHeader: false,
    },

    {
      row: 3,
      column: 0,
      value: '5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 3,
      column: 1,
      value: '500',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 3,
      column: 2,
      value: '380',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 3,
      column: 3,
      value: '300',
      isHeader: false,
    },

    {
      row: 3,
      column: 4,
      value: '250',
      isHeader: false,
    },

    {
      row: 3,
      column: 5,
      value: '210',
      isHeader: false,
    },

    {
      row: 4,
      column: 0,
      value: '6',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 4,
      column: 1,
      value: '770',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 2,
      value: '590',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 3,
      value: '470',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 4,
      value: '390',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 5,
      value: '320',
      isHeader: false,
    },

    {
      row: 5,
      column: 0,
      value: '8',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 5,
      column: 1,
      value: '1370',
      isHeader: false,
    },

    {
      row: 5,
      column: 2,
      value: '1080',
      isHeader: false,
    },

    {
      row: 5,
      column: 3,
      value: '830',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 4,
      value: '680',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 5,
      value: '560',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 0,
      value: '10',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 6,
      column: 3,
      value: '1320',
      isHeader: false,
    },

    {
      row: 6,
      column: 4,
      value: '1160',
      isHeader: false,
    },

    {
      row: 6,
      column: 5,
      value: '970',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 7,
      column: 0,
      value: '12',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 7,
      column: 5,
      value: '1570',
      isHeader: false,
    },
  ],
};

export const ROLL_BEND_ALUMINUM_RB1250: TableInfo = {
  rows: 10,
  columns: 67,
  caption: 'CALCULATIONS.PRESSING_FORCE_TABLE.PRESSING_FORCE_SPECIFICATION',
  borderColor: LIGHT_GREEN_GREY_BORDER_COLOR,
  legend: [
    {
      color: BLUE_GREY_LEGEND_COLOR,
      toolTip: 'CALCULATIONS.PRESSING_FORCE_TABLE.OPTIMAL_FOR_R4',
    },
  ],
  cells: [
    {
      row: 0,
      column: 0,
      value: 's',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 0,
      column: 1,
      value: 'W40',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 2,
      value: 'W50',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 3,
      value: 'W60',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 4,
      value: 'W70',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 5,
      value: 'W80',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 1,
      column: 0,
      value: '3',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 1,
      column: 1,
      value: '65',
      isHeader: false,
    },

    {
      row: 1,
      column: 2,
      value: '55',
      isHeader: false,
    },

    {
      row: 2,
      column: 0,
      value: '4',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 2,
      column: 1,
      value: '130',
      isHeader: false,
    },

    {
      row: 2,
      column: 2,
      value: '105',
      isHeader: false,
    },

    {
      row: 2,
      column: 3,
      value: '85',
      isHeader: false,
    },

    {
      row: 2,
      column: 4,
      value: '70',
      isHeader: false,
    },

    {
      row: 3,
      column: 0,
      value: '5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 3,
      column: 1,
      value: '210',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 3,
      column: 2,
      value: '175',
      isHeader: false,
    },

    {
      row: 3,
      column: 3,
      value: '140',
      isHeader: false,
    },

    {
      row: 3,
      column: 4,
      value: '120',
      isHeader: false,
    },

    {
      row: 3,
      column: 5,
      value: '100',
      isHeader: false,
    },

    {
      row: 4,
      column: 0,
      value: '6',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 4,
      column: 1,
      value: '330',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 2,
      value: '290',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 3,
      value: '210',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 4,
      value: '150',
      isHeader: false,
    },

    {
      row: 4,
      column: 5,
      value: '115',
      isHeader: false,
    },

    {
      row: 5,
      column: 0,
      value: '8',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 5,
      column: 1,
      value: '500',
      isHeader: false,
    },

    {
      row: 5,
      column: 2,
      value: '410',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 3,
      value: '330',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 4,
      value: '280',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 5,
      value: '230',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 0,
      value: '10',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 6,
      column: 3,
      value: '570',
      isHeader: false,
    },

    {
      row: 6,
      column: 4,
      value: '480',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 5,
      value: '400',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 7,
      column: 0,
      value: '12',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 7,
      column: 4,
      value: '740',
      isHeader: false,
    },

    {
      row: 7,
      column: 5,
      value: '580',
      isHeader: false,
    },
  ],
};

// OPT
export const ROLL_BEND_STEEL_OPT_RB200: TableInfo = {
  rows: 10,
  columns: 67,
  caption: 'CALCULATIONS.PRESSING_FORCE_TABLE.PRESSING_FORCE_SPECIFICATION',
  borderColor: LIGHT_GREEN_GREY_BORDER_COLOR,
  legend: [
    {
      color: BLUE_GREY_LEGEND_COLOR,
      toolTip: 'CALCULATIONS.PRESSING_FORCE_TABLE.OPTIMAL_FOR_R1',
    },
  ],
  cells: [
    {
      row: 0,
      column: 0,
      value: 's',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 0,
      column: 1,
      value: 'RB 200 + EV W8/84°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 2,
      value: 'RB 300 + EV W12/84°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 3,
      value: 'RB 400 + EV W16/84°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 4,
      value: 'RB 500 + EV W24/84°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 5,
      value: 'RB 600 + EV W30/84°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 1,
      column: 0,
      value: '0.5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 1,
      column: 1,
      value: '30',
      isHeader: false,
    },

    {
      row: 1,
      column: 2,
      value: '30',
      isHeader: false,
    },

    {
      row: 1,
      column: 3,
      value: '20',
      isHeader: false,
    },

    {
      row: 2,
      column: 0,
      value: '1',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 2,
      column: 1,
      value: '100',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 2,
      column: 2,
      value: '60',
      isHeader: false,
    },

    {
      row: 2,
      column: 3,
      value: '40',
      isHeader: false,
    },

    {
      row: 2,
      column: 4,
      value: '30',
      isHeader: false,
    },

    {
      row: 3,
      column: 0,
      value: '1.5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 3,
      column: 1,
      value: '240',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 3,
      column: 2,
      value: '130',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 3,
      column: 3,
      value: '90',
      isHeader: false,
    },

    {
      row: 3,
      column: 4,
      value: '50',
      isHeader: false,
    },

    {
      row: 4,
      column: 0,
      value: '2',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 4,
      column: 2,
      value: '260',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 3,
      value: '150',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 4,
      value: '100',
      isHeader: false,
    },

    {
      row: 4,
      column: 5,
      value: '80',
      isHeader: false,
    },

    {
      row: 5,
      column: 0,
      value: '2.5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 5,
      column: 2,
      value: '460',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 3,
      value: '270',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 4,
      value: '160',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 5,
      value: '130',
      isHeader: false,
    },

    {
      row: 6,
      column: 0,
      value: '3',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 6,
      column: 3,
      value: '510',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 4,
      value: '270',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 5,
      value: '180',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 7,
      column: 0,
      value: '4',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 7,
      column: 4,
      value: '530',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 7,
      column: 5,
      value: '400',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 8,
      column: 0,
      value: '5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 8,
      column: 5,
      value: '660',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },
  ],
};

export const ROLL_BEND_STAINLESS_STEEL_OPT_RB200: TableInfo = {
  rows: 10,
  columns: 67,
  caption: 'CALCULATIONS.PRESSING_FORCE_TABLE.PRESSING_FORCE_SPECIFICATION',
  borderColor: LIGHT_GREEN_GREY_BORDER_COLOR,
  legend: [
    {
      color: BLUE_GREY_LEGEND_COLOR,
      toolTip: 'CALCULATIONS.PRESSING_FORCE_TABLE.OPTIMAL_FOR_R1',
    },
  ],
  cells: [
    {
      row: 0,
      column: 0,
      value: 's',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 0,
      column: 1,
      value: 'RB 200 + EV W8/84°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 2,
      value: 'RB 300 + EV W12/84°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 3,
      value: 'RB 400 + EV W16/84°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 4,
      value: 'RB 500 + EV W24/84°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 5,
      value: 'RB 600 + EV W30/84°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 1,
      column: 0,
      value: '0.5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 1,
      column: 1,
      value: '30',
      isHeader: false,
    },

    {
      row: 1,
      column: 2,
      value: '30',
      isHeader: false,
    },

    {
      row: 1,
      column: 3,
      value: '30',
      isHeader: false,
    },

    {
      row: 2,
      column: 0,
      value: '1',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 2,
      column: 1,
      value: '120',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 2,
      column: 2,
      value: '70',
      isHeader: false,
    },

    {
      row: 2,
      column: 3,
      value: '50',
      isHeader: false,
    },

    {
      row: 2,
      column: 4,
      value: '40',
      isHeader: false,
    },

    {
      row: 3,
      column: 0,
      value: '1.5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 3,
      column: 1,
      value: '330',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 3,
      column: 2,
      value: '180',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 3,
      column: 3,
      value: '100',
      isHeader: false,
    },

    {
      row: 3,
      column: 4,
      value: '80',
      isHeader: false,
    },

    {
      row: 4,
      column: 0,
      value: '2',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 4,
      column: 2,
      value: '400',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 3,
      value: '230',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 4,
      value: '150',
      isHeader: false,
    },

    {
      row: 4,
      column: 5,
      value: '110',
      isHeader: false,
    },

    {
      row: 5,
      column: 0,
      value: '2.5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 5,
      column: 3,
      value: '400',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 4,
      value: '230',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 5,
      value: '170',
      isHeader: false,
    },

    {
      row: 6,
      column: 0,
      value: '3',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 6,
      column: 3,
      value: '750',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 4,
      value: '380',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 5,
      value: '210',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 7,
      column: 0,
      value: '4',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 7,
      column: 4,
      value: '710',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 7,
      column: 5,
      value: '520',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 8,
      column: 0,
      value: '5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 8,
      column: 5,
      value: '780',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },
  ],
};
export const ROLL_BEND_ALUMINUM_OPT_RB200: TableInfo = {
  rows: 10,
  columns: 67,
  caption: 'CALCULATIONS.PRESSING_FORCE_TABLE.PRESSING_FORCE_SPECIFICATION',
  borderColor: LIGHT_GREEN_GREY_BORDER_COLOR,
  legend: [
    {
      color: BLUE_GREY_LEGEND_COLOR,
      toolTip: 'CALCULATIONS.PRESSING_FORCE_TABLE.OPTIMAL_FOR_R1',
    },
  ],
  cells: [
    {
      row: 0,
      column: 0,
      value: 's',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 0,
      column: 1,
      value: 'RB 200 + EV W8/84°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 2,
      value: 'RB 300 + EV W12/84°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 3,
      value: 'RB 400 + EV W16/84°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 4,
      value: 'RB 500 + EV W24/84°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 5,
      value: 'RB 600 + EV W30/84°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 1,
      column: 0,
      value: '0.5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 1,
      column: 1,
      value: '20',
      isHeader: false,
    },

    {
      row: 1,
      column: 2,
      value: '20',
      isHeader: false,
    },

    {
      row: 1,
      column: 3,
      value: '10',
      isHeader: false,
    },

    {
      row: 2,
      column: 0,
      value: '1',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 2,
      column: 1,
      value: '40',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 2,
      column: 2,
      value: '40',
      isHeader: false,
    },

    {
      row: 2,
      column: 3,
      value: '20',
      isHeader: false,
    },

    {
      row: 2,
      column: 4,
      value: '20',
      isHeader: false,
    },

    {
      row: 3,
      column: 0,
      value: '1.5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 3,
      column: 1,
      value: '130',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 3,
      column: 2,
      value: '80',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 3,
      column: 3,
      value: '50',
      isHeader: false,
    },

    {
      row: 3,
      column: 4,
      value: '40',
      isHeader: false,
    },

    {
      row: 4,
      column: 0,
      value: '2',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 4,
      column: 2,
      value: '140',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 3,
      value: '70',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 4,
      value: '60',
      isHeader: false,
    },

    {
      row: 4,
      column: 5,
      value: '50',
      isHeader: false,
    },

    {
      row: 5,
      column: 0,
      value: '2.5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 5,
      column: 2,
      value: '240',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 3,
      value: '150',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 4,
      value: '100',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 5,
      value: '70',
      isHeader: false,
    },

    {
      row: 6,
      column: 0,
      value: '3',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 6,
      column: 3,
      value: '260',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 4,
      value: '170',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 5,
      value: '100',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 7,
      column: 0,
      value: '4',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 7,
      column: 4,
      value: '320',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 7,
      column: 5,
      value: '240',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 8,
      column: 0,
      value: '5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 8,
      column: 5,
      value: '350',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },
  ],
};
export const ROLL_BEND_STEEL_OPT_RB1250: TableInfo = {
  rows: 10,
  columns: 67,
  caption: 'CALCULATIONS.PRESSING_FORCE_TABLE.PRESSING_FORCE_SPECIFICATION',
  borderColor: LIGHT_GREEN_GREY_BORDER_COLOR,
  legend: [
    {
      color: BLUE_GREY_LEGEND_COLOR,
      toolTip: 'CALCULATIONS.PRESSING_FORCE_TABLE.OPTIMAL_FOR_R4',
    },
  ],
  cells: [
    {
      row: 0,
      column: 0,
      value: 's',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 0,
      column: 1,
      value: 'W40 + EV W40/80°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 2,
      value: 'W50 + EV W50/80°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 3,
      value: 'W60 + EV W60/80°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 4,
      value: 'W70 + EV W70/80°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 5,
      value: 'W80 + EV W80/80°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 1,
      column: 0,
      value: '3',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 1,
      column: 1,
      value: '105',
      isHeader: false,
    },

    {
      row: 1,
      column: 2,
      value: '85',
      isHeader: false,
    },

    {
      row: 2,
      column: 0,
      value: '4',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 2,
      column: 1,
      value: '240',
      isHeader: false,
    },

    {
      row: 2,
      column: 2,
      value: '190',
      isHeader: false,
    },

    {
      row: 2,
      column: 3,
      value: '160',
      isHeader: false,
    },

    {
      row: 2,
      column: 4,
      value: '130',
      isHeader: false,
    },

    {
      row: 3,
      column: 0,
      value: '5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 3,
      column: 1,
      value: '390',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 3,
      column: 2,
      value: '310',
      isHeader: false,
    },

    {
      row: 3,
      column: 3,
      value: '240',
      isHeader: false,
    },

    {
      row: 3,
      column: 4,
      value: '205',
      isHeader: false,
    },

    {
      row: 3,
      column: 5,
      value: '175',
      isHeader: false,
    },

    {
      row: 4,
      column: 0,
      value: '6',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 4,
      column: 1,
      value: '630',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 2,
      value: '495',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 3,
      value: '400',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 4,
      value: '330',
      isHeader: false,
    },

    {
      row: 4,
      column: 5,
      value: '280',
      isHeader: false,
    },

    {
      row: 5,
      column: 0,
      value: '8',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 5,
      column: 3,
      value: '660',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 4,
      value: '550',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 5,
      value: '460',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 0,
      value: '10',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 6,
      column: 4,
      value: '970',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 5,
      value: '790',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 7,
      column: 0,
      value: '12',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 7,
      column: 5,
      value: '1160',
      isHeader: false,
    },
  ],
};

export const ROLL_BEND_STAINLESS_STEEL_OPT_RB1250: TableInfo = {
  rows: 10,
  columns: 67,
  caption: 'CALCULATIONS.PRESSING_FORCE_TABLE.PRESSING_FORCE_SPECIFICATION',
  borderColor: LIGHT_GREEN_GREY_BORDER_COLOR,
  legend: [
    {
      color: BLUE_GREY_LEGEND_COLOR,
      toolTip: 'CALCULATIONS.PRESSING_FORCE_TABLE.OPTIMAL_FOR_R4',
    },
  ],
  cells: [
    {
      row: 0,
      column: 0,
      value: 's',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 0,
      column: 1,
      value: 'W40 + EV W40/80°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 2,
      value: 'W50 + EV W50/80°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 3,
      value: 'W60 + EV W60/80°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 4,
      value: 'W70 + EV W70/80°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 5,
      value: 'W80 + EV W80/80°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 1,
      column: 0,
      value: '3',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 1,
      column: 1,
      value: '130',
      isHeader: false,
    },

    {
      row: 1,
      column: 2,
      value: '100',
      isHeader: false,
    },

    {
      row: 2,
      column: 0,
      value: '4',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 2,
      column: 1,
      value: '280',
      isHeader: false,
    },

    {
      row: 2,
      column: 2,
      value: '210',
      isHeader: false,
    },

    {
      row: 2,
      column: 3,
      value: '170',
      isHeader: false,
    },

    {
      row: 2,
      column: 4,
      value: '140',
      isHeader: false,
    },

    {
      row: 3,
      column: 0,
      value: '5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 3,
      column: 1,
      value: '500',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 3,
      column: 2,
      value: '380',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 3,
      column: 3,
      value: '300',
      isHeader: false,
    },

    {
      row: 3,
      column: 4,
      value: '250',
      isHeader: false,
    },

    {
      row: 3,
      column: 5,
      value: '210',
      isHeader: false,
    },

    {
      row: 4,
      column: 0,
      value: '6',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 4,
      column: 1,
      value: '770',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 2,
      value: '590',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 3,
      value: '470',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 4,
      value: '390',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 5,
      value: '320',
      isHeader: false,
    },

    {
      row: 5,
      column: 0,
      value: '8',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 5,
      column: 3,
      value: '830',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 4,
      value: '680',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 5,
      value: '560',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 0,
      value: '10',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 6,
      column: 4,
      value: '1160',
      isHeader: false,
    },

    {
      row: 6,
      column: 5,
      value: '970',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 7,
      column: 0,
      value: '12',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 7,
      column: 5,
      value: '1570',
      isHeader: false,
    },
  ],
};
export const ROLL_BEND_ALUMINUM_OPT_RB1250: TableInfo = {
  rows: 10,
  columns: 67,
  caption: 'CALCULATIONS.PRESSING_FORCE_TABLE.PRESSING_FORCE_SPECIFICATION',
  borderColor: LIGHT_GREEN_GREY_BORDER_COLOR,
  legend: [
    {
      color: BLUE_GREY_LEGEND_COLOR,
      toolTip: 'CALCULATIONS.PRESSING_FORCE_TABLE.OPTIMAL_FOR_R4',
    },
  ],
  cells: [
    {
      row: 0,
      column: 0,
      value: 's',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 0,
      column: 1,
      value: 'W40 + EV W40/80°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 2,
      value: 'W50 + EV W50/80°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 3,
      value: 'W60 + EV W60/80°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 4,
      value: 'W70 + EV W70/80°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 0,
      column: 5,
      value: 'W80 + EV W80/80°',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_HEADER_COLOR,
    },

    {
      row: 1,
      column: 0,
      value: '3',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 1,
      column: 1,
      value: '65',
      isHeader: false,
    },

    {
      row: 1,
      column: 2,
      value: '55',
      isHeader: false,
    },

    {
      row: 2,
      column: 0,
      value: '4',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 2,
      column: 1,
      value: '130',
      isHeader: false,
    },

    {
      row: 2,
      column: 2,
      value: '105',
      isHeader: false,
    },

    {
      row: 2,
      column: 3,
      value: '85',
      isHeader: false,
    },

    {
      row: 2,
      column: 4,
      value: '70',
      isHeader: false,
    },

    {
      row: 3,
      column: 0,
      value: '5',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 3,
      column: 1,
      value: '210',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 3,
      column: 2,
      value: '175',
      isHeader: false,
    },

    {
      row: 3,
      column: 3,
      value: '140',
      isHeader: false,
    },

    {
      row: 3,
      column: 4,
      value: '120',
      isHeader: false,
    },

    {
      row: 3,
      column: 5,
      value: '100',
      isHeader: false,
    },

    {
      row: 4,
      column: 0,
      value: '6',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 4,
      column: 1,
      value: '330',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 2,
      value: '290',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 3,
      value: '210',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 4,
      column: 4,
      value: '150',
      isHeader: false,
    },

    {
      row: 4,
      column: 5,
      value: '115',
      isHeader: false,
    },

    {
      row: 5,
      column: 0,
      value: '8',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 5,
      column: 1,
      value: '500',
      isHeader: false,
    },

    {
      row: 5,
      column: 2,
      value: '410',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 3,
      value: '330',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 4,
      value: '280',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 5,
      column: 5,
      value: '230',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 0,
      value: '10',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 6,
      column: 3,
      value: '570',
      isHeader: false,
    },

    {
      row: 6,
      column: 4,
      value: '480',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 6,
      column: 5,
      value: '400',
      isHeader: false,
      backgroundColor: BLUE_GREY_LEGEND_COLOR,
    },

    {
      row: 7,
      column: 0,
      value: '12',
      isHeader: true,
      backgroundColor: LIGHT_GREEN_GREY_BORDER_COLOR,
    },

    {
      row: 7,
      column: 4,
      value: '740',
      isHeader: false,
    },

    {
      row: 7,
      column: 5,
      value: '580',
      isHeader: false,
    },
  ],
};
