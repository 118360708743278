import { Component } from '@angular/core';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import { TranslationHelper } from '../../../shared/helpers';
import { CaseHeightCalculationService } from '../../services/case-height-calculation.service';
import { CaseHeightCalculationResult } from '../../model/case-height-calculation-result';
import { merge, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

export enum heightToCalculateType {
  innerBox,
  upperTool,
}

export const DEFAULT_UPPER_TOOL_HEIGHT = 120;
export const DEFAULT_INNER_BOX_HEIGHT = 65;

@Component({
  selector: 'lsb-case-height',
  templateUrl: './case-height.component.html',
  styleUrls: ['../../styles/calculations-base-style.scss', './case-height.component.scss'],
  providers: [CaseHeightCalculationService],
})
export class CaseHeightComponent {
  public readonly uiElementIds = UiElementIds;
  public upperToolHeight: number | null = DEFAULT_UPPER_TOOL_HEIGHT;
  public innerBoxHeight: number | null = DEFAULT_INNER_BOX_HEIGHT;
  public halfPressBarDistance: number = 24;

  public heightToCalculateType = heightToCalculateType;
  public heightToCalculate: heightToCalculateType = heightToCalculateType.innerBox;

  private clearResultSubject: Subject<CaseHeightCalculationResult | undefined> = new Subject<
    CaseHeightCalculationResult | undefined
  >();
  public calculationResult$: Observable<CaseHeightCalculationResult | undefined> = merge(
    this.clearResultSubject.asObservable(),
    this.calculationService.calculationResult$,
  ).pipe(tap((result) => this.resetInputs(result)));

  constructor(
    public translations: TranslationHelper,
    private calculationService: CaseHeightCalculationService,
  ) {}

  public calculate(): void {
    if (this.heightToCalculate === heightToCalculateType.innerBox) {
      this.calculationService.calculateInnerBoxHeight(
        +this.upperToolHeight!,
        this.halfPressBarDistance,
      );
    }
    if (this.heightToCalculate === heightToCalculateType.upperTool) {
      this.calculationService.calculateUpperToolHeight(
        +this.innerBoxHeight!,
        this.halfPressBarDistance,
      );
    }
  }

  public clearResult(): void {
    this.clearResultSubject.next();
  }

  public calculationTypeChanged(newCalculationType: heightToCalculateType) {
    this.heightToCalculate = newCalculationType;
    this.restoreDefaultValues();
  }

  public resetInputs(calculationResult: CaseHeightCalculationResult | undefined): void {
    if (calculationResult) {
      this.upperToolHeight = calculationResult.upperToolHeight ?? this.upperToolHeight;
      this.innerBoxHeight = calculationResult.innerBoxHeight ?? this.innerBoxHeight;
    } else {
      if (this.heightToCalculate === heightToCalculateType.upperTool) {
        this.upperToolHeight = null;
      }
      if (this.heightToCalculate === heightToCalculateType.innerBox) {
        this.innerBoxHeight = null;
      }
    }
  }

  private restoreDefaultValues(): void {
    if (
      this.heightToCalculate === heightToCalculateType.innerBox &&
      this.upperToolHeight === null
    ) {
      this.upperToolHeight = DEFAULT_UPPER_TOOL_HEIGHT;
    }
    if (
      this.heightToCalculate === heightToCalculateType.upperTool &&
      this.innerBoxHeight === null
    ) {
      this.innerBoxHeight = DEFAULT_INNER_BOX_HEIGHT;
    }
  }
}
