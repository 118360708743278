import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BEND_INNER_RADIUS_CALCULATIONS } from '../data/bend-inner-radius-calculations';
import { BendInnerRadiusCalculationResult } from '../model/bend-inner-radius-calculation-result';
import { Material } from '../model/material';

@Injectable()
export class BendInnerRadiusCalculationService {
  private calculationResult: BehaviorSubject<BendInnerRadiusCalculationResult | undefined> =
    new BehaviorSubject<BendInnerRadiusCalculationResult | undefined>(undefined);
  public calculationResult$ = this.calculationResult.asObservable();

  private availableSheetThicknesses: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  public availableSheetThicknesses$ = this.availableSheetThicknesses.asObservable();

  private availableUpperToolRadii: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  public availableUpperToolRadii$ = this.availableUpperToolRadii.asObservable();

  private availableDieWidths: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  public availableDieWidths$ = this.availableDieWidths.asObservable();

  private availableBendingAngles: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  public availableBendingAngles$ = this.availableBendingAngles.asObservable();

  constructor() {}

  public updateAvailableOptions(
    material: Material,
    sheetThickness: number,
    dieWidth: number,
    upperToolRadius: number,
    bendingAngle: number,
  ): void {
    const sheetThicknesses = BEND_INNER_RADIUS_CALCULATIONS.filter(
      (d) => d.material === material,
    ).map((d) => d.sheetThickness);
    this.availableSheetThicknesses.next([...new Set(sheetThicknesses)]);

    const dieWidths = BEND_INNER_RADIUS_CALCULATIONS.filter(
      (d) => d.material === material && d.sheetThickness === sheetThickness,
    ).map((d) => d.sinkWidth);
    this.availableDieWidths.next([...new Set(dieWidths)]);

    const upperToolRadii = BEND_INNER_RADIUS_CALCULATIONS.filter(
      (d) =>
        d.material === material && d.sheetThickness === sheetThickness && d.sinkWidth === dieWidth,
    ).map((d) => d.upperToolRadius);
    this.availableUpperToolRadii.next([...new Set(upperToolRadii)]);

    const bendingAngles = BEND_INNER_RADIUS_CALCULATIONS.filter(
      (d) =>
        d.material === material &&
        d.sheetThickness === sheetThickness &&
        d.upperToolRadius === upperToolRadius &&
        d.sinkWidth === dieWidth,
    ).map((d) => d.bendingAngle);
    this.availableBendingAngles.next([...new Set(bendingAngles)]);
  }

  public calculate(
    material: Material,
    sheetThickness: number,
    sinkWidth: number,
    upperToolRadius: number,
    bendingAngle: number,
  ): void {
    const availableCalculation = BEND_INNER_RADIUS_CALCULATIONS.find(
      (d) =>
        d.material === material &&
        d.sheetThickness === sheetThickness &&
        d.sinkWidth === sinkWidth &&
        d.upperToolRadius === upperToolRadius &&
        d.bendingAngle === bendingAngle,
    );

    if (availableCalculation) {
      this.calculationResult.next({
        bendInnerRadius: availableCalculation?.bendInnerRadius,
        errorMessages: undefined,
      });
    } else {
      this.calculationResult.next({
        bendInnerRadius: undefined,
        errorMessages: ['no calculation found for given parameters.'],
      });
    }
  }
}
