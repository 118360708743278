import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { merge, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TranslationHelper } from '../../../shared/helpers';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import { materialToTranslation } from '../../helpers/enum-to-translation';
import { BendInnerRadiusCalculationResult } from '../../model/bend-inner-radius-calculation-result';
import {
  BEND_INNER_RADIUS_BENDING_ANGLES,
  BEND_INNER_RADIUS_DIE_WIDTHS,
  BEND_INNER_RADIUS_MATERIALS,
  BEND_INNER_RADIUS_SHEET_THICKNESSES,
  BEND_INNER_RADIUS_UPPER_TOOL_RADII,
} from '../../model/default-values/bend-inner-radius-default-values';
import { DropdownItemValue } from '../../model/dropdown-item-value';
import { Material } from '../../model/material';
import { TensileStrengths } from '../../model/tensile-strength';
import { ValidityDropdownItemValue } from '../../model/validity-dropdown-item-value';
import { BendInnerRadiusCalculationService } from '../../services/bend-inner-radius-calculation.service';

@Component({
  selector: 'lsb-bend-inner-radius',
  templateUrl: './bend-inner-radius.component.html',
  styleUrls: ['../../styles/calculations-base-style.scss', './bend-inner-radius.component.scss'],
  providers: [BendInnerRadiusCalculationService],
})
export class BendInnerRadiusComponent implements OnInit {
  public readonly uiElementIds = UiElementIds;

  public materials: DropdownItemValue<Material>[] = [];
  public selectedMaterial: Material = Material.STAINLESS_STEEL;
  public tensileStrength: number = TensileStrengths.get(Material.STAINLESS_STEEL)!;

  public sheetThicknesses: ValidityDropdownItemValue<number>[] = [];
  public selectedSheetThickness: ValidityDropdownItemValue<number>;
  public sheetThicknessForCalculation: number;

  public dieWidths: ValidityDropdownItemValue<number>[] = [];
  public selectedDieWidth: ValidityDropdownItemValue<number>;
  public dieWidthForCalculation: number;

  public upperToolRadii: ValidityDropdownItemValue<number>[] = [];
  public selectedUpperToolRadius: ValidityDropdownItemValue<number>;
  public upperToolRadiusForCalculation: number;

  public bendingAngles: ValidityDropdownItemValue<number>[] = [];
  public selectedBendingAngle: ValidityDropdownItemValue<number>;
  public bendingAngleForCalculation: number;

  public bendInnerRadiusForImage: number = -1;

  public calculationErrors$ = this.calculationService.calculationResult$.pipe(
    map((r) => r?.errorMessages),
  );

  private clearResultSubject: Subject<BendInnerRadiusCalculationResult | undefined> = new Subject<
    BendInnerRadiusCalculationResult | undefined
  >();
  public calculationResult$: Observable<BendInnerRadiusCalculationResult | undefined> = merge(
    this.clearResultSubject.asObservable(),
    this.calculationService.calculationResult$,
  ).pipe(
    tap((result) => {
      if (result) {
        this.bendInnerRadiusForImage = result.bendInnerRadius ?? -1;
      } else {
        this.bendInnerRadiusForImage = -1;
      }
    }),
  );

  constructor(
    public translations: TranslationHelper,
    private translateService: TranslateService,
    private calculationService: BendInnerRadiusCalculationService,
  ) {
    for (const material of BEND_INNER_RADIUS_MATERIALS) {
      this.materials.push({
        id: material,
        name: materialToTranslation(material, translations, translateService),
      });
    }

    for (const sheetThickness of BEND_INNER_RADIUS_SHEET_THICKNESSES) {
      this.sheetThicknesses.push({
        id: sheetThickness,
        name: sheetThickness.toString(),
        isValid: true,
      });
    }
    this.selectedSheetThickness = this.sheetThicknesses[0];
    this.sheetThicknessForCalculation = this.selectedSheetThickness.id;

    this.calculationService.availableSheetThicknesses$.subscribe((availableThicknesses) => {
      for (const sheetThickness of this.sheetThicknesses) {
        if (availableThicknesses.indexOf(sheetThickness.id) == -1) {
          sheetThickness.isValid = false;
        } else {
          sheetThickness.isValid = true;
        }
      }
    });

    for (const dieWidth of BEND_INNER_RADIUS_DIE_WIDTHS) {
      this.dieWidths.push({
        id: dieWidth,
        name: dieWidth.toString(),
        isValid: true,
      });
    }
    this.selectedDieWidth = this.dieWidths[0];
    this.dieWidthForCalculation = this.selectedDieWidth.id;

    this.calculationService.availableDieWidths$.subscribe((availableDieWidths) => {
      for (const dieWidth of this.dieWidths) {
        if (availableDieWidths.indexOf(dieWidth.id) == -1) {
          dieWidth.isValid = false;
        } else {
          dieWidth.isValid = true;
        }
      }
    });

    for (const radius of BEND_INNER_RADIUS_UPPER_TOOL_RADII) {
      this.upperToolRadii.push({
        id: radius,
        name: radius.toString(),
        isValid: true,
      });
    }
    this.selectedUpperToolRadius = this.upperToolRadii[0];
    this.upperToolRadiusForCalculation = this.selectedUpperToolRadius.id;

    this.calculationService.availableUpperToolRadii$.subscribe((availableRadii) => {
      for (const radius of this.upperToolRadii) {
        if (availableRadii.indexOf(radius.id) == -1) {
          radius.isValid = false;
        } else {
          radius.isValid = true;
        }
      }
    });

    for (const bendingAngle of BEND_INNER_RADIUS_BENDING_ANGLES) {
      this.bendingAngles.push({
        id: bendingAngle,
        name: bendingAngle.toString(),
        isValid: true,
      });
    }
    this.selectedBendingAngle = this.bendingAngles[0];
    this.bendingAngleForCalculation = this.selectedBendingAngle.id;

    this.calculationService.availableBendingAngles$.subscribe((availableBendingAngles) => {
      for (const bendingAngle of this.bendingAngles) {
        if (availableBendingAngles.indexOf(bendingAngle.id) == -1) {
          bendingAngle.isValid = false;
        } else {
          bendingAngle.isValid = true;
        }
      }
    });
  }

  ngOnInit(): void {
    this.updateAvailableOptions();
  }

  public selectedMaterialName(): string {
    if (this.selectedMaterial) {
      return this.materials.find((m) => m.id === this.selectedMaterial)?.name ?? 'unknown';
    } else {
      return this.translateService.instant(
        this.translations.CALCULATIONS.INPUT_LABELS.USER_DEFINED,
      );
    }
  }

  public onSelectMaterial(newMaterial: Material) {
    this.selectedMaterial = newMaterial;
    this.tensileStrength = TensileStrengths.get(this.selectedMaterial)!;
    this.onSelectItem();
  }

  public onSelectItem(): void {
    this.updateAvailableOptions();
    this.clearResult();
  }

  public clearResult(): void {
    this.clearResultSubject.next(undefined);
  }

  public updateAvailableOptions(): void {
    this.calculationService.updateAvailableOptions(
      this.selectedMaterial,
      this.sheetThicknessForCalculation,
      +this.dieWidthForCalculation,
      this.upperToolRadiusForCalculation,
      this.bendingAngleForCalculation,
    );
  }

  public calculate(): void {
    this.calculationService.calculate(
      this.selectedMaterial,
      this.sheetThicknessForCalculation,
      +this.dieWidthForCalculation,
      this.upperToolRadiusForCalculation,
      this.bendingAngleForCalculation,
    );
  }
}
