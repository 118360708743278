import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CaseHeightCalculationResult } from '../model/case-height-calculation-result';

@Injectable()
export class CaseHeightCalculationService {
  private calculationResult: BehaviorSubject<CaseHeightCalculationResult | undefined> =
    new BehaviorSubject<CaseHeightCalculationResult | undefined>(undefined);

  public calculationResult$ = this.calculationResult.asObservable();
  constructor() {}

  // InnerBox = Schachtelinnenhöhe => SHI = (OWH-B)/1,414 x 0,95
  // OWH - upperToolHeight
  // b - distance between Modufix top edge and center of press bar
  public calculateInnerBoxHeight(upperToolHeight: number, b: number): void {
    const innerBoxHeight = (0.95 * (+upperToolHeight - +b)) / 1.414;
    this.calculationResult.next({ innerBoxHeight, upperToolHeight });
  }

  // UpperTool = Oberwerkzeughöhe => OWH = SHI/0,95 x 1,414 + B
  // SHI - innerBoxHeight
  // b - distance between Modufix top edge and center of press bar
  public calculateUpperToolHeight(innerBoxHeight: number, b: number): void {
    const upperToolHeight = 1.414 * (+innerBoxHeight / 0.95) + +b;
    this.calculationResult.next({ innerBoxHeight, upperToolHeight });
  }
}
