<div class="logo-column">
  <img class="logo" src="./assets/img/dashboard/trumpf-logo.png" />
</div>

<div class="cards">
  <div class="row top">
    <xui-welcome-tile
      class="tile welcome"
      headline="BendGuide"
      [description]="translations.DASHBOARD.WELCOME.DESCRIPTION | translate"
    >
      <!-- image sources for background image -->
      <source src="./assets/img/dashboard/hero-desktop.png" media="(min-width: 1000px)" />
      <source src="./assets/img/dashboard/hero-mobile.png" />
      <img src="./assets/img/dashboard/hero-mobile.png" />
    </xui-welcome-tile>
  </div>
  <div class="rule"></div>
  <div class="row bottom">
    <lsb-news-tile class="tile news"></lsb-news-tile>
    <xui-getting-started-tile
      class="tile"
      [videoSrc]="tutorialVideo$ | async"
    ></xui-getting-started-tile>
  </div>
</div>
