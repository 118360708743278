import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  AppSettings,
  SettingsAccessor,
  SettingsService,
  SystemOfUnits,
} from '@trumpf-xguide/xguide';
import { map } from 'rxjs/operators';
import { DEFAULT_APP_SETTINGS, SHARED_SETTINGS_NAMESPACE } from '../../../shared/constants';
import { TranslationHelper } from '../../../shared/helpers';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import { materialToTranslation } from '../../helpers/enum-to-translation';
import { DropdownItemValue } from '../../model/dropdown-item-value';
import { Material } from '../../model/material';
import { SheetWeightCalculationService } from '../../services/sheet-weight-calculation.service';

@Component({
  selector: 'lsb-sheet-weight',
  templateUrl: './sheet-weight.component.html',
  styleUrls: ['../../styles/calculations-base-style.scss', './sheet-weight.component.scss'],
  providers: [SheetWeightCalculationService],
})
export class SheetWeightComponent {
  public readonly uiElementIds = UiElementIds;
  public materials: DropdownItemValue<Material>[] = [];
  public selectedMaterial: Material = Material.STEEL;
  public length: number = 1000;
  public width: number = 200;
  public sheetThickness: number = 10;
  public calculationError$ = this.calculationService.calculationResult$.pipe(map((r) => r?.error));
  private readonly availableMaterials: Material[] = [
    Material.STEEL,
    Material.STAINLESS_STEEL,
    Material.ALUMINUM,
  ];
  private settings: SettingsAccessor<AppSettings>;

  constructor(
    public translations: TranslationHelper,
    public calculationService: SheetWeightCalculationService,
    private translateService: TranslateService,
    private settingsService: SettingsService,
  ) {
    for (const material of this.availableMaterials) {
      this.materials.push({
        id: material,
        name: materialToTranslation(material, translations, translateService),
      });
    }

    this.settings = settingsService.access<AppSettings>(
      SHARED_SETTINGS_NAMESPACE,
      DEFAULT_APP_SETTINGS,
    );
  }

  private get systemOfUnits(): SystemOfUnits {
    return this.settings.get('unitSystem');
  }

  public calculate(): void {
    this.calculationService.calculate(
      this.selectedMaterial,
      this.length,
      this.width,
      this.sheetThickness,
    );
  }

  public selectedMaterialName(): string {
    return this.materials.find((m) => m.id === this.selectedMaterial)?.name ?? 'unknown';
  }

  public clearResult(): void {
    this.calculationService.clearResult();
  }
}
