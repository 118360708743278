import { Material } from './material';

/**
 * A tensile strength for each material in N/mm^2
 */
export const TensileStrengths: Map<Material, number> = new Map<Material, number>([
  [Material.STAINLESS_STEEL, 700],
  [Material.X5CrNi18_10, 700],
  [Material.ALUMINUM, 300],
  [Material.ALMG3, 270],
  [Material.STEEL, 450],
  [Material.DC_01, 410],
  [Material.DC_03, 370],
  [Material.DC_04, 350],
  [Material.S_235_JR, 510],
  [Material.S_355_JR, 680],
  [Material.S_355_NC, 610],
  [Material.S_355_MC, 550],
  [Material.S_420_NC, 670],
  [Material.S_420_MC, 620],
  [Material.S_460_MC, 670],
  [Material.S_500_MC, 700],
  [Material.S_550_MC, 760],
  [Material.S_600_MC, 820],
  [Material.S_650_MC, 880],
  [Material.S_700_MC, 950],
  [Material.WELDOX_500, 620],
  [Material.WELDOX_700, 860],
  [Material.WELDOX_900, 1030],
  [Material.WELDOX_1100, 1350],
  [Material.HARDOX_400, 1250],
  [Material.HARDOX_450, 1400],
  [Material.HARDOX_500, 1550],
]);
