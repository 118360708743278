<div class="input-container">
  <!-- Calculate dropdown -->
  <div class="dropdown">
    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.CALCULATE | translate }}
    </span>
    <xui-dropdown
      #calculateDropDown
      [label]="selectedCalculationTypeName()"
      data-ngtx="mount-height:calculation-type"
    >
      <xui-dropdown-item
        (click)="calculationTypeChanged(calculationType.id); calculateDropDown.close()"
        *ngFor="let calculationType of calculationTypes"
      >
        {{ calculationType?.name }}
      </xui-dropdown-item>
    </xui-dropdown>
  </div>

  <!-- Machine -->
  <ng-container *ngIf="selectedCalculationType !== mountHeightCalculationType.MACHINE_SELECTION">
    <div class="dropdown">
      <span class="dropdown-label">
        {{ translations.CALCULATIONS.INPUT_LABELS.MACHINE | translate }}
      </span>
      <xui-dropdown #machineDropDown [label]="selectedMachine" data-ngtx="mount-height:machine">
        <xui-dropdown-item
          (click)="
            selectedMachine = userDefinedText;
            isMachineUserDefined = true;
            machineDropDown.close();
            clearResult()
          "
        >
          {{ translations.CALCULATIONS.INPUT_LABELS.USER_DEFINED | translate }}
        </xui-dropdown-item>
        <xui-dropdown-item
          (click)="onSelectMachine(machine); machineDropDown.close()"
          *ngFor="let machine of machines"
        >
          {{ machine }}
        </xui-dropdown-item>
      </xui-dropdown>
    </div>
    <xui-input-field
      (focusout)="clearResult()"
      *ngIf="isMachineUserDefined"
      [(text)]="machineHeightValue.value"
      data-ngtx="mount-height:machine-height"
      label="{{ translations.CALCULATIONS.INPUT_LABELS.MACHINE_HEIGHT | translate }}"
    >
      <xui-value
        #machineHeightValue
        [(binding)]="machineHeight"
        [fallbackValue]="machineHeight"
        lsbValidateRequired
      >
        <xui-length-converter
          imperialTargetUnit="inch"
          metricTargetUnit="mm"
        ></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="0"
          [unit]="machineHeightValue.unit"
        ></xui-number-formatter>
      </xui-value>
    </xui-input-field>
  </ng-container>

  <!-- Adapter -->
  <ng-container *ngIf="selectedCalculationType !== mountHeightCalculationType.UPPER_TOOL_HEIGHT">
    <div class="dropdown">
      <span class="dropdown-label">
        {{ translations.CALCULATIONS.INPUT_LABELS.ADAPTER | translate }}
      </span>
      <xui-dropdown #adapterDropDown [label]="selectedAdapter" data-ngtx="mount-height:adapter">
        <xui-dropdown-item
          (click)="
            selectedAdapter = userDefinedText;
            isAdapterUserDefined = true;
            adapterDropDown.close();
            clearResult()
          "
        >
          {{ translations.CALCULATIONS.INPUT_LABELS.USER_DEFINED | translate }}
        </xui-dropdown-item>
        <xui-dropdown-item
          (click)="onSelectAdapter(adapter); adapterDropDown.close()"
          *ngFor="let adapter of adapters"
        >
          {{ adapter }}
        </xui-dropdown-item>
      </xui-dropdown>
    </div>
    <xui-input-field
      (focusout)="clearResult()"
      *ngIf="isAdapterUserDefined"
      [(text)]="adapterHeightValue.value"
      data-ngtx="mount-height:adapter-height"
      label="{{ translations.CALCULATIONS.INPUT_LABELS.ADAPTER_HEIGHT | translate }}"
    >
      <xui-value
        #adapterHeightValue
        [(binding)]="adapterHeight"
        [fallbackValue]="adapterHeight"
        lsbValidateRequired
      >
        <xui-length-converter
          imperialTargetUnit="inch"
          metricTargetUnit="mm"
        ></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="0"
          [unit]="adapterHeightValue.unit"
        ></xui-number-formatter>
      </xui-value>
    </xui-input-field>
  </ng-container>

  <!-- Upper tool -->
  <ng-container *ngIf="selectedCalculationType !== mountHeightCalculationType.UPPER_TOOL_HEIGHT">
    <div class="dropdown">
      <span class="dropdown-label">
        {{ translations.CALCULATIONS.INPUT_LABELS.UPPER_TOOL | translate }}
      </span>
      <xui-dropdown
        #upperToolDropDown
        [label]="selectedUpperTool"
        data-ngtx="mount-height:upper-tool"
      >
        <xui-dropdown-item
          (click)="
            selectedUpperTool = userDefinedText;
            isUpperToolUserDefined = true;
            upperToolDropDown.close();
            clearResult()
          "
        >
          {{ translations.CALCULATIONS.INPUT_LABELS.USER_DEFINED | translate }}
        </xui-dropdown-item>
        <xui-dropdown-item
          (click)="onSelectUpperTool(upperTool); upperToolDropDown.close()"
          *ngFor="let upperTool of upperTools"
        >
          {{ upperTool | translate }}
        </xui-dropdown-item>
      </xui-dropdown>
    </div>
    <xui-input-field
      (focusout)="clearResult()"
      *ngIf="isUpperToolUserDefined"
      [(text)]="upperToolHeightValue.value"
      data-ngtx="mount-height:upper-tool-height"
      label="{{ translations.CALCULATIONS.INPUT_LABELS.UPPER_TOOL_HEIGHT | translate }}"
    >
      <xui-value
        #upperToolHeightValue
        [(binding)]="upperToolHeight"
        [fallbackValue]="upperToolHeight"
        lsbValidateRequired
      >
        <xui-length-converter
          imperialTargetUnit="inch"
          metricTargetUnit="mm"
        ></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="0"
          [unit]="upperToolHeightValue.unit"
        ></xui-number-formatter>
      </xui-value>
    </xui-input-field>
  </ng-container>

  <!-- Bottom tool -->
  <ng-container>
    <div class="dropdown">
      <span class="dropdown-label">
        {{ translations.CALCULATIONS.INPUT_LABELS.BOTTOM_TOOL | translate }}
      </span>
      <xui-dropdown
        #bottomToolDropDown
        [label]="selectedBottomTool"
        data-ngtx="mount-height:bottom-tool"
      >
        <xui-dropdown-item
          (click)="
            selectedBottomTool = userDefinedText;
            isBottomToolUserDefined = true;
            bottomToolDropDown.close();
            clearResult()
          "
        >
          {{ translations.CALCULATIONS.INPUT_LABELS.USER_DEFINED | translate }}
        </xui-dropdown-item>
        <xui-dropdown-item
          (click)="onSelectBottomTool(bottomTool); bottomToolDropDown.close()"
          *ngFor="let bottomTool of bottomTools"
        >
          {{ bottomTool }}
        </xui-dropdown-item>
      </xui-dropdown>
    </div>
    <xui-input-field
      (focusout)="clearResult()"
      *ngIf="isBottomToolUserDefined"
      [(text)]="bottomToolHeightValue.value"
      data-ngtx="mount-height:upper-tool-height"
      label="{{ translations.CALCULATIONS.INPUT_LABELS.BOTTOM_TOOL_HEIGHT | translate }}"
    >
      <xui-value
        #bottomToolHeightValue
        [(binding)]="bottomToolHeight"
        [fallbackValue]="bottomToolHeight"
        lsbValidateRequired
      >
        <xui-length-converter
          imperialTargetUnit="inch"
          metricTargetUnit="mm"
        ></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="0"
          [unit]="bottomToolHeightValue.unit"
        ></xui-number-formatter>
      </xui-value>
    </xui-input-field>
  </ng-container>

  <!-- Desired remaining opening -->
  <xui-input-field
    (focusout)="clearResult()"
    *ngIf="selectedCalculationType !== mountHeightCalculationType.REMAINING_OPENING"
    [(text)]="desiredRemainigOpeningValue.value"
    data-ngtx="mount-height:desired-remaining-opening"
    label="{{ translations.CALCULATIONS.INPUT_LABELS.DESIRED_REMAINING_OPENING | translate }}"
  >
    <xui-value
      #desiredRemainigOpeningValue
      [(binding)]="desiredRemainingOpening"
      [fallbackValue]="desiredRemainingOpening"
      lsbValidateRequired
    >
      <xui-length-converter imperialTargetUnit="inch" metricTargetUnit="mm"></xui-length-converter>
      <xui-number-formatter
        [maxDecimals]="0"
        [unit]="desiredRemainigOpeningValue.unit"
      ></xui-number-formatter>
    </xui-value>
  </xui-input-field>

  <!-- Calculate -->
  <div class="calculate-button button-info-box">
    <xui-button
      (click)="calculate()"
      xuiClickUsageTracking="{{ uiElementIds.CALCULATE_MOUNT_HEIGHT }}"
    >
      {{ translations.CALCULATIONS.INPUT_LABELS.CALCULATE | translate }}
    </xui-button>
    <xui-icon
      [xuiTooltip]="calculateTooltip"
      class="info-flag"
      iconKey="Tooltip.Information"
    ></xui-icon>

    <xui-tooltip #calculateTooltip>
      <p class="simple-text-tooltip">
        {{ translations.CALCULATIONS.TOOLTIPS.MOUNT_HEIGHT_TOOLTIP | translate }}
      </p>
    </xui-tooltip>
  </div>

  <!-- Result -->
  <div *ngIf="calculationResult$ | async as calculationResult" class="calculation-result">
    <ng-container [ngSwitch]="selectedCalculationType">
      <ng-container *ngSwitchCase="mountHeightCalculationType.REMAINING_OPENING">
        <xui-caption-label
          [caption]="
            translations.CALCULATIONS.MOUNT_HEIGHT_CALCULATION_TYPE.REMAINING_OPENING | translate
          "
        >
          <xui-value
            #remainOpeningResultValue
            [(binding)]="calculationResult.remainOpening"
            [fallbackValue]="0"
          >
            <xui-length-converter
              imperialTargetUnit="inch"
              metricTargetUnit="mm"
            ></xui-length-converter>
            <xui-number-formatter
              [maxDecimals]="2"
              [unit]="remainOpeningResultValue.unit"
            ></xui-number-formatter>
          </xui-value>
          {{ remainOpeningResultValue.value }}
          <lsb-mount-height-possibility-icon
            [possibility]="calculationResult.possibility"
          ></lsb-mount-height-possibility-icon>
        </xui-caption-label>
      </ng-container>
      <ng-container *ngSwitchCase="mountHeightCalculationType.UPPER_TOOL_HEIGHT">
        <xui-caption-label
          [caption]="
            translations.CALCULATIONS.MOUNT_HEIGHT_CALCULATION_TYPE.UPPER_TOOL_HEIGHT | translate
          "
        >
          <xui-value
            #upperToolHeightResultValue
            [(binding)]="calculationResult.upperToolHeight"
            [fallbackValue]="0"
          >
            <xui-length-converter
              imperialTargetUnit="inch"
              metricTargetUnit="mm"
            ></xui-length-converter>
            <xui-number-formatter
              [maxDecimals]="2"
              [unit]="upperToolHeightResultValue.unit"
            ></xui-number-formatter>
          </xui-value>
          {{ upperToolHeightResultValue.value }}
          <lsb-mount-height-possibility-icon
            [possibility]="calculationResult.possibility"
          ></lsb-mount-height-possibility-icon>
        </xui-caption-label>
      </ng-container>
      <ng-container *ngSwitchCase="mountHeightCalculationType.MACHINE_SELECTION">
        <xui-caption-label
          [caption]="
            translations.CALCULATIONS.MOUNT_HEIGHT_CALCULATION_TYPE.MACHINE_SELECTION | translate
          "
        >
          <xui-value
            #machineMinMountHeightResultValue
            [(binding)]="calculationResult.machineMinimumMountHeight"
            [fallbackValue]="0"
          >
            <xui-length-converter
              imperialTargetUnit="inch"
              metricTargetUnit="mm"
            ></xui-length-converter>
            <xui-number-formatter
              [maxDecimals]="2"
              [unit]="machineMinMountHeightResultValue.unit"
            ></xui-number-formatter>
          </xui-value>
          {{ machineMinMountHeightResultValue.value }}
          <lsb-mount-height-possibility-icon
            [possibility]="calculationResult.possibility"
          ></lsb-mount-height-possibility-icon>
        </xui-caption-label>
      </ng-container>
    </ng-container>
  </div>

  <div class="result-image-block">
    <lsb-mount-height-picture
      [bottomToolHeight]="bottomToolPictureResultValue.value"
      [desiredRemainingOpening]="remainingOpeningPictureResultValue.value ?? '?'"
      [minimalMountHeight]="machineHeightPictureResultValue.value ?? '?'"
      [upperToolHeight]="upperToolHeightPictureResultValue.value ?? '?'"
    >
      <xui-value
        #upperToolHeightPictureResultValue
        [(binding)]="upperToolHeight"
        [fallbackValue]="0"
      >
        <xui-length-converter
          imperialTargetUnit="inch"
          metricTargetUnit="mm"
        ></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="0"
          [unit]="upperToolHeightPictureResultValue.unit"
        ></xui-number-formatter>
      </xui-value>
      <xui-value
        #remainingOpeningPictureResultValue
        [(binding)]="desiredRemainingOpening"
        [fallbackValue]="0"
      >
        <xui-length-converter
          imperialTargetUnit="inch"
          metricTargetUnit="mm"
        ></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="0"
          [unit]="remainingOpeningPictureResultValue.unit"
        ></xui-number-formatter>
      </xui-value>
      <xui-value #machineHeightPictureResultValue [(binding)]="machineHeight" [fallbackValue]="0">
        <xui-length-converter
          imperialTargetUnit="inch"
          metricTargetUnit="mm"
        ></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="0"
          [unit]="machineHeightPictureResultValue.unit"
        ></xui-number-formatter>
      </xui-value>
      <xui-value #bottomToolPictureResultValue [(binding)]="bottomToolHeight" [fallbackValue]="0">
        <xui-length-converter
          imperialTargetUnit="inch"
          metricTargetUnit="mm"
        ></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="0"
          [unit]="bottomToolPictureResultValue.unit"
        ></xui-number-formatter>
      </xui-value>
    </lsb-mount-height-picture>
  </div>
</div>
