<xui-icon
  *ngIf="possibility === mountHeightPossibilityMark.POSSIBLE"
  iconKey="Check"
  style="--icon-stroke: green; --icon-fill: green"
></xui-icon>
<xui-icon
  *ngIf="possibility === mountHeightPossibilityMark.NOT_IDEAL"
  iconKey="Triangle.Exclamation"
  style="--icon-stroke: darkorange; --icon-fill: darkorange"
></xui-icon>
<xui-icon
  *ngIf="possibility === mountHeightPossibilityMark.NOT_POSSIBLE"
  iconKey="Cross"
  style="--icon-stroke: red; --icon-fill: red"
></xui-icon>
