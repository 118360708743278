import { ToolCategory } from '../model/tool-category';
import { ToolType } from '../model/tool-type';

export const TOOL_LENGTHS = [
  10, 15, 20, 25, 30, 35, 40, 45, 50, 100, 200, 250, 300, 500, 1250, 2050, 2550, 3050, 4050,
];

export const TOOL_SPECIFIC_WEIGHTS: ToolType[] = [
  { name: 'Adapter Modufix H=60', specificWeight: 26.28, category: ToolCategory.ADAPTER },
  { name: 'Adapter Modufix H=80', specificWeight: 35.7, category: ToolCategory.ADAPTER },
  { name: 'Adapter Modufix H=100', specificWeight: 45.3, category: ToolCategory.ADAPTER },
  { name: 'Adapter Modufix H=150', specificWeight: 68.64, category: ToolCategory.ADAPTER },
  { name: 'Adapter Modufix H=200', specificWeight: 61.9, category: ToolCategory.ADAPTER },
  { name: 'Einsatz flach 180°', specificWeight: 6.8, category: ToolCategory.INSERT },
  { name: 'EV 70', specificWeight: 18, category: ToolCategory.DIE },
  { name: 'EV W10/84°', specificWeight: 17.48, category: ToolCategory.DIE },
  { name: 'EV W10/84° H=150', specificWeight: 26.1, category: ToolCategory.DIE },
  { name: 'EV W100/80°', specificWeight: 89.4, category: ToolCategory.DIE },
  { name: 'EV W12/84°', specificWeight: 21.31, category: ToolCategory.DIE },
  { name: 'EV W12/84° H=150', specificWeight: 31.1, category: ToolCategory.DIE },
  { name: 'EV W120/60°', specificWeight: 92.4, category: ToolCategory.DIE },
  { name: 'EV W16/84°', specificWeight: 24.95, category: ToolCategory.DIE },
  { name: 'EV W16/84° H=150', specificWeight: 36.7, category: ToolCategory.DIE },
  { name: 'EV W20/84°', specificWeight: 24.6, category: ToolCategory.DIE },
  { name: 'EV W20/84° H=150', specificWeight: 36.4, category: ToolCategory.DIE },
  { name: 'EV W24/80°', specificWeight: 27.98, category: ToolCategory.DIE },
  { name: 'EV W24/80° H=150', specificWeight: 41.7, category: ToolCategory.DIE },
  { name: 'EV W30/30°', specificWeight: 38.2, category: ToolCategory.DIE },
  { name: 'EV W30/80°', specificWeight: 34.82, category: ToolCategory.DIE },
  { name: 'EV W30/80° H=150', specificWeight: 52.5, category: ToolCategory.DIE },
  { name: 'EV W40/30°', specificWeight: 36.7, category: ToolCategory.DIE },
  { name: 'EV W40/80°', specificWeight: 40.89, category: ToolCategory.DIE },
  { name: 'EV W40/80° H=150', specificWeight: 62.5, category: ToolCategory.DIE },
  { name: 'EV W50/80°', specificWeight: 46.53, category: ToolCategory.DIE },
  { name: 'EV W50/80° H=150', specificWeight: 72, category: ToolCategory.DIE },
  { name: 'EV W6/84°', specificWeight: 17.64, category: ToolCategory.DIE },
  { name: 'EV W6/84° H=150', specificWeight: 26.3, category: ToolCategory.DIE },
  { name: 'EV W60/80°', specificWeight: 51.66, category: ToolCategory.DIE },
  { name: 'EV W70/80°', specificWeight: 56.33, category: ToolCategory.DIE },
  { name: 'EV W8/84°', specificWeight: 17.57, category: ToolCategory.DIE },
  { name: 'EV W8/84° H=150', specificWeight: 26.2, category: ToolCategory.DIE },
  { name: 'EV W80/80°', specificWeight: 64.46, category: ToolCategory.DIE },
  { name: 'EV W90/80°', specificWeight: 84.65, category: ToolCategory.DIE },
  { name: 'EV/S W10/84°', specificWeight: 13.3, category: ToolCategory.DIE },
  { name: 'EV/S W12/84°', specificWeight: 14.56, category: ToolCategory.DIE },
  { name: 'EV/S W6/84°', specificWeight: 10.58, category: ToolCategory.DIE },
  { name: 'EV/S W8/84°', specificWeight: 10.92, category: ToolCategory.DIE },
  { name: 'EV/S-W4/30°', specificWeight: 14.07, category: ToolCategory.DIE },
  { name: 'EV/S-W4/84°', specificWeight: 10.16, category: ToolCategory.DIE },
  { name: 'EV/S-W4/90°', specificWeight: 11.11, category: ToolCategory.DIE },
  { name: 'EV/S-W5/30°', specificWeight: 14, category: ToolCategory.DIE },
  { name: 'EV/S-W5/84°', specificWeight: 10.13, category: ToolCategory.DIE },
  { name: 'EV/S-W5/90°', specificWeight: 11.91, category: ToolCategory.DIE },
  { name: 'EV001 30°', specificWeight: 17.5, category: ToolCategory.DIE },
  { name: 'EV001 30° H=150', specificWeight: 25.6, category: ToolCategory.DIE },
  { name: 'EV001 R3 30°', specificWeight: 17.33, category: ToolCategory.DIE },
  { name: 'EV001/S 30°', specificWeight: 11.8, category: ToolCategory.DIE },
  { name: 'EV002 30°', specificWeight: 17.2, category: ToolCategory.DIE },
  { name: 'EV002 30° H=150', specificWeight: 25.4, category: ToolCategory.DIE },
  { name: 'EV002 R3 30°', specificWeight: 17.11, category: ToolCategory.DIE },
  { name: 'EV002/S 30°', specificWeight: 13, category: ToolCategory.DIE },
  { name: 'EV003 30°', specificWeight: 17, category: ToolCategory.DIE },
  { name: 'EV003 30° H=150', specificWeight: 25.2, category: ToolCategory.DIE },
  { name: 'EV003 R3 30°', specificWeight: 16.81, category: ToolCategory.DIE },
  { name: 'EV003/S 30°', specificWeight: 14.12, category: ToolCategory.DIE },
  { name: 'EV004 30°', specificWeight: 20.5, category: ToolCategory.DIE },
  { name: 'EV004 30° H=150', specificWeight: 30.3, category: ToolCategory.DIE },
  { name: 'EV004 R3 30°', specificWeight: 20.53, category: ToolCategory.DIE },
  { name: 'EV004/S 30°', specificWeight: 15.59, category: ToolCategory.DIE },
  { name: 'EV005 30°', specificWeight: 23.6, category: ToolCategory.DIE },
  { name: 'EV005 30° H=150', specificWeight: 35.4, category: ToolCategory.DIE },
  { name: 'EV005 R3 30°', specificWeight: 23.43, category: ToolCategory.DIE },
  { name: 'EV006 30°', specificWeight: 26.4, category: ToolCategory.DIE },
  { name: 'EV006 30° H=150', specificWeight: 40.13, category: ToolCategory.DIE },
  { name: 'EV007 30°', specificWeight: 29.5, category: ToolCategory.DIE },
  { name: 'EV007 30° H=150', specificWeight: 49.2, category: ToolCategory.DIE },
  { name: 'EV020 86°', specificWeight: 17.6, category: ToolCategory.DIE },
  { name: 'EV020 86° H=150', specificWeight: 26.3, category: ToolCategory.DIE },
  { name: 'EV020/S 86°', specificWeight: 10.5, category: ToolCategory.DIE },
  { name: 'EV021 86°', specificWeight: 17.6, category: ToolCategory.DIE },
  { name: 'EV021 86° H=150', specificWeight: 26.2, category: ToolCategory.DIE },
  { name: 'EV021/S 86°', specificWeight: 11.91, category: ToolCategory.DIE },
  { name: 'EV022 86°', specificWeight: 17.5, category: ToolCategory.DIE },
  { name: 'EV022 86° H=150', specificWeight: 26.1, category: ToolCategory.DIE },
  { name: 'EV022/S 86°', specificWeight: 13.26, category: ToolCategory.DIE },
  { name: 'EV023 86°', specificWeight: 21.3, category: ToolCategory.DIE },
  { name: 'EV023 86° H=150', specificWeight: 31.1, category: ToolCategory.DIE },
  { name: 'EV023/S 86°', specificWeight: 21.14, category: ToolCategory.DIE },
  { name: 'EV024 86°', specificWeight: 25, category: ToolCategory.DIE },
  { name: 'EV024 86° H=150', specificWeight: 36.7, category: ToolCategory.DIE },
  { name: 'EV025 86°', specificWeight: 24.4, category: ToolCategory.DIE },
  { name: 'EV025 86° H=150', specificWeight: 36.4, category: ToolCategory.DIE },
  { name: 'EV026 86°', specificWeight: 28, category: ToolCategory.DIE },
  { name: 'EV026 86° H=150', specificWeight: 41.8, category: ToolCategory.DIE },
  { name: 'EV027 86°', specificWeight: 35.2, category: ToolCategory.DIE },
  { name: 'EV027 86° H=150', specificWeight: 52.9, category: ToolCategory.DIE },
  { name: 'EV028 86°', specificWeight: 41.5, category: ToolCategory.DIE },
  { name: 'EV028 86° H=150', specificWeight: 63.1, category: ToolCategory.DIE },
  { name: 'EV029 86°', specificWeight: 55.2, category: ToolCategory.DIE },
  { name: 'EV029 86° H=150', specificWeight: 84.7, category: ToolCategory.DIE },
  { name: 'EV040 90°', specificWeight: 18.4, category: ToolCategory.DIE },
  { name: 'EV040 90° H=150', specificWeight: 26.3, category: ToolCategory.DIE },
  { name: 'EV040/S 90°', specificWeight: 10.97, category: ToolCategory.DIE },
  { name: 'EV041 90°', specificWeight: 18.4, category: ToolCategory.DIE },
  { name: 'EV041 90° H=150', specificWeight: 26.2, category: ToolCategory.DIE },
  { name: 'EV041/S 90°', specificWeight: 12.32, category: ToolCategory.DIE },
  { name: 'EV042 90°', specificWeight: 18.3, category: ToolCategory.DIE },
  { name: 'EV042 90° H=150', specificWeight: 26.2, category: ToolCategory.DIE },
  { name: 'EV042/S 90°', specificWeight: 13.66, category: ToolCategory.DIE },
  { name: 'EV043 90°', specificWeight: 18.2, category: ToolCategory.DIE },
  { name: 'EV043 90° H=150', specificWeight: 26.1, category: ToolCategory.DIE },
  { name: 'EV043/S 90°', specificWeight: 14.98, category: ToolCategory.DIE },
  { name: 'EV044 90°', specificWeight: 25, category: ToolCategory.DIE },
  { name: 'EV044 90° H=150', specificWeight: 36.8, category: ToolCategory.DIE },
  { name: 'Einsatz flach 180°', specificWeight: 6.8, category: ToolCategory.INSERT },
  { name: 'EV 70', specificWeight: 18, category: ToolCategory.DIE },
  { name: 'EV70-M', specificWeight: 12.65, category: ToolCategory.DIE },
  { name: 'EVZ 90°', specificWeight: 18.19, category: ToolCategory.DIE },
  { name: 'EVZ 90° H=100', specificWeight: 18.16, category: ToolCategory.DIE },
  { name: 'EVZ 90° H=100', specificWeight: 24.75, category: ToolCategory.DIE },
  { name: 'EVZ 90° H=100', specificWeight: 24.82, category: ToolCategory.DIE },
  { name: 'FEV W10/30°', specificWeight: 20.7, category: ToolCategory.DIE },
  { name: 'FEV W12/30°', specificWeight: 21.5, category: ToolCategory.DIE },
  { name: 'FEV W6/30°', specificWeight: 20.9, category: ToolCategory.DIE },
  { name: 'FEV W8/30°', specificWeight: 21, category: ToolCategory.DIE },
  { name: 'FWZ', specificWeight: 14.8, category: ToolCategory.DIE },
  { name: 'FWZ H=150', specificWeight: 25.33, category: ToolCategory.DIE },
  { name: 'KEV W10/30°', specificWeight: 22.51, category: ToolCategory.DIE },
  { name: 'KEV W12/30°', specificWeight: 24.58, category: ToolCategory.DIE },
  { name: 'KEV W16/30°', specificWeight: 27.8, category: ToolCategory.DIE },
  { name: 'KEV W20/30°', specificWeight: 29.1, category: ToolCategory.DIE },
  { name: 'KEV W24/30°', specificWeight: 33.4, category: ToolCategory.DIE },
  { name: 'KEV W8/30°', specificWeight: 21.2, category: ToolCategory.DIE },
  {
    name: '>L< OW200/S R1/86° H=220',
    specificWeight: 43.93,
    category: ToolCategory.LIGHTWEIGHT_TOOLING,
  },
  {
    name: '>L< OW201/S R1/86° H=220',
    specificWeight: 37.58,
    category: ToolCategory.LIGHTWEIGHT_TOOLING,
  },
  {
    name: '>L< OW202/S R1/28° H=220',
    specificWeight: 34.96,
    category: ToolCategory.LIGHTWEIGHT_TOOLING,
  },
  {
    name: '>L< OW203/S R4/60° H=220',
    specificWeight: 35.1,
    category: ToolCategory.LIGHTWEIGHT_TOOLING,
  },
  {
    name: '>L< OW210/S R1/28° H=240',
    specificWeight: 32.9,
    category: ToolCategory.LIGHTWEIGHT_TOOLING,
  },
  {
    name: '>L< OW300/S R1/86° H=300',
    specificWeight: 64.96,
    category: ToolCategory.LIGHTWEIGHT_TOOLING,
  },
  { name: 'MST', specificWeight: 18.7, category: ToolCategory.DIE },
  { name: 'MST H=150', specificWeight: 30.6, category: ToolCategory.DIE },
  { name: 'OW/K 130', specificWeight: 24.72, category: ToolCategory.PUNCH },
  { name: 'OW/K 80', specificWeight: 18.83, category: ToolCategory.PUNCH },
  { name: 'OW/K 90', specificWeight: 19.3, category: ToolCategory.PUNCH },
  { name: 'OW/K-SBW H120 R=1', specificWeight: 35, category: ToolCategory.PUNCH },
  { name: 'OW/S 190', specificWeight: 44.5, category: ToolCategory.PUNCH },
  { name: 'OW200/K R=0,5', specificWeight: 29.35, category: ToolCategory.PUNCH },
  { name: 'OW200/K R=1', specificWeight: 29.3, category: ToolCategory.PUNCH },
  { name: 'OW200/K R=1,6', specificWeight: 29.33, category: ToolCategory.PUNCH },
  { name: 'OW200/K R=2', specificWeight: 29.3, category: ToolCategory.PUNCH },
  { name: 'OW200/K R=2,3', specificWeight: 29.34, category: ToolCategory.PUNCH },
  { name: 'OW200/K R=2,5', specificWeight: 29.35, category: ToolCategory.PUNCH },
  { name: 'OW200/K R=3', specificWeight: 29.33, category: ToolCategory.PUNCH },
  { name: 'OW200/K R=3,2', specificWeight: 29.33, category: ToolCategory.PUNCH },
  { name: 'OW200/K R=4', specificWeight: 29.33, category: ToolCategory.PUNCH },
  { name: 'OW200/S R=0,5', specificWeight: 66.56, category: ToolCategory.PUNCH },
  { name: 'OW200/S R=1', specificWeight: 66.6, category: ToolCategory.PUNCH },
  { name: 'OW200/S R=1,6', specificWeight: 66.5, category: ToolCategory.PUNCH },
  { name: 'OW200/S R=2', specificWeight: 66.56, category: ToolCategory.PUNCH },
  { name: 'OW200/S R=2,3', specificWeight: 66.55, category: ToolCategory.PUNCH },
  { name: 'OW200/S R=2,5', specificWeight: 66.5, category: ToolCategory.PUNCH },
  { name: 'OW200/S R=3', specificWeight: 66.5, category: ToolCategory.PUNCH },
  { name: 'OW200/S R=3,2', specificWeight: 66.53, category: ToolCategory.PUNCH },
  { name: 'OW200/S R=4', specificWeight: 66.57, category: ToolCategory.PUNCH },
  { name: 'OW201/K R=0,5', specificWeight: 24.13, category: ToolCategory.PUNCH },
  { name: 'OW201/K R=1', specificWeight: 24.3, category: ToolCategory.PUNCH },
  { name: 'OW201/K R=1,6', specificWeight: 24.21, category: ToolCategory.PUNCH },
  { name: 'OW201/K R=2', specificWeight: 24.19, category: ToolCategory.PUNCH },
  { name: 'OW201/K R=2,3', specificWeight: 24.19, category: ToolCategory.PUNCH },
  { name: 'OW201/K R=2,5', specificWeight: 24.2, category: ToolCategory.PUNCH },
  { name: 'OW201/K R=3', specificWeight: 24.19, category: ToolCategory.PUNCH },
  { name: 'OW201/K R=3,2', specificWeight: 24.18, category: ToolCategory.PUNCH },
  { name: 'OW201/K R=4', specificWeight: 24.17, category: ToolCategory.PUNCH },
  { name: 'OW201/S R=0,5', specificWeight: 56.97, category: ToolCategory.PUNCH },
  { name: 'OW201/S R=1', specificWeight: 57.1, category: ToolCategory.PUNCH },
  { name: 'OW201/S R=1,6', specificWeight: 56.98, category: ToolCategory.PUNCH },
  { name: 'OW201/S R=2', specificWeight: 56.97, category: ToolCategory.PUNCH },
  { name: 'OW201/S R=2,3', specificWeight: 56.96, category: ToolCategory.PUNCH },
  { name: 'OW201/S R=2,5', specificWeight: 56.94, category: ToolCategory.PUNCH },
  { name: 'OW201/S R=3', specificWeight: 56.97, category: ToolCategory.PUNCH },
  { name: 'OW201/S R=3,2', specificWeight: 56.96, category: ToolCategory.PUNCH },
  { name: 'OW201/S R=4', specificWeight: 56.96, category: ToolCategory.PUNCH },
  { name: 'OW202/K R=0,5', specificWeight: 19.5, category: ToolCategory.PUNCH },
  { name: 'OW202/K R=1', specificWeight: 19.7, category: ToolCategory.PUNCH },
  { name: 'OW202/K R=1,6', specificWeight: 19.54, category: ToolCategory.PUNCH },
  { name: 'OW202/K R=2', specificWeight: 19.51, category: ToolCategory.PUNCH },
  { name: 'OW202/K R=2,3', specificWeight: 19.48, category: ToolCategory.PUNCH },
  { name: 'OW202/K R=2,5', specificWeight: 19.45, category: ToolCategory.PUNCH },
  { name: 'OW202/K R=3', specificWeight: 19.4, category: ToolCategory.PUNCH },
  { name: 'OW202/K R=3,2', specificWeight: 19.41, category: ToolCategory.PUNCH },
  { name: 'OW202/K R=4', specificWeight: 19.26, category: ToolCategory.PUNCH },
  { name: 'OW202/S R=0,5', specificWeight: 49.92, category: ToolCategory.PUNCH },
  { name: 'OW202/S R=1', specificWeight: 49.9, category: ToolCategory.PUNCH },
  { name: 'OW202/S R=1,6', specificWeight: 50, category: ToolCategory.PUNCH },
  { name: 'OW202/S R=2', specificWeight: 49.9, category: ToolCategory.PUNCH },
  { name: 'OW202/S R=2,3', specificWeight: 49.92, category: ToolCategory.PUNCH },
  { name: 'OW202/S R=2,5', specificWeight: 49.65, category: ToolCategory.PUNCH },
  { name: 'OW202/S R=3', specificWeight: 49.63, category: ToolCategory.PUNCH },
  { name: 'OW202/S R=3,2', specificWeight: 49.81, category: ToolCategory.PUNCH },
  { name: 'OW202/S R=4', specificWeight: 49.65, category: ToolCategory.PUNCH },
  { name: 'OW203/K R=2', specificWeight: 23.27, category: ToolCategory.PUNCH },
  { name: 'OW203/K R=2,5', specificWeight: 23.33, category: ToolCategory.PUNCH },
  { name: 'OW203/K R=3', specificWeight: 23.42, category: ToolCategory.PUNCH },
  { name: 'OW203/K R=4', specificWeight: 23.5, category: ToolCategory.PUNCH },
  { name: 'OW203/K R=4,7', specificWeight: 23.48, category: ToolCategory.PUNCH },
  { name: 'OW203/K R=5', specificWeight: 23.47, category: ToolCategory.PUNCH },
  { name: 'OW203/K R=6', specificWeight: 23.37, category: ToolCategory.PUNCH },
  { name: 'OW203/K R=6,35', specificWeight: 23.35, category: ToolCategory.PUNCH },
  { name: 'OW203/S R=2', specificWeight: 51.87, category: ToolCategory.PUNCH },
  { name: 'OW203/S R=2,5', specificWeight: 51.96, category: ToolCategory.PUNCH },
  { name: 'OW203/S R=3', specificWeight: 52.04, category: ToolCategory.PUNCH },
  { name: 'OW203/S R=4', specificWeight: 52.3, category: ToolCategory.PUNCH },
  { name: 'OW203/S R=4,7', specificWeight: 52.12, category: ToolCategory.PUNCH },
  { name: 'OW203/S R=5', specificWeight: 52.11, category: ToolCategory.PUNCH },
  { name: 'OW203/S R=6', specificWeight: 52.05, category: ToolCategory.PUNCH },
  { name: 'OW203/S R=6,35', specificWeight: 52.02, category: ToolCategory.PUNCH },
  { name: 'OW203/S R=8', specificWeight: 52.7, category: ToolCategory.PUNCH },
  { name: 'OW203/S DB R=4', specificWeight: 52.3, category: ToolCategory.PUNCH },
  { name: 'OW209/S', specificWeight: 38.7, category: ToolCategory.PUNCH },
  { name: 'OW210 R=0,5', specificWeight: 28.97, category: ToolCategory.PUNCH },
  { name: 'OW210 R=1', specificWeight: 29, category: ToolCategory.PUNCH },
  { name: 'OW210/S R=0,5', specificWeight: 47.8, category: ToolCategory.PUNCH },
  { name: 'OW210/S R=1', specificWeight: 47.9, category: ToolCategory.PUNCH },
  { name: 'OW211/K R=0,2', specificWeight: 23.6, category: ToolCategory.PUNCH },
  { name: 'OW211/K R=0,5', specificWeight: 23.6, category: ToolCategory.PUNCH },
  { name: 'OW211/K R=1', specificWeight: 23.6, category: ToolCategory.PUNCH },
  { name: 'OW280/K R=0,5', specificWeight: 30.94, category: ToolCategory.PUNCH },
  { name: 'OW300/S R=1', specificWeight: 98.9, category: ToolCategory.PUNCH },
  { name: 'OW308/K', specificWeight: 33.3, category: ToolCategory.PUNCH },
  { name: 'OW308/S', specificWeight: 46.5, category: ToolCategory.PUNCH },
  { name: 'OW320 28° R=0,3', specificWeight: 9.1, category: ToolCategory.PUNCH },
  { name: 'OW320 28° R=1', specificWeight: 8.55, category: ToolCategory.PUNCH },
  { name: 'OW320 28° R=1,6', specificWeight: 8.7, category: ToolCategory.PUNCH },
  { name: 'OW320 28° R=2', specificWeight: 9, category: ToolCategory.PUNCH },
  { name: 'OW320 28° R=3', specificWeight: 9, category: ToolCategory.PUNCH },
  { name: 'OW320 28° R=4', specificWeight: 9.12, category: ToolCategory.PUNCH },
  { name: 'OW320 28° R=5', specificWeight: 9.22, category: ToolCategory.PUNCH },
  { name: 'OW320 28° R=6', specificWeight: 9.29, category: ToolCategory.PUNCH },
  { name: 'OW320 90° R=0,2', specificWeight: 9.14, category: ToolCategory.PUNCH },
  { name: 'OW320 90° R=0,5', specificWeight: 9.18, category: ToolCategory.PUNCH },
  { name: 'OW320 90° R=1', specificWeight: 9.17, category: ToolCategory.PUNCH },
  { name: 'OW320 90° R=1,5', specificWeight: 9.18, category: ToolCategory.PUNCH },
  { name: 'OW320-F R=1', specificWeight: 9.4, category: ToolCategory.PUNCH },
  { name: 'OW390 R=0,5', specificWeight: 4, category: ToolCategory.PUNCH },
  { name: 'OW390 R=0,5', specificWeight: 6.21, category: ToolCategory.PUNCH },
  { name: 'OW391 R=0,5', specificWeight: 8.87, category: ToolCategory.PUNCH },
  { name: 'OW391 R=0,5', specificWeight: 8.95, category: ToolCategory.PUNCH },
  { name: 'OWZ/K 4/90°', specificWeight: 24, category: ToolCategory.PUNCH },
  { name: 'OWZ/K 6/90°', specificWeight: 24.04, category: ToolCategory.PUNCH },
  { name: 'OWZ/K 8/90°', specificWeight: 32.69, category: ToolCategory.PUNCH },
  { name: 'OWZ/K 10/90°', specificWeight: 32.72, category: ToolCategory.PUNCH },
  { name: 'RollBend 200', specificWeight: 20.1, category: ToolCategory.DIE },
  { name: 'RollBend 300', specificWeight: 28.8, category: ToolCategory.DIE },
  { name: 'RollBend 400', specificWeight: 31, category: ToolCategory.DIE },
  { name: 'RollBend 500', specificWeight: 42.4, category: ToolCategory.DIE },
  { name: 'RollBend 600', specificWeight: 59, category: ToolCategory.DIE },
  { name: 'RollBend 1250', specificWeight: 77, category: ToolCategory.DIE },
  { name: 'Rundmaterial R=10', specificWeight: 2.6, category: ToolCategory.INSERT },
  { name: 'Rundmaterial R=15', specificWeight: 5.48, category: ToolCategory.INSERT },
  { name: 'Rundmaterial R=20', specificWeight: 9.8, category: ToolCategory.INSERT },
  { name: 'Rundmaterial R=25', specificWeight: 15.3, category: ToolCategory.INSERT },
  { name: 'Rundmaterial R=30', specificWeight: 22.2, category: ToolCategory.INSERT },
  { name: 'Rundmaterial R=35', specificWeight: 30.21, category: ToolCategory.INSERT },
  { name: 'Rundmaterial R=40', specificWeight: 39.5, category: ToolCategory.INSERT },
  { name: 'Rundmaterial R=45', specificWeight: 49.3, category: ToolCategory.INSERT },
  { name: 'Rundmaterial R=50', specificWeight: 29.9, category: ToolCategory.INSERT },
  { name: 'SBW', specificWeight: 98, category: ToolCategory.DIE },
  { name: 'Trumpf DV', specificWeight: 26, category: ToolCategory.ADAPTER },
  { name: 'Trumpf EV', specificWeight: 11.6, category: ToolCategory.ADAPTER },
  { name: 'TRUMPF-EHT OW', specificWeight: 35.75, category: ToolCategory.ADAPTER },
  { name: 'TRUMPF-EHT EV', specificWeight: 32.9, category: ToolCategory.ADAPTER },
  { name: 'VLMBN', specificWeight: 220, category: ToolCategory.DIE },
  { name: 'VLMDN', specificWeight: 330, category: ToolCategory.DIE },
  { name: 'Z-Einsatz 4/90°', specificWeight: 6.5, category: ToolCategory.INSERT },
  { name: 'Z-Einsatz 6/90°', specificWeight: 6.4, category: ToolCategory.INSERT },
  { name: 'Z-Einsatz 8/90°', specificWeight: 6.4, category: ToolCategory.INSERT },
  { name: 'Z-Einsatz 10/90°', specificWeight: 6.4, category: ToolCategory.INSERT },
  { name: 'ZDL', specificWeight: 11.7, category: ToolCategory.DIE },
  { name: 'ZDL H=150', specificWeight: 16.4, category: ToolCategory.DIE },
];
