export enum Material {
  STAINLESS_STEEL = 'STAINLESS_STEEL',
  X5CrNi18_10 = 'X5CrNi18_10',
  ALUMINUM = 'ALUMINUM',
  ALMG3 = 'ALMG3',
  STEEL = 'STEEL',
  DC_01 = 'DC_01',
  DC_03 = 'DC_03',
  DC_04 = 'DC_04',
  S_235_JR = 'S_235_JR',
  S_355_JR = 'S_355_JR',
  S_355_NC = 'S_355_NC',
  S_355_MC = 'S_355_MC',
  S_420_NC = 'S_420_NC',
  S_420_MC = 'S_420_MC',
  S_460_MC = 'S_460_MC',
  S_500_MC = 'S_500_MC',
  S_550_MC = 'S_550_MC',
  S_600_MC = 'S_600_MC',
  S_650_MC = 'S_650_MC',
  S_700_MC = 'S_700_MC',
  WELDOX_500 = 'WELDOX_500',
  WELDOX_700 = 'WELDOX_700',
  WELDOX_900 = 'WELDOX_900',
  WELDOX_1100 = 'WELDOX_1100',
  HARDOX_400 = 'HARDOX_400',
  HARDOX_450 = 'HARDOX_450',
  HARDOX_500 = 'HARDOX_500',
}
