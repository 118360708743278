import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lsb-leg-length-picture',
  templateUrl: './leg-length-picture.component.svg',
  styleUrls: [],
})
export class LegLengthPictureComponent implements OnInit {
  @Input() dieWidth: string;
  @Input() legLength: string;
  constructor() {}

  ngOnInit(): void {}
}
