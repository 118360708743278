import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BendInnerRadiusComponent } from './views/bend-inner-radius/bend-inner-radius.component';
import { CalculationsComponent } from './views/calculations/calculations.component';
import { LegLengthComponent } from './views/leg-length/leg-length.component';
import { PressingForceComponent } from './views/pressing-force/pressing-force.component';
import { SheetThicknessComponent } from './views/sheet-thickness/sheet-thickness.component';
import { SheetWeightComponent } from './views/sheet-weight/sheet-weight.component';
import { DieWidthComponent } from './views/die-width/die-width.component';
import { PressingForceTableComponent } from './views/pressing-force-table/pressing-force-table.component';
import { CaseHeightComponent } from './views/case-height/case-height.component';
import { ToolWeightComponent } from './views/tool-weight/tool-weight.component';
import { MountHeightComponent } from './views/mount-height/mount-height.component';

const routes: Routes = [
  {
    path: 'calculations',
    component: CalculationsComponent,
    children: [
      {
        path: '',
        redirectTo: 'pressing-force',
        pathMatch: 'full',
      },
      { path: 'pressing-force', component: PressingForceComponent },
      { path: 'die-width', component: DieWidthComponent },
      { path: 'leg-length', component: LegLengthComponent },
      { path: 'pressing-force-table', component: PressingForceTableComponent },
      { path: 'sheet-thickness', component: SheetThicknessComponent },
      { path: 'sheet-width', component: SheetThicknessComponent },
      { path: 'sheet-weight', component: SheetWeightComponent },
      { path: 'case-height', component: CaseHeightComponent },
      { path: 'mount-height', component: MountHeightComponent },
      { path: 'tool-weight', component: ToolWeightComponent },
      { path: 'bend-inner-radius', component: BendInnerRadiusComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CalculationsRoutingModule {}
