<div class="input-container">
  <div class="dropdown">
    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.SHEET_THICKNESS | translate }}
    </span>
    <xui-dropdown
      #sheetThicknessDropDown
      [label]="selectedSheetThicknessInCmOrInch.value"
      data-ngtx="pressing-force:sheet-thickness"
    >
      <xui-value #selectedSheetThicknessInCmOrInch [binding]="selectedSheetThickness">
        <xui-length-converter imperialTargetUnit="mm" metricTargetUnit="mm"></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="2"
          [unit]="selectedSheetThicknessInCmOrInch.unit"
        ></xui-number-formatter>
      </xui-value>
      <xui-dropdown-item
        (click)="
          selectedSheetThickness = sheetThickness; clearResult(); sheetThicknessDropDown.close()
        "
        *ngFor="let sheetThickness of sheetThicknesses"
      >
        <xui-value #sheetThicknessInCentimeterOrInch [binding]="sheetThickness">
          <xui-length-converter
            imperialTargetUnit="mm"
            metricTargetUnit="mm"
          ></xui-length-converter>
          <xui-number-formatter
            [maxDecimals]="2"
            [unit]="sheetThicknessInCentimeterOrInch.unit"
          ></xui-number-formatter>
        </xui-value>
        {{ sheetThicknessInCentimeterOrInch.value }}
      </xui-dropdown-item>
    </xui-dropdown>
  </div>

  <!-- Calculate -->
  <div class="calculate-button button-info-box">
    <xui-button
      (click)="calculate()"
      xuiClickUsageTracking="{{ uiElementIds.CALCULATE_DIE_WIDTH }}"
    >
      {{ translations.CALCULATIONS.INPUT_LABELS.CALCULATE | translate }}
    </xui-button>
    <xui-icon
      [xuiTooltip]="calculateTooltip"
      class="info-flag"
      iconKey="Tooltip.Information"
    ></xui-icon>

    <xui-tooltip #calculateTooltip>
      <p class="simple-text-tooltip">
        {{ translations.CALCULATIONS.TOOLTIPS.DIE_WIDTH_CALCULATION_TOOL_TIP | translate }}
      </p>
    </xui-tooltip>
  </div>

  <div *ngIf="calculationResult$ | async as calculationResult" class="calculation-result">
    <div class="result-box">
      <xui-caption-label
        [caption]="translations.CALCULATIONS.RESULT_LABELS.MINIMAL_DIE_WIDTH | translate"
      >
        <xui-value
          #minimalDieWith
          [(binding)]="calculationResult.minimalDieWidth"
          [fallbackValue]="0"
        >
          <xui-length-converter
            imperialTargetUnit="mm"
            metricTargetUnit="mm"
          ></xui-length-converter>
          <xui-number-formatter
            [maxDecimals]="0"
            [unit]="minimalDieWith.unit"
          ></xui-number-formatter>
        </xui-value>
        {{ minimalDieWith.value }}
      </xui-caption-label>
      <xui-caption-label
        [caption]="translations.CALCULATIONS.RESULT_LABELS.OPTIMAL_DIE_WIDTH | translate"
      >
        <xui-value
          #optimalDieWidth
          [(binding)]="calculationResult.optimalDieWith"
          [fallbackValue]="0"
        >
          <xui-length-converter
            imperialTargetUnit="mm"
            metricTargetUnit="mm"
          ></xui-length-converter>
          <xui-number-formatter
            [maxDecimals]="0"
            [unit]="optimalDieWidth.unit"
          ></xui-number-formatter>
        </xui-value>
        {{ optimalDieWidth.value }}
      </xui-caption-label>
      <xui-caption-label
        [caption]="translations.CALCULATIONS.RESULT_LABELS.MAXIMAL_DIE_WIDTH | translate"
      >
        <xui-value
          #maximalDieWidth
          [(binding)]="calculationResult.maximalDieWith"
          [fallbackValue]="0"
        >
          <xui-length-converter
            imperialTargetUnit="mm"
            metricTargetUnit="mm"
          ></xui-length-converter>
          <xui-number-formatter
            [maxDecimals]="0"
            [unit]="maximalDieWidth.unit"
          ></xui-number-formatter>
        </xui-value>
        {{ maximalDieWidth.value }}
      </xui-caption-label>
    </div>
  </div>
  <div class="result-image-block">
    <lsb-die-width-picture
      [dieWidth]="translations.CALCULATIONS.TYPES.DIE_WIDTH | translate"
      [sheetThickness]="sheetThicknessPictureValue.value"
    >
      <xui-value
        #sheetThicknessPictureValue
        [(binding)]="selectedSheetThickness"
        [fallbackValue]="0"
      >
        <xui-length-converter imperialTargetUnit="mm" metricTargetUnit="mm"></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="2"
          [unit]="sheetThicknessPictureValue.unit"
        ></xui-number-formatter>
      </xui-value>
    </lsb-die-width-picture>
  </div>
</div>
