import { TranslationHelper } from '../../shared/helpers';
import { TranslateService } from '@ngx-translate/core';

export const checkForNegativeValue = (
  translations: TranslationHelper,
  translateService: TranslateService,
  pressingForce?: number,
  tensileStrength?: number,
  sheetThickness?: number,
  upperToolRadius?: number,
  dieWidth?: number,
  bendingLength?: number,
  bendingAngle?: number,
): string[] => {
  const errors: string[] = [];
  if (pressingForce && pressingForce <= 0) {
    errors.push(
      `${translateService.instant(
        translations.CALCULATIONS.TYPES.PRESSING_FORCE,
      )} ${translateService.instant(translations.CALCULATIONS.ERROR_MESSAGES.SHOULD_BE_POSITIVE)}`,
    );
  }
  if (tensileStrength && tensileStrength <= 0) {
    errors.push(
      `${translateService.instant(
        translations.CALCULATIONS.INPUT_LABELS.TENSILE_STRENGTH,
      )} ${translateService.instant(translations.CALCULATIONS.ERROR_MESSAGES.SHOULD_BE_POSITIVE)}`,
    );
  }
  if (sheetThickness && sheetThickness <= 0) {
    errors.push(
      `${translateService.instant(
        translations.CALCULATIONS.INPUT_LABELS.SHEET_THICKNESS,
      )} ${translateService.instant(translations.CALCULATIONS.ERROR_MESSAGES.SHOULD_BE_POSITIVE)}`,
    );
  }
  if (upperToolRadius && upperToolRadius <= 0) {
    errors.push(
      `${translateService.instant(
        translations.CALCULATIONS.INPUT_LABELS.UPPER_TOOL_RADIUS,
      )} ${translateService.instant(translations.CALCULATIONS.ERROR_MESSAGES.SHOULD_BE_POSITIVE)}`,
    );
  }
  if (dieWidth && dieWidth <= 0) {
    errors.push(
      `${translateService.instant(
        translations.CALCULATIONS.TYPES.DIE_WIDTH,
      )} ${translateService.instant(translations.CALCULATIONS.ERROR_MESSAGES.SHOULD_BE_POSITIVE)}`,
    );
  }
  if (bendingLength && bendingLength <= 0) {
    errors.push(
      `${translateService.instant(
        translations.CALCULATIONS.INPUT_LABELS.BENDING_LENGTH,
      )} ${translateService.instant(translations.CALCULATIONS.ERROR_MESSAGES.SHOULD_BE_POSITIVE)}`,
    );
  }
  if (bendingAngle && bendingAngle <= 0) {
    errors.push(
      `${translateService.instant(
        translations.CALCULATIONS.INPUT_LABELS.BENDING_ANGLE,
      )} ${translateService.instant(translations.CALCULATIONS.ERROR_MESSAGES.SHOULD_BE_POSITIVE)}`,
    );
  }

  return errors;
};
