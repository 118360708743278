import { DieWidthCalculationResult } from '../model/die-width-calculation-result';

export const DIE_WIDTH_TO_CALCULATE: DieWidthCalculationResult[] = [
  {
    sheetThickness: 0.5,
    minimalDieWidth: 4,
    optimalDieWith: 4,
    maximalDieWith: 8,
  },
  {
    sheetThickness: 0.75,

    minimalDieWidth: 4,
    optimalDieWith: 6,
    maximalDieWith: 12,
  },
  {
    sheetThickness: 1,
    minimalDieWidth: 5,
    optimalDieWith: 8,
    maximalDieWith: 16,
  },
  {
    sheetThickness: 1.25,
    minimalDieWidth: 6,
    optimalDieWith: 10,
    maximalDieWith: 20,
  },
  {
    sheetThickness: 1.5,
    minimalDieWidth: 6,
    optimalDieWith: 12,
    maximalDieWith: 20,
  },
  {
    sheetThickness: 1.75,
    minimalDieWidth: 8,
    optimalDieWith: 14,
    maximalDieWith: 24,
  },
  {
    sheetThickness: 2,
    minimalDieWidth: 10,
    optimalDieWith: 16,
    maximalDieWith: 24,
  },
  {
    sheetThickness: 2.5,
    minimalDieWidth: 12,
    optimalDieWith: 20,
    maximalDieWith: 30,
  },
  {
    sheetThickness: 3,
    minimalDieWidth: 16,
    optimalDieWith: 24,
    maximalDieWith: 40,
  },
  {
    sheetThickness: 3.5,
    minimalDieWidth: 16,
    optimalDieWith: 30,
    maximalDieWith: 50,
  },
  {
    sheetThickness: 4,
    minimalDieWidth: 20,
    optimalDieWith: 30,
    maximalDieWith: 60,
  },
  {
    sheetThickness: 4.5,
    minimalDieWidth: 20,
    optimalDieWith: 40,
    maximalDieWith: 70,
  },
  {
    sheetThickness: 5,
    minimalDieWidth: 30,
    optimalDieWith: 40,
    maximalDieWith: 80,
  },
  {
    sheetThickness: 6,
    minimalDieWidth: 30,
    optimalDieWith: 50,
    maximalDieWith: 90,
  },
  {
    sheetThickness: 7,
    minimalDieWidth: 40,
    optimalDieWith: 60,
    maximalDieWith: 100,
  },
  {
    sheetThickness: 8,
    minimalDieWidth: 50,
    optimalDieWith: 70,
    maximalDieWith: 120,
  },
  {
    sheetThickness: 10,
    minimalDieWidth: 70,
    optimalDieWith: 80,
    maximalDieWith: 150,
  },
  {
    sheetThickness: 12,
    minimalDieWidth: 80,
    optimalDieWith: 100,
    maximalDieWith: 150,
  },
  {
    sheetThickness: 15,
    minimalDieWidth: 90,
    optimalDieWith: 120,
    maximalDieWith: 150,
  },
  {
    sheetThickness: 20,
    minimalDieWidth: 90,
    optimalDieWith: 150,
    maximalDieWith: 150,
  },
];
