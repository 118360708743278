import { Component, OnInit } from '@angular/core';
import { TranslationHelper } from '../../../shared/helpers';
import { TranslateService } from '@ngx-translate/core';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import { DropdownItemValue } from '../../model/dropdown-item-value';
import { Material } from '../../model/material';
import { materialToTranslation, toolTypeToTranslation } from '../../helpers/enum-to-translation';
import {
  MATERIALS_FOR_PRESSING_FORCE_TABLE,
  ROLL_BENDS,
} from '../../model/default-values/pressing-force-table-default-values';
import { PressingForceTableToolType } from '../../model/pressing-force-table-tool-type';
import { TensileStrengths } from '../../model/tensile-strength';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { PressingForceTableService } from '../../services/pressing-force-table.service';
import { PressingForceTableCalculationResult } from '../../model/pressing-force-table-calculation-result';

@Component({
  selector: 'lsb-pressing-force-table',
  templateUrl: './pressing-force-table.component.html',
  styleUrls: ['../../styles/calculations-base-style.scss', './pressing-force-table.component.scss'],
  providers: [PressingForceTableService],
})
export class PressingForceTableComponent implements OnInit {
  public readonly uiElementIds = UiElementIds;
  public readonly uiToolTypes = PressingForceTableToolType;

  public materials: DropdownItemValue<Material>[] = [];
  public selectedMaterial: Material = Material.STEEL;
  public tensileStrength: number = TensileStrengths.get(Material.STAINLESS_STEEL)!;

  public toolTypes: DropdownItemValue<PressingForceTableToolType>[] = [];
  public selectedToolType: PressingForceTableToolType = PressingForceTableToolType.STANDARD_TOOL;
  public rollBends = ROLL_BENDS;
  public selectedRollBend = ROLL_BENDS[0];
  public materials$: Observable<DropdownItemValue<Material>[]>;
  private materialsSubject: BehaviorSubject<DropdownItemValue<Material>[]>;

  private clearResultSubject: Subject<PressingForceTableCalculationResult | undefined> =
    new Subject<PressingForceTableCalculationResult | undefined>();
  public calculationResult$: Observable<PressingForceTableCalculationResult | undefined> = merge(
    this.clearResultSubject.asObservable(),
    this.calculationService.calculationResult$,
  );

  constructor(
    public translations: TranslationHelper,
    private translateService: TranslateService,
    private calculationService: PressingForceTableService,
  ) {
    for (const material of MATERIALS_FOR_PRESSING_FORCE_TABLE) {
      this.materials.push({
        id: material,
        name: materialToTranslation(material, translations, translateService),
      });
    }

    this.materialsSubject = new BehaviorSubject<DropdownItemValue<Material>[]>(
      this.materials.filter((m) => m.id !== Material.ALUMINUM),
    );
    this.materials$ = this.materialsSubject.asObservable();

    for (const toolType of Object.values(PressingForceTableToolType)) {
      this.toolTypes.push({
        id: toolType,
        name: toolTypeToTranslation(toolType, translations, translateService),
      });
    }
  }

  ngOnInit(): void {}

  public selectedMaterialName(): string {
    if (this.selectedMaterial) {
      return this.materials.find((m) => m.id === this.selectedMaterial)?.name ?? 'unknown';
    } else {
      return this.translateService.instant(
        this.translations.CALCULATIONS.INPUT_LABELS.USER_DEFINED,
      );
    }
  }

  public onSelectMaterial(newMaterial: Material) {
    this.selectedMaterial = newMaterial;
    this.tensileStrength = TensileStrengths.get(this.selectedMaterial)!;
    this.clearResult();
  }

  public selectedToolTypeName(): string {
    if (this.selectedToolType) {
      return this.toolTypes.find((m) => m.id === this.selectedToolType)?.name ?? 'unknown';
    } else {
      return this.translateService.instant(
        this.translations.CALCULATIONS.INPUT_LABELS.USER_DEFINED,
      );
    }
  }

  public onSelectToolType(newToolType: PressingForceTableToolType) {
    this.selectedToolType = newToolType;
    this.selectedMaterial = MATERIALS_FOR_PRESSING_FORCE_TABLE[0];
    if (newToolType === PressingForceTableToolType.ROLL_BEND) {
      this.materialsSubject.next(this.materials);
    } else {
      this.materialsSubject.next(this.materials.filter((m) => m.id !== Material.ALUMINUM));
    }
    this.clearResult();
  }

  public clearResult(): void {
    this.clearResultSubject.next(undefined);
  }

  public calculate(): void {
    this.calculationService.calculate(
      this.selectedToolType,
      this.selectedMaterial,
      this.selectedRollBend,
    );
  }
}
