import { Component, OnInit } from '@angular/core';
import { TranslationHelper } from '../../../shared/helpers';
import { DieWidthCalculationService } from '../../services/die-width-calculation.service';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import { SHEET_THICKNESSES } from '../../model/default-dimensions';
import { merge, Observable, Subject } from 'rxjs';
import { DieWidthCalculationResult } from '../../model/die-width-calculation-result';

@Component({
  selector: 'lsb-die-width',
  templateUrl: './die-width.component.html',
  styleUrls: ['../../styles/calculations-base-style.scss', './die-width.component.scss'],
  providers: [DieWidthCalculationService],
})
export class DieWidthComponent implements OnInit {
  public readonly uiElementIds = UiElementIds;

  public sheetThicknesses = SHEET_THICKNESSES;
  public selectedSheetThickness: number = SHEET_THICKNESSES[0];

  private clearResultSubject: Subject<DieWidthCalculationResult | undefined> = new Subject<
    DieWidthCalculationResult | undefined
  >();

  public calculationResult$: Observable<DieWidthCalculationResult | undefined> = merge(
    this.clearResultSubject.asObservable(),
    this.calculationService.calculationResult$,
  );
  constructor(
    public translations: TranslationHelper,
    private calculationService: DieWidthCalculationService,
  ) {}

  ngOnInit(): void {}

  public calculate(): void {
    this.calculationService.calculateDieWidth(+this.selectedSheetThickness);
  }

  public clearResult(): void {
    this.clearResultSubject.next(undefined);
  }
}
