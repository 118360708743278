import { TranslateService } from '@ngx-translate/core';
import { TranslationHelper } from '../../shared/helpers';
import { BendingForceCalculationType } from '../model/bending-force-calculation-type';
import { CalculationType } from '../model/calculation-type';
import { Material } from '../model/material';
import { MountHeightCalculationType } from '../model/mount-height/mount-height-calculation-type';
import { PressingForceTableToolType } from '../model/pressing-force-table-tool-type';
import { ToolCategory } from '../model/tool-category';

export const calculationTypeToTranslation = (
  calculationType: CalculationType,
  translations: TranslationHelper,
  translateService: TranslateService,
): string => {
  switch (calculationType) {
    case CalculationType.PRESSING_FORCE:
      return translateService.instant(translations.CALCULATIONS.TYPES.PRESSING_FORCE);
    case CalculationType.DIE_WIDTH:
      return translateService.instant(translations.CALCULATIONS.TYPES.DIE_WIDTH);
    case CalculationType.LEG_LENGTH:
      return translateService.instant(translations.CALCULATIONS.TYPES.LEG_LENGTH);
    case CalculationType.BEND_INNER_RADIUS:
      return translateService.instant(translations.CALCULATIONS.TYPES.BEND_INNER_RADIUS);
    case CalculationType.PRESSING_FORCE_TABLE:
      return translateService.instant(translations.CALCULATIONS.TYPES.PRESSING_FORCE_TABLE);
    case CalculationType.CASE_HEIGHT:
      return translateService.instant(translations.CALCULATIONS.TYPES.CASE_HEIGHT);
    case CalculationType.SHEET_WEIGHT:
      return translateService.instant(translations.CALCULATIONS.TYPES.SHEET_WEIGHT);
    case CalculationType.SHEET_WIDTH:
      return translateService.instant(translations.CALCULATIONS.TYPES.SHEET_WIDTH);
    case CalculationType.MOUNT_HEIGHT:
      return translateService.instant(translations.CALCULATIONS.TYPES.MOUNT_HEIGHT);
    case CalculationType.TOOL_WEIGHT:
      return translateService.instant(translations.CALCULATIONS.TYPES.TOOL_WEIGHT);
  }
};

export const bendingForceCalculationTypeToTranslation = (
  bendingForceCalculationType: BendingForceCalculationType,
  translations: TranslationHelper,
  translateService: TranslateService,
): string => {
  switch (bendingForceCalculationType) {
    case BendingForceCalculationType.PRESSING_FORCE:
      return translateService.instant(translations.CALCULATIONS.TYPES.PRESSING_FORCE);
    case BendingForceCalculationType.SHEET_THICKNESS:
      return translateService.instant(translations.CALCULATIONS.INPUT_LABELS.SHEET_THICKNESS);
    case BendingForceCalculationType.BENDING_LENGTH:
      return translateService.instant(translations.CALCULATIONS.INPUT_LABELS.BENDING_LENGTH);
  }
};

export const materialToTranslation = (
  material: Material,
  translations: TranslationHelper,
  translateService: TranslateService,
): string => {
  switch (material) {
    case Material.STEEL:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.STEEL);
    case Material.STAINLESS_STEEL:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.STAINLESS_STEEL);
    case Material.ALUMINUM:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.ALUMINUM);
    case Material.X5CrNi18_10:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.X5CrNi18_10);
    case Material.ALMG3:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.ALMG3);
    case Material.DC_01:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.DC_01);
    case Material.DC_03:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.DC_03);
    case Material.DC_04:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.DC_04);
    case Material.S_235_JR:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.S_235_JR);
    case Material.S_355_JR:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.S_355_JR);
    case Material.S_355_NC:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.S_355_NC);
    case Material.S_355_MC:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.S_355_MC);
    case Material.S_420_NC:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.S_420_NC);
    case Material.S_420_MC:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.S_420_MC);
    case Material.S_460_MC:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.S_460_MC);
    case Material.S_500_MC:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.S_500_MC);
    case Material.S_550_MC:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.S_550_MC);
    case Material.S_600_MC:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.S_600_MC);
    case Material.S_650_MC:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.S_650_MC);
    case Material.S_700_MC:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.S_700_MC);
    case Material.WELDOX_500:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.WELDOX_500);
    case Material.WELDOX_700:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.WELDOX_700);
    case Material.WELDOX_900:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.WELDOX_900);
    case Material.WELDOX_1100:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.WELDOX_1100);
    case Material.HARDOX_400:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.HARDOX_400);
    case Material.HARDOX_450:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.HARDOX_450);
    case Material.HARDOX_500:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.HARDOX_500);
  }
};

export const toolTypeToTranslation = (
  toolType: PressingForceTableToolType,
  translations: TranslationHelper,
  translateService: TranslateService,
): string => {
  switch (toolType) {
    case PressingForceTableToolType.STANDARD_TOOL:
      return translateService.instant(translations.CALCULATIONS.TOOL_TYPE.STANDARD_TOOL);
    case PressingForceTableToolType.FOLDING:
      return translateService.instant(translations.CALCULATIONS.TOOL_TYPE.FOLDING);
    case PressingForceTableToolType.ROLL_BEND:
      return translateService.instant(translations.CALCULATIONS.TOOL_TYPE.ROLL_BEND);
  }
};

export const toolCategoryToTranslation = (
  category: ToolCategory,
  translations: TranslationHelper,
  translateService: TranslateService,
): string => {
  switch (category) {
    case ToolCategory.ADAPTER:
      return translateService.instant(translations.CALCULATIONS.TOOL_CATEGORY.ADAPTER);
    case ToolCategory.DIE:
      return translateService.instant(translations.CALCULATIONS.TOOL_CATEGORY.DIE);
    case ToolCategory.LIGHTWEIGHT_TOOLING:
      return translateService.instant(translations.CALCULATIONS.TOOL_CATEGORY.LIGHTWEIGHT_TOOLING);
    case ToolCategory.PUNCH:
      return translateService.instant(translations.CALCULATIONS.TOOL_CATEGORY.PUNCH);
    case ToolCategory.INSERT:
      return translateService.instant(translations.CALCULATIONS.TOOL_CATEGORY.INSERT);
  }
};

export const mountHeightCalculationTypeToTranslation = (
  mountHeightCalculationType: MountHeightCalculationType,
  translations: TranslationHelper,
  translateService: TranslateService,
): string => {
  switch (mountHeightCalculationType) {
    case MountHeightCalculationType.REMAINING_OPENING:
      return translateService.instant(
        translations.CALCULATIONS.MOUNT_HEIGHT_CALCULATION_TYPE.REMAINING_OPENING,
      );
    case MountHeightCalculationType.UPPER_TOOL_HEIGHT:
      return translateService.instant(
        translations.CALCULATIONS.MOUNT_HEIGHT_CALCULATION_TYPE.UPPER_TOOL_HEIGHT,
      );
    case MountHeightCalculationType.MACHINE_SELECTION:
      return translateService.instant(
        translations.CALCULATIONS.MOUNT_HEIGHT_CALCULATION_TYPE.MACHINE_SELECTION,
      );
  }
};
