import {
  Article,
  Category,
  ContentType,
  HeadlineAndText,
  Hint,
  Illustration,
  MediaType,
  MOCK_VIDEOS,
  WeldingPrinciple,
  WeldingPrincipleDetails,
} from '../../../../shared';
import { CategoryToPrinciples, UserProfile } from '../types';
import { PartsFilterCategory } from '../types/parts-filter-category';
import {
  MOCK_CATEGORY_NAMES,
  MOCK_WELDING_PRINCIPLE_GALLERY_IMAGES,
  MOCK_WELDING_PRINCIPLE_IMAGES,
  MOCK_WELDING_PRINCIPLE_NAMES,
} from './mock-data';

const dasherize = (name: string) => name.toLocaleLowerCase().replace(' ', '-');

let i = 1;

export const ARTICLES_DE_MOCK: Article[] = [
  {
    date: '21/03/14',
    link: 'https://trumpf.com',
    image: {
      src: 'https://www.centigrade.de/basic/resources/images/portfolio/Portfolio.Trumpf-TouchPoint-System.jpg',
      description: 'A cool technical image.',
      type: MediaType.IMAGE,
    },
    title: 'An Article You MUST Read!',
    description:
      'A really cool article about some interesting topic. You will not want to miss that one, I guess!' +
      ' A really cool article about some interesting topic. You will not want to miss that one, I guess!' +
      ' A really cool article about some interesting topic. You will not want to miss that one, I guess!' +
      ' A really cool article about some interesting topic. You will not want to miss that one, I guess!',
  },
  {
    date: '21/02/12',
    link: 'https://trumpf.com',
    image: {
      src: 'https://www.centigrade.de/basic/resources/images/portfolio/SEW-Industrie-4-0-Tile-auto/SEW-Industrie-4-0-Tile-1920w.jpg',
      description: 'Some tooltips!',
      type: MediaType.IMAGE,
    },
    title: 'Make the most of your configurations with tooltips!',
    description:
      'Tooltips are the go-to way of showing details about your configurations. See how to use them!',
  },
  {
    date: '21/06/22',
    link: 'https://trumpf.com',
    image: {
      src: 'https://hmi-project.com/thumbs/news/aasted-smart-control-nominiert/hmi-project-news-aasted-nominierung-4-1560x780-50-50-q100.png',
      description: 'A new way to config your parts.',
      type: MediaType.IMAGE,
    },
    title: 'Create Configurations from example parts!',
    description:
      'A new feature just arrived at BendGuide! You can now create new configurations that are based on example parts. Check it out!',
  },
];

export const MOCK_USERPROFILE: UserProfile = {
  givenName: 'Max',
  familyName: 'Muster',
  locality: 'de-DE',
  confirmedCustomer: true,
};

export const MOCK_CATEGORIES: Category[] = MOCK_CATEGORY_NAMES.map((name) => {
  return {
    id: dasherize(name),
    name,
  };
});

export const MOCK_BENDING_PRINCIPLES: WeldingPrinciple[] = MOCK_WELDING_PRINCIPLE_NAMES.map(
  (name, index) => ({
    id: dasherize(name),
    name,
    description: 'At vero eos at accusam et justo duo dolores et ea rebum.',
    image: MOCK_WELDING_PRINCIPLE_IMAGES[index % MOCK_WELDING_PRINCIPLE_IMAGES.length],
  }),
);

export const MOCK_CATEGORY_TO_PRINCIPLES: CategoryToPrinciples[] = [
  {
    category: {
      id: dasherize(MOCK_CATEGORY_NAMES[0]),
      name: MOCK_CATEGORY_NAMES[0],
    },
    principles: MOCK_BENDING_PRINCIPLES.slice(0, 5),
  },
  {
    category: {
      id: dasherize(MOCK_CATEGORY_NAMES[1]),
      name: MOCK_CATEGORY_NAMES[1],
    },
    principles: MOCK_BENDING_PRINCIPLES.slice(5),
  },
  {
    category: {
      id: dasherize(MOCK_CATEGORY_NAMES[2]),
      name: MOCK_CATEGORY_NAMES[2],
    },
    principles: [],
  },
];

const HEADLINE_AND_TEXTS: HeadlineAndText[] = [
  {
    type: ContentType.TEXT,
    headline: 'Einsatzmöglichkeiten',
    text:
      // tslint:disable-next-line: max-line-length
      'Mit Fügehilfen kann die Positionierung der Einzelteile in das Bauteil gelegt werden und somit die Vorrichtungstechnik vereinfach werden. Auch beim Vorheften helfen Fügehilfen die Teile einfacher zueinander zu positionieren. Bei der Verwendung muss darauf geachtet werden, dass Bauteile über Biegungen hinweig nicht überbestimmt ist. Biegetoleranzen führen dann zu Spalten in der Nahtgeometrie.',
  },
  {
    type: ContentType.TEXT,
    headline: 'Technische Umsetzung',
    text:
      // tslint:disable-next-line: max-line-length
      'Um Bleche sicher fügen zu können wird als Richtwert empfohlen den Fügespalt um 0,2 mm größer zu gestalten. So können die Bleche gefügt werden, auch wenn die Blechdicke kein Untermaß hat. Für Blechdicken größer 2 mm empfiehlt es sich die Ecken der Aussparung freizuräumen.',
  },
];

const HINTS: Hint[] = [
  {
    type: ContentType.HINT,
    text:
      // tslint:disable-next-line: max-line-length
      'Für Blechdicken kleiner 2 mm, können *Fügehilfen* auch über Biegungen hinweg eingesetzt werden. Dabei muss ausreichend Abstand zur Biegung vorgesehen werden, damit die elastische Verformung des Blechs die Biegetoleranz ausgleichen kann.',
  },
  {
    type: ContentType.HINT,
    text:
      // tslint:disable-next-line: max-line-length
      '*Fügehilfen* müssen gezielt eingesetzt werden, können aber an den richtigen Stellen einen hohen Mehrwert bieten. Es lohnt sich die eigene Baugruppe auf den Einsatz von *Fügehilfen* zu prüfen. Schauen Sie sich den Einsatz von Fügehilfen an den Beispielbauteilen an.',
  },
];

const PROS_AND_CONS: HeadlineAndText[] = [
  {
    type: ContentType.TEXT,
    headline: 'Vorteile',
    text:
      // tslint:disable-next-line: max-line-length
      '+	Einzelteile werden zueinander positioniert\n\n+	Vereinfachen die Vorrichtungstechnik\n\n+	Fehlerhafte verbinden von ähnlichen Teilen kann vermieden 	werden',
  },
  {
    type: ContentType.TEXT,
    headline: 'Nachteile',
    text:
      // tslint:disable-next-line: max-line-length
      '-	Druckdichtigkeit sinkt (Tiefschweißen)\n\n-	Optisch in nicht lackierten Wärmeleitnähten zu erkennen',
  },
];

const ILLUSTRATION: Illustration = {
  type: ContentType.ILLUSTRATION,
  media: {
    type: MediaType.IMAGE,
    src: MOCK_WELDING_PRINCIPLE_IMAGES[0],
  },
};

export const MOCK_WELDING_PRINCIPLE_DETAILS: WeldingPrincipleDetails = {
  id: 'joining-aids',
  categoryId: 'fixture-construction',
  name: 'Fügehilfen',
  keywords: [
    { id: 'heat-conduction', displayText: 'Wärmeleitnaht' },
    { id: 'sheet-fixture', displayText: 'Blechvorrichtung' },
    { id: 'removable-core', displayText: 'Entnehmbarer Kern' },
  ],
  titleMedia: { type: MediaType.IMAGE, src: MOCK_WELDING_PRINCIPLE_IMAGES[0] },
  gallery: [
    {
      type: MediaType.IMAGE,
      src: MOCK_WELDING_PRINCIPLE_IMAGES[0],
      description: 'Image 1 description',
    },
    {
      type: MediaType.VIDEO,
      src: MOCK_VIDEOS[0].src,
      description: 'Video 1 description with *bold* text\nand also new-lines\nline3',
    },
    {
      type: MediaType.VIDEO,
      src: MOCK_VIDEOS[1].src,
      description: '',
    },
    {
      type: MediaType.IMAGE,
      src: MOCK_WELDING_PRINCIPLE_GALLERY_IMAGES[1],
      description: 'Image 2 description',
    },
    {
      type: MediaType.IMAGE,
      src: MOCK_WELDING_PRINCIPLE_GALLERY_IMAGES[2],
      description: 'Image 3 description',
    },
    {
      type: MediaType.IMAGE,
      src: MOCK_WELDING_PRINCIPLE_GALLERY_IMAGES[3],
      description: 'Image 4 description',
    },
    {
      type: MediaType.IMAGE,
      src: MOCK_WELDING_PRINCIPLE_GALLERY_IMAGES[0],
      description: 'Image 5 description',
    },
    {
      type: MediaType.IMAGE,
      src: MOCK_WELDING_PRINCIPLE_GALLERY_IMAGES[1],
      description: 'Image 6 description',
    },
    {
      type: MediaType.IMAGE,
      src: MOCK_WELDING_PRINCIPLE_GALLERY_IMAGES[2],
      description: 'Image 7 description',
    },
    {
      type: MediaType.IMAGE,
      src: MOCK_WELDING_PRINCIPLE_GALLERY_IMAGES[3],
      description: 'Image 8 description',
    },
    {
      type: MediaType.IMAGE,
      src: MOCK_WELDING_PRINCIPLE_GALLERY_IMAGES[0],
      description: 'Image 9 description',
    },
    {
      type: MediaType.IMAGE,
      src: MOCK_WELDING_PRINCIPLE_GALLERY_IMAGES[1],
      description: 'Image 10 description',
    },
    {
      type: MediaType.IMAGE,
      src: MOCK_WELDING_PRINCIPLE_GALLERY_IMAGES[2],
      description: 'Image 11 description',
    },
    {
      type: MediaType.IMAGE,
      src: MOCK_WELDING_PRINCIPLE_GALLERY_IMAGES[3],
      description: 'Image 12 description',
    },
  ],
  description: 'At vero eos at accusam et justo duo dolores et ea rebum.',
  content: [
    HEADLINE_AND_TEXTS[0],
    HINTS[0],
    PROS_AND_CONS[0],
    PROS_AND_CONS[1],
    ILLUSTRATION,
    { type: ContentType.SPACER },
    HEADLINE_AND_TEXTS[1],
    HINTS[1],
  ],
  accessibleWithoutLogin: false,
};

// This filter categories must come from Backend in the future
export const PARTS_FILTERS: PartsFilterCategory[] = [
  {
    id: 'requirements',
    label: 'Requirements',
    tags: [
      { id: 'waterproof', keyword: 'Waterproof', partsFieldToApply: '', isChecked: false },
      { id: 'watertight', keyword: 'Watertight', partsFieldToApply: '', isChecked: false },
      { id: 'heat-resistent', keyword: 'Heat-resistent', partsFieldToApply: '', isChecked: false },
    ],
  },
  {
    id: 'seamGeometry',
    label: 'Seam Geometry',
    tags: [
      {
        id: 'deepPenetrationWelding',
        keyword: 'Deep penetration welding',
        partsFieldToApply: '',
        isChecked: false,
      },
      {
        id: 'heatConductionWelding',
        keyword: 'Heat conduction welding',
        partsFieldToApply: '',
        isChecked: false,
      },
      {
        id: 'no90JointGeometry',
        keyword: 'No 90° joint geometry',
        partsFieldToApply: '',
        isChecked: false,
      },
    ],
  },
  {
    id: 'material',
    label: 'Material',
    tags: [
      { id: 'steel', keyword: 'Steel', partsFieldToApply: 'material', isChecked: false },
      { id: 'aluminium', keyword: 'Aluminium', partsFieldToApply: 'material', isChecked: false },
      { id: 'ironSteel', keyword: 'Iron steel', partsFieldToApply: 'material', isChecked: false },
    ],
  },
  {
    id: 'sheetThickness',
    label: 'Sheet thickness',
    tags: [
      { id: 'lessOrEqual1mm', keyword: '<= 1mm', partsFieldToApply: '', isChecked: false },
      { id: 'oneAndTheHalfmm', keyword: '1,5 mm', partsFieldToApply: '', isChecked: false },
      { id: 'twomm', keyword: '2 mm', partsFieldToApply: '', isChecked: false },
      { id: 'moreThanTwomm', keyword: '> 2 mm', partsFieldToApply: '', isChecked: false },
    ],
  },
];
