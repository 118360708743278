import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lsb-mount-height-picture',
  templateUrl: './mount-height-picture.component.svg',
  styleUrls: [],
})
export class MountHeightPictureComponent implements OnInit {
  @Input() desiredRemainingOpening: string;
  @Input() minimalMountHeight: string;
  @Input() bottomToolHeight: string;
  @Input() upperToolHeight: string;
  constructor() {}

  ngOnInit(): void {}
}
