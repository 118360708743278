import { Material } from '../material';

export const MATERIALS_FOR_PRESSING_FORCE_TABLE: Material[] = [
  Material.STEEL,
  Material.STAINLESS_STEEL,
  Material.ALUMINUM,
];

export const ROLL_BENDS: string[] = [
  'RB 200 - RB 600',
  'RB 1250',
  'Opt. RB 200 - RB 600',
  'Opt. RB 1250',
];
