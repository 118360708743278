<p>{{ tableData?.caption | translate }}</p>
<ng-container *ngIf="tableData?.legend">
  <div *ngFor="let legend of tableData?.legend" class="legend-area">
    <div [style.background-color]="legend.color" class="legend-box"></div>
    <div>{{ legend.toolTip | translate }}</div>
  </div>
</ng-container>
<div class="table-parent-area">
  <table>
    <tbody>
      <tr *ngFor="let rowIndex of rowsScaffold">
        <td
          *ngFor="let columnIndex of columnsScaffold"
          [style.background-color]="cellBackground(rowIndex, columnIndex)"
          [style.border-color]="tableData?.borderColor"
        >
          {{ cellValue(rowIndex, columnIndex) }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
