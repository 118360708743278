<div class="pressing-force-input-container">
  <!-- Material -->
  <div class="dropdown">
    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.MATERIAL | translate }}
    </span>
    <xui-dropdown
      #materialDropDown
      [label]="selectedMaterialName()"
      data-ngtx="pressing-force-table:material"
    >
      <xui-dropdown-item
        (click)="onSelectMaterial(material.id); materialDropDown.close()"
        *ngFor="let material of materials$ | async"
      >
        {{ material?.name }}
      </xui-dropdown-item>
    </xui-dropdown>
  </div>
  <xui-input-field
    (focusout)="clearResult()"
    [(text)]="tensileStrengthValue.value"
    [disabled]="true"
    data-ngtx="pressing-force-table:tensileStrength"
    label="{{ translations.CALCULATIONS.INPUT_LABELS.TENSILE_STRENGTH | translate }}"
  >
    <xui-value
      #tensileStrengthValue
      [(binding)]="tensileStrength"
      [fallbackValue]="tensileStrength"
      lsbValidateRequired
    >
      <xui-pressure-converter
        imperialTargetUnit="psi"
        metricTargetUnit="N/mm²"
      ></xui-pressure-converter>
      <xui-number-formatter
        [maxDecimals]="0"
        [unit]="tensileStrengthValue.unit"
      ></xui-number-formatter>
    </xui-value>
  </xui-input-field>

  <!-- Tool type -->
  <div class="dropdown">
    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.TOOL_TYPE | translate }}
    </span>
    <xui-dropdown
      #toolTypeDropDown
      [label]="selectedToolTypeName()"
      data-ngtx="pressing-force-table:tool-type"
    >
      <xui-dropdown-item
        (click)="onSelectToolType(toolType.id); toolTypeDropDown.close()"
        *ngFor="let toolType of toolTypes"
      >
        {{ toolType?.name }}
      </xui-dropdown-item>
    </xui-dropdown>
  </div>

  <!-- Roll Bend -->
  <div *ngIf="selectedToolType === uiToolTypes.ROLL_BEND" class="dropdown">
    <span class="dropdown-label"> RollBand </span>
    <xui-dropdown
      #rollBandDropDown
      [label]="selectedRollBend"
      data-ngtx="pressing-force-table:tool-type"
    >
      <xui-dropdown-item
        (click)="selectedRollBend = rollBand; clearResult(); rollBandDropDown.close()"
        *ngFor="let rollBand of rollBends"
      >
        {{ rollBand }}
      </xui-dropdown-item>
    </xui-dropdown>
  </div>

  <!-- Calculate -->
  <div class="calculate-button">
    <xui-button
      (click)="calculate()"
      xuiClickUsageTracking="{{ uiElementIds.CALCULATE_PRESSING_FORCE_TABLE }}"
    >
      {{ translations.CALCULATIONS.INPUT_LABELS.CALCULATE | translate }}
    </xui-button>
  </div>
</div>
<div *ngIf="calculationResult$ | async as calculationResult">
  <lsb-result-table [tableData]="calculationResult.resultTable"></lsb-result-table>
</div>
