import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { TextFieldIcon } from '../../types/text-field-icon';
import { IForceUpdateView } from '@trumpf-xguide/xguide/lib/input-field/types';

/**
 * A simple input field to allow the user to enter text values.
 */
@Component({
  selector: 'lsb-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
})
export class InputFieldComponent implements IForceUpdateView<string> {
  @Input() text = '';
  @Input() unit: string;
  @Input() valueField = false;
  @Input() placeholder = '';
  @Input() label = '';
  @Input() hint = '';
  @Input() icon: TextFieldIcon;
  @Input() readonly = false;
  @Input() disabled = false;
  @Output() textChange = new EventEmitter<string>();

  @ViewChild('txt') inputRef: ElementRef<HTMLInputElement>;

  public readonly inputId = uuid();
  protected readonly bindablePropertyName: keyof this = 'text';

  public get classes() {
    const cssClasses: any = {
      readonly: this.readonly,
      disabled: this.disabled,
    };

    if (this.icon) {
      cssClasses[this.icon] = true;
    }

    return cssClasses;
  }

  private get input() {
    return this.inputRef.nativeElement;
  }

  onChange(text: string): void {
    this.text = text;
    this.textChange.emit(text);
  }

  forceUpdateView(updated: string): void {
    if (!this.input) {
      return;
    }

    this.input.value = updated;
  }
}
