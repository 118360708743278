<ng-container *ngIf="partDetails$ | async as partDetails">
  <div class="part-details">
    <div class="related-parts">
      <span class="label">{{ translations.PARTS.RELATED_PARTS | translate }}</span>
      <xui-thumbnail (selectedItemChange)="selectRelatedPart($event.id)">
        <xui-thumbnail-item
          *ngFor="let related of partDetails.relatedParts"
          [media]="asImageMedia(related.image)"
          [id]="related.id"
          [attr.title]="related.name"
        ></xui-thumbnail-item>
      </xui-thumbnail>
    </div>
    <div class="part-details-content">
      <div class="part-header">
        <lsb-part-specification [part]="partDetails"></lsb-part-specification>

        <div class="part-actions">
          <xui-button
            type="primary"
            xuiClickUsageTracking="{{ uiElementIds.PARTS_DOWNLOAD }}"
            (click)="downloadCAD()"
          >
            {{ translations.PARTS.DOWNLOAD_CAD | translate }}
          </xui-button>
        </div>
      </div>

      <div class="description">
        {{ partDetails.description }}
      </div>

      <div class="media-container">
        <hr />
        <xui-media-gallery
          [items]="partDetails.gallery"
          [(selectedIndex)]="selectedMediaIndex"
        ></xui-media-gallery>
        <hr />
      </div>
      <div
        class="welding-principles"
        *ngIf="relatedWeldingPrinciples$ | async as relatedWeldingPrinciples"
      >
        <h2 class="header">
          {{ translations.PARTS.RELATED_BENDING_PRINCIPLES_TITLE | translate }}
        </h2>
        <div class="cards">
          <xui-card
            *ngFor="let principle of relatedWeldingPrinciples"
            class="card"
            [title]="principle.name"
            [preview]="principle.image | imageUrl"
            (click)="selectWeldingPrinciple(principle.id)"
          >
            {{ principle.description }}
          </xui-card>
        </div>
        <xui-button
          *ngIf="
            partDetails.weldingPrinciples?.length > relatedWeldingPrinciplesToShow &&
            relatedWeldingPrinciples.length <= relatedWeldingPrinciplesToShow
          "
          class="more-button"
          xuiClickUsageTracking="{{ uiElementIds.PARTS_MORE_DETAILS }}"
          (click)="updatePrinciples$.next()"
          [type]="'secondary'"
        >
          {{ translations.BENDING_PRINCIPLES.DETAILS.MORE_PARTS | translate }}
        </xui-button>
      </div>
    </div>
  </div>
</ng-container>
