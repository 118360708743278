import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import {
  CookieService,
  DialogModule,
  LocalStorageModule,
  UsageTrackingModule,
  XGuideConfigurationModule,
} from '@trumpf-xguide/xguide';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { Language, UsageTrackingMessageType } from '../../../shared';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CalculationsModule } from './calculations/calculations.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { WeldingPrinciplesService } from './services/welding-principles/welding-principles.service';
import { WeldingPrinciplesServiceMock } from './services/welding-principles/welding-principles.service.mock';
import { identifyBrowser, isSupportedBrowser } from './shared/helpers/browser.helper';
import { DocumentToken } from './shared/injection-tokens/document.token';
import { IsSupportedBrowserToken } from './shared/injection-tokens/is-supported-browser.injection.token';
import { NavigatorToken } from './shared/injection-tokens/navigator.injection-token';
import { ImageUrlPipe } from './shared/pipe';
import { SharedModule } from './shared/shared.module';
import { UsageTrackingService } from './shared/usage-tracking/services/usage-tracking/usage-tracking.service';
import { UsageTrackingServiceMock } from './shared/usage-tracking/services/usage-tracking/usage-tracking.service.mock';
import { AboutComponent } from './views/about/about.component';
import { CopyrightComponent } from './views/copyright/copyright.component';
import { PartDetailComponent } from './views/part-detail/part-detail.component';
import { PartFixtureComponent } from './views/part-fixture/part-fixture.component';
import { PartsComponent } from './views/parts/parts.component';
import { SettingsComponent } from './views/settings/settings.component';
import { UnsupportedBrowserComponent } from './views/unsupported-browser/unsupported-browser.component';
import { WeldingPrincipleDetailsComponent } from './views/welding-principle-details/welding-principle-details.component';
import { WeldingPrincipleOverviewComponent } from './views/welding-principle-overview/welding-principle-overview.component';
import { WeldingPrinciplesComponent } from './views/welding-principles/welding-principles.component';

const COMPONENTS = [
  // <this comment forces items to be placed below each other>
  AppComponent,
  PartFixtureComponent,
  SettingsComponent,
];

const VIEWS = [
  WeldingPrincipleDetailsComponent,
  WeldingPrincipleOverviewComponent,
  WeldingPrinciplesComponent,
  PartsComponent,
  PartDetailComponent,
  AboutComponent,
  CopyrightComponent,
  PartFixtureComponent,
];

const DIALOG_VIEWS = [UnsupportedBrowserComponent];

export class WebpackTranslateLoader extends TranslateLoader {
  getTranslation(language: Language) {
    return from(import(`../assets/i18n/${language}.ts`)).pipe(pluck('default'));
  }
}

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}

const urlsToInjectToken = [environment.apiUrl];
if (environment.oidcIssuer) {
  urlsToInjectToken.push(new URL(environment.oidcIssuer).origin);
}

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CalculationsModule,
    DashboardModule,
    DialogModule.forRoot(),
    HttpClientModule,
    LocalStorageModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: urlsToInjectToken,
        sendAccessToken: true,
      },
    }),
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    UsageTrackingModule.forRoot({
      elementClickedMessageType: UsageTrackingMessageType.ElementClicked,
      usageTrackingSendImplementationService: environment.mockLogin
        ? UsageTrackingServiceMock
        : UsageTrackingService,
    }),
    XGuideConfigurationModule.withConfig({
      appName: 'bendguide',
      imageUrlPipeClass: ImageUrlPipe,
      translationPipeClass: TranslatePipe,
    }),
  ],
  providers: [
    CookieService,
    {
      provide: DocumentToken,
      useFactory: () => document,
    },
    {
      provide: NavigatorToken,
      useValue: navigator,
    },
    {
      provide: IsSupportedBrowserToken,
      useValue: isSupportedBrowser(identifyBrowser()),
    },
    {
      provide: WeldingPrinciplesService,
      useClass: environment.mock ? WeldingPrinciplesServiceMock : WeldingPrinciplesService,
    },
    { provide: OAuthStorage, useFactory: storageFactory },
  ],
  declarations: [COMPONENTS, VIEWS, DIALOG_VIEWS],
  exports: [COMPONENTS, DIALOG_VIEWS, SharedModule, TranslateModule],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
