import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import {
  STAINLESS_STEEL_FOLDING_PRESSING_FORCE_TABLE,
  STEEL_FOLDING_PRESSING_FORCE_TABLE,
} from '../data/PressingForceTables/folding-tables';
import {
  ROLL_BEND_ALUMINIUM_RB200_RB600,
  ROLL_BEND_ALUMINUM_OPT_RB1250,
  ROLL_BEND_ALUMINUM_OPT_RB200,
  ROLL_BEND_ALUMINUM_RB1250,
  ROLL_BEND_STAINLESS_STEEL_OPT_RB1250,
  ROLL_BEND_STAINLESS_STEEL_OPT_RB200,
  ROLL_BEND_STAINLESS_STEEL_RB1250,
  ROLL_BEND_STAINLESS_STEEL_RB200_RB600,
  ROLL_BEND_STEEL_OPT_RB1250,
  ROLL_BEND_STEEL_OPT_RB200,
  ROLL_BEND_STEEL_RB1250,
  ROLL_BEND_STEEL_RB200_RB600,
} from '../data/PressingForceTables/roll-bend-tables';
import { STAINLESS_STEEL_PRESSING_FORCE_TABLE } from '../data/PressingForceTables/standard-stainless-steel';
import { STEEL_PRESSING_FORCE_TABLE } from '../data/PressingForceTables/standard-steel';
import { Material } from '../model/material';
import { PressingForceTableCalculationResult } from '../model/pressing-force-table-calculation-result';
import { PressingForceTableToolType } from '../model/pressing-force-table-tool-type';
import { TableInfo } from '../model/table-result/table-info';

@Injectable()
export class PressingForceTableService {
  private calculationResult: BehaviorSubject<PressingForceTableCalculationResult | undefined> =
    new BehaviorSubject<PressingForceTableCalculationResult | undefined>(undefined);

  public calculationResult$ = this.calculationResult.asObservable();
  constructor(private translateService: TranslateService) {}

  public calculate(toolType: PressingForceTableToolType, material: Material, rollBend: string) {
    // Standard -> Steel, Stainless steel
    // Falzen -> Steel, Stainless steel
    // RollBend -> Steel * 3, Stainless Steel * 3, Alumnium * 3
    // 13 tables

    switch (toolType) {
      case PressingForceTableToolType.STANDARD_TOOL:
        switch (material) {
          case Material.STEEL:
            this.calculationResult.next({ resultTable: STEEL_PRESSING_FORCE_TABLE });
            break;
          case Material.STAINLESS_STEEL:
            this.calculationResult.next({ resultTable: STAINLESS_STEEL_PRESSING_FORCE_TABLE });
            break;
        }
        break;
      case PressingForceTableToolType.FOLDING:
        let resultTable: TableInfo | undefined;
        switch (material) {
          case Material.STEEL:
            resultTable = STEEL_FOLDING_PRESSING_FORCE_TABLE;
            break;
          case Material.STAINLESS_STEEL:
            resultTable = STAINLESS_STEEL_FOLDING_PRESSING_FORCE_TABLE;
            break;
        }
        if (resultTable) {
          // Translate headers
          const firstRowCells = resultTable.cells.filter((c) => c.row === 0 && c.value.length > 0);
          for (const headerCell of firstRowCells) {
            headerCell.value = this.translateService.instant(headerCell.value);
          }
          this.calculationResult.next({ resultTable });
        }
        break;
      case PressingForceTableToolType.ROLL_BEND:
        switch (rollBend) {
          case 'RB 200 - RB 600':
            switch (material) {
              case Material.STEEL:
                this.calculationResult.next({ resultTable: ROLL_BEND_STEEL_RB200_RB600 });
                break;
              case Material.STAINLESS_STEEL:
                this.calculationResult.next({ resultTable: ROLL_BEND_STAINLESS_STEEL_RB200_RB600 });
                break;
              case Material.ALUMINUM:
                this.calculationResult.next({ resultTable: ROLL_BEND_ALUMINIUM_RB200_RB600 });
                break;
            }
            break;
          case 'RB 1250':
            switch (material) {
              case Material.STEEL:
                this.calculationResult.next({ resultTable: ROLL_BEND_STEEL_RB1250 });
                break;
              case Material.STAINLESS_STEEL:
                this.calculationResult.next({ resultTable: ROLL_BEND_STAINLESS_STEEL_RB1250 });
                break;
              case Material.ALUMINUM:
                this.calculationResult.next({ resultTable: ROLL_BEND_ALUMINUM_RB1250 });
                break;
            }
            break;
          case 'Opt. RB 200 - RB 600':
            switch (material) {
              case Material.STEEL:
                this.calculationResult.next({ resultTable: ROLL_BEND_STEEL_OPT_RB200 });
                break;
              case Material.STAINLESS_STEEL:
                this.calculationResult.next({ resultTable: ROLL_BEND_STAINLESS_STEEL_OPT_RB200 });
                break;
              case Material.ALUMINUM:
                this.calculationResult.next({ resultTable: ROLL_BEND_ALUMINUM_OPT_RB200 });
                break;
            }
            break;
          case 'Opt. RB 1250':
            switch (material) {
              case Material.STEEL:
                this.calculationResult.next({ resultTable: ROLL_BEND_STEEL_OPT_RB1250 });
                break;
              case Material.STAINLESS_STEEL:
                this.calculationResult.next({ resultTable: ROLL_BEND_STAINLESS_STEEL_OPT_RB1250 });
                break;
              case Material.ALUMINUM:
                this.calculationResult.next({ resultTable: ROLL_BEND_ALUMINUM_OPT_RB1250 });
                break;
            }
            break;
        }
    }
  }
}
