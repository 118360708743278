import { TableInfo } from '../../model/table-result/table-info';
import { ORANGE_GREY_BORDER_COLOR, ORANGE_HEADER_COLOR } from './color-constants';

export const STEEL_FOLDING_PRESSING_FORCE_TABLE: TableInfo = {
  rows: 8,
  columns: 3,
  caption: 'CALCULATIONS.PRESSING_FORCE_TABLE.PRESSING_FORCE_SPECIFICATION',
  borderColor: ORANGE_GREY_BORDER_COLOR,
  cells: [
    {
      row: 0,
      column: 0,
      value: '',
      isHeader: true,
      backgroundColor: ORANGE_HEADER_COLOR,
    },

    {
      row: 0,
      column: 1,
      value: 'CALCULATIONS.PRESSING_FORCE_TABLE.FORGING_PRESSING_FORCE',
      isHeader: true,
      backgroundColor: ORANGE_HEADER_COLOR,
    },

    {
      row: 0,
      column: 2,
      value: 'CALCULATIONS.PRESSING_FORCE_TABLE.FOLDING_PRESSING_FORCE',
      isHeader: true,
      backgroundColor: ORANGE_HEADER_COLOR,
    },

    {
      row: 1,
      column: 0,
      value: '0.8',
      isHeader: true,
      backgroundColor: ORANGE_GREY_BORDER_COLOR,
    },

    {
      row: 1,
      column: 1,
      value: '320',
      isHeader: false,
    },

    {
      row: 1,
      column: 2,
      value: '120',
      isHeader: false,
    },

    {
      row: 2,
      column: 0,
      value: '1',
      isHeader: true,
      backgroundColor: ORANGE_GREY_BORDER_COLOR,
    },

    {
      row: 2,
      column: 1,
      value: '500',
      isHeader: false,
    },

    {
      row: 2,
      column: 2,
      value: '150',
      isHeader: false,
    },

    {
      row: 3,
      column: 0,
      value: '1.25',
      isHeader: true,
      backgroundColor: ORANGE_GREY_BORDER_COLOR,
    },

    {
      row: 3,
      column: 1,
      value: '550',
      isHeader: false,
    },

    {
      row: 3,
      column: 2,
      value: '170',
      isHeader: false,
    },

    {
      row: 4,
      column: 0,
      value: '1.5',
      isHeader: true,
      backgroundColor: ORANGE_GREY_BORDER_COLOR,
    },

    {
      row: 4,
      column: 1,
      value: '650',
      isHeader: false,
    },

    {
      row: 4,
      column: 2,
      value: '220',
      isHeader: false,
    },

    {
      row: 5,
      column: 0,
      value: '2',
      isHeader: true,
      backgroundColor: ORANGE_GREY_BORDER_COLOR,
    },

    {
      row: 5,
      column: 1,
      value: '800',
      isHeader: false,
    },

    {
      row: 5,
      column: 2,
      value: '300',
      isHeader: false,
    },

    {
      row: 6,
      column: 0,
      value: '2.5',
      isHeader: true,
      backgroundColor: ORANGE_GREY_BORDER_COLOR,
    },

    {
      row: 6,
      column: 1,
      value: '1000',
      isHeader: false,
    },

    {
      row: 6,
      column: 2,
      value: '550',
      isHeader: false,
    },

    {
      row: 7,
      column: 0,
      value: '3',
      isHeader: true,
      backgroundColor: ORANGE_GREY_BORDER_COLOR,
    },

    {
      row: 7,
      column: 1,
      value: '1300',
      isHeader: false,
    },

    {
      row: 7,
      column: 2,
      value: '800',
      isHeader: false,
    },
  ],
};

export const STAINLESS_STEEL_FOLDING_PRESSING_FORCE_TABLE: TableInfo = {
  rows: 6,
  columns: 3,
  caption: 'CALCULATIONS.PRESSING_FORCE_TABLE.PRESSING_FORCE_SPECIFICATION',
  borderColor: ORANGE_HEADER_COLOR,
  cells: [
    {
      row: 0,
      column: 0,
      value: '',
      isHeader: true,
      backgroundColor: ORANGE_HEADER_COLOR,
    },

    {
      row: 0,
      column: 1,
      value: 'CALCULATIONS.PRESSING_FORCE_TABLE.FORGING_PRESSING_FORCE',
      isHeader: true,
      backgroundColor: ORANGE_HEADER_COLOR,
    },

    {
      row: 0,
      column: 2,
      value: 'CALCULATIONS.PRESSING_FORCE_TABLE.FOLDING_PRESSING_FORCE',
      isHeader: true,
      backgroundColor: ORANGE_HEADER_COLOR,
    },

    {
      row: 1,
      column: 0,
      value: '0.8',
      isHeader: true,
      backgroundColor: ORANGE_GREY_BORDER_COLOR,
    },

    {
      row: 1,
      column: 1,
      value: '500',
      isHeader: false,
    },

    {
      row: 1,
      column: 2,
      value: '200',
      isHeader: false,
    },

    {
      row: 2,
      column: 0,
      value: '1',
      isHeader: true,
      backgroundColor: ORANGE_GREY_BORDER_COLOR,
    },

    {
      row: 2,
      column: 1,
      value: '600',
      isHeader: false,
    },

    {
      row: 2,
      column: 2,
      value: '250',
      isHeader: false,
    },

    {
      row: 3,
      column: 0,
      value: '1.25',
      isHeader: true,
      backgroundColor: ORANGE_GREY_BORDER_COLOR,
    },

    {
      row: 3,
      column: 1,
      value: '800',
      isHeader: false,
    },

    {
      row: 3,
      column: 2,
      value: '300',
      isHeader: false,
    },

    {
      row: 4,
      column: 0,
      value: '1.5',
      isHeader: true,
      backgroundColor: ORANGE_GREY_BORDER_COLOR,
    },

    {
      row: 4,
      column: 1,
      value: '950',
      isHeader: false,
    },

    {
      row: 4,
      column: 2,
      value: '500',
      isHeader: false,
    },

    {
      row: 5,
      column: 0,
      value: '2',
      isHeader: true,
      backgroundColor: ORANGE_GREY_BORDER_COLOR,
    },

    {
      row: 5,
      column: 1,
      value: '1300',
      isHeader: false,
    },

    {
      row: 5,
      column: 2,
      value: '800',
      isHeader: false,
    },
  ],
};
