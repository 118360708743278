import { Component, Input, OnInit } from '@angular/core';
import { MountHeightPossibilityMark } from '../../model/mount-height/mount-height-possibility-mark';

@Component({
  selector: 'lsb-mount-height-possibility-icon',
  templateUrl: './mount-height-possibility-icon.component.html',
  styleUrls: ['./mount-height-possibility-icon.component.scss'],
})
export class MountHeightPossibilityIconComponent implements OnInit {
  public readonly mountHeightPossibilityMark = MountHeightPossibilityMark;

  @Input() public possibility: MountHeightPossibilityMark = MountHeightPossibilityMark.NOT_POSSIBLE;

  constructor() {}

  ngOnInit(): void {}
}
