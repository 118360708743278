export enum CalculationType {
  PRESSING_FORCE = 'PRESSING_FORCE',
  DIE_WIDTH = 'DIE_WIDTH',
  LEG_LENGTH = 'LEG_LENGTH',
  BEND_INNER_RADIUS = 'BEND_INNER_RADIUS',
  PRESSING_FORCE_TABLE = 'PRESSING_FORCE_TABLE',
  CASE_HEIGHT = 'CASE_HEIGHT',
  SHEET_WEIGHT = 'SHEET_WEIGHT',
  SHEET_WIDTH = 'SHEET_WIDTH',
  MOUNT_HEIGHT = 'MOUNT_HEIGHT',
  TOOL_WEIGHT = 'TOOL_WEIGHT',
}
