import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalculationsComponent } from './views/calculations/calculations.component';
import { CalculationsRoutingModule } from './calculations-routing.module';
import { SharedModule } from '../shared/shared.module';
import { UsageTrackingModule } from '@trumpf-xguide/xguide';
import { PressingForceComponent } from './views/pressing-force/pressing-force.component';
import { SheetThicknessComponent } from './views/sheet-thickness/sheet-thickness.component';
import { SheetWeightComponent } from './views/sheet-weight/sheet-weight.component';
import { DieWidthComponent } from './views/die-width/die-width.component';
import { LegLengthComponent } from './views/leg-length/leg-length.component';
import { PressingForceTableComponent } from './views/pressing-force-table/pressing-force-table.component';
import { ResultTableComponent } from './components/result-table/result-table.component';
import { CaseHeightComponent } from './views/case-height/case-height.component';
import { ToolWeightComponent } from './views/tool-weight/tool-weight.component';
import { MountHeightComponent } from './views/mount-height/mount-height.component';
import { MountHeightPossibilityIconComponent } from './components/mount-height-possibility-icon/mount-height-possibility-icon.component';
import { BendInnerRadiusComponent } from './views/bend-inner-radius/bend-inner-radius.component';
import { PressingForcePictureComponent } from './components/pressing-force-picture/pressing-force-picture.component';
import { LegLengthPictureComponent } from './components/leg-length-picture/leg-length-picture.component';
import { BendInnerRadiusPictureComponent } from './components/bend-inner-radius-picture/bend-inner-radius-picture.component';
import { CaseHeightPictureComponent } from './components/case-height-picture/case-height-picture.component';
import { MountHeightPictureComponent } from './components/mount-height-picture/mount-height-picture.component';
import { DieWidthPictureComponent } from './components/die-width-picture/die-width-picture.component';

@NgModule({
  declarations: [
    CalculationsComponent,
    PressingForceComponent,
    DieWidthComponent,
    LegLengthComponent,
    PressingForceTableComponent,
    ResultTableComponent,
    SheetWeightComponent,
    SheetThicknessComponent,
    CaseHeightComponent,
    ToolWeightComponent,
    BendInnerRadiusComponent,
    MountHeightComponent,
    MountHeightPossibilityIconComponent,
    PressingForcePictureComponent,
    LegLengthPictureComponent,
    BendInnerRadiusPictureComponent,
    CaseHeightPictureComponent,
    MountHeightPictureComponent,
    DieWidthPictureComponent,
  ],
  imports: [CalculationsRoutingModule, CommonModule, SharedModule, UsageTrackingModule.forChild()],
})
export class CalculationsModule {}
