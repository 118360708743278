<div class="input-container">
  <!-- Calculate dropdown -->
  <div class="dropdown">
    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.CALCULATE | translate }}
    </span>
    <xui-dropdown
      #calculateDropDown
      [label]="selectedCalculationTypeName()"
      data-ngtx="pressing-force:calculation-type"
    >
      <xui-dropdown-item
        (click)="calculationTypeChanged(calculationType.id); calculateDropDown.close()"
        *ngFor="let calculationType of calculationTypes"
      >
        {{ calculationType?.name }}
      </xui-dropdown-item>
    </xui-dropdown>
  </div>
  <!-- Material -->
  <div class="dropdown">
    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.MATERIAL | translate }}
    </span>
    <xui-dropdown
      #materialDropDown
      [label]="selectedMaterialName()"
      data-ngtx="pressing-force:material"
    >
      <xui-dropdown-item
        (click)="
          selectedMaterial = undefined;
          isMaterialUserDefined = true;
          materialDropDown.close();
          clearResult()
        "
      >
        {{ translations.CALCULATIONS.INPUT_LABELS.USER_DEFINED | translate }}
      </xui-dropdown-item>
      <xui-dropdown-item
        (click)="onSelectMaterial(material.id); materialDropDown.close()"
        *ngFor="let material of materials"
      >
        {{ material?.name }}
      </xui-dropdown-item>
    </xui-dropdown>
  </div>
  <xui-input-field
    (focusout)="clearResult()"
    [(text)]="tensileStrengthValue.value"
    [disabled]="!isMaterialUserDefined"
    data-ngtx="pressing-force:tensileStrength"
    label="{{ translations.CALCULATIONS.INPUT_LABELS.TENSILE_STRENGTH | translate }}"
  >
    <xui-value
      #tensileStrengthValue
      [(binding)]="tensileStrength"
      [fallbackValue]="tensileStrength"
      lsbValidateRequired
    >
      <xui-pressure-converter
        imperialTargetUnit="psi"
        metricTargetUnit="N/mm²"
      ></xui-pressure-converter>
      <xui-number-formatter
        [maxDecimals]="0"
        [unit]="tensileStrengthValue.unit"
      ></xui-number-formatter>
    </xui-value>
  </xui-input-field>

  <!-- Pressing force -->
  <xui-input-field
    (focusout)="clearResult()"
    *ngIf="selectedCalculationType !== bendingForceCalculationTypes.PRESSING_FORCE"
    [(text)]="pressingForceValue.value"
    data-ngtx="pressing-force:pressingForce"
    label="{{ translations.CALCULATIONS.TYPES.PRESSING_FORCE | translate }}"
  >
    <xui-value #pressingForceValue [(binding)]="pressingForce" [fallbackValue]="pressingForce">
      <xui-force-converter imperialTargetUnit="ton" metricTargetUnit="kN"></xui-force-converter>
      <xui-number-formatter
        [maxDecimals]="0"
        [unit]="pressingForceValue.unit"
      ></xui-number-formatter>
    </xui-value>
  </xui-input-field>

  <!-- Sheet thickness -->
  <ng-container *ngIf="selectedCalculationType !== bendingForceCalculationTypes.SHEET_THICKNESS">
    <div class="dropdown">
      <span class="dropdown-label">
        {{ translations.CALCULATIONS.INPUT_LABELS.SHEET_THICKNESS | translate }}
      </span>
      <xui-dropdown
        #sheetThicknessDropDown
        [label]="
          isSheetThicknessUserDefined
            ? (translations.CALCULATIONS.INPUT_LABELS.USER_DEFINED | translate)
            : selectedSheetThicknessInCmOrInch.value
        "
        data-ngtx="pressing-force:sheet-thickness"
      >
        <xui-value #selectedSheetThicknessInCmOrInch [binding]="selectedSheetThickness">
          <xui-length-converter
            imperialTargetUnit="inch"
            metricTargetUnit="mm"
          ></xui-length-converter>
          <xui-number-formatter
            [maxDecimals]="2"
            [unit]="selectedSheetThicknessInCmOrInch.unit"
          ></xui-number-formatter>
        </xui-value>
        <xui-dropdown-item
          (click)="
            isSheetThicknessUserDefined = true; sheetThicknessDropDown.close(); clearResult()
          "
        >
          {{ translations.CALCULATIONS.INPUT_LABELS.USER_DEFINED | translate }}
        </xui-dropdown-item>
        <xui-dropdown-item
          (click)="
            selectedSheetThickness = sheetThicknessForCalculation = sheetThickness;
            isSheetThicknessUserDefined = false;
            clearResult();
            sheetThicknessDropDown.close()
          "
          *ngFor="let sheetThickness of sheetThicknesses"
        >
          <xui-value #sheetThicknessInCentimeterOrInch [binding]="sheetThickness">
            <xui-length-converter
              imperialTargetUnit="inch"
              metricTargetUnit="mm"
            ></xui-length-converter>
            <xui-number-formatter
              [maxDecimals]="2"
              [unit]="sheetThicknessInCentimeterOrInch.unit"
            ></xui-number-formatter>
          </xui-value>
          {{ sheetThicknessInCentimeterOrInch.value }}
        </xui-dropdown-item>
      </xui-dropdown>
    </div>
    <xui-input-field
      (focusout)="clearResult()"
      *ngIf="isSheetThicknessUserDefined"
      [(text)]="sheetThicknessValue.value"
      data-ngtx="pressing-force:sheetThicknessInput"
      label="{{ translations.CALCULATIONS.INPUT_LABELS.SHEET_THICKNESS | translate }}"
    >
      <xui-value
        #sheetThicknessValue
        [(binding)]="sheetThicknessForCalculation"
        [fallbackValue]="sheetThicknessForCalculation"
        lsbValidateRequired
      >
        <xui-length-converter
          imperialTargetUnit="inch"
          metricTargetUnit="mm"
        ></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="2"
          [unit]="sheetThicknessValue.unit"
        ></xui-number-formatter>
      </xui-value>
    </xui-input-field>
  </ng-container>
  <!-- Die Width -->
  <div class="dropdown">
    <span class="dropdown-label">
      {{ translations.CALCULATIONS.TYPES.DIE_WIDTH | translate }}
    </span>
    <xui-dropdown
      #dieWidthDropDown
      [label]="
        isDieWidthUserDefined
          ? (translations.CALCULATIONS.INPUT_LABELS.USER_DEFINED | translate)
          : dieWidthInCmOrInch.value
      "
      data-ngtx="pressing-force:die-width-dropdown"
    >
      <xui-value #dieWidthInCmOrInch [binding]="selectedDieWidth">
        <xui-length-converter imperialTargetUnit="mm" metricTargetUnit="mm"></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="0"
          [unit]="dieWidthInCmOrInch.unit"
        ></xui-number-formatter>
      </xui-value>
      <xui-dropdown-item
        (click)="isDieWidthUserDefined = true; dieWidthDropDown.close(); clearResult()"
      >
        {{ translations.CALCULATIONS.INPUT_LABELS.USER_DEFINED | translate }}
      </xui-dropdown-item>
      <xui-dropdown-item
        (click)="
          selectedDieWidth = dieWidthForCalculation = dieWidth;
          isDieWidthUserDefined = false;
          clearResult();
          dieWidthDropDown.close()
        "
        *ngFor="let dieWidth of dieWidths"
      >
        <xui-value #dieWidthInCentimeterOrInch [binding]="dieWidth">
          <xui-length-converter
            imperialTargetUnit="mm"
            metricTargetUnit="mm"
          ></xui-length-converter>
          <xui-number-formatter
            [maxDecimals]="0"
            [unit]="dieWidthInCentimeterOrInch.unit"
          ></xui-number-formatter>
        </xui-value>
        {{ dieWidthInCentimeterOrInch.value }}
      </xui-dropdown-item>
    </xui-dropdown>
    <xui-input-field
      (focusout)="clearResult()"
      *ngIf="isDieWidthUserDefined"
      [(text)]="dieWidthValue.value"
      data-ngtx="pressing-force:dieWidth"
      label="{{ translations.CALCULATIONS.TYPES.DIE_WIDTH | translate }}"
    >
      <xui-value
        #dieWidthValue
        [(binding)]="dieWidthForCalculation"
        [fallbackValue]="dieWidthForCalculation"
        lsbValidateRequired
      >
        <xui-length-converter imperialTargetUnit="mm" metricTargetUnit="mm"></xui-length-converter>
        <xui-number-formatter [maxDecimals]="2" [unit]="dieWidthValue.unit"></xui-number-formatter>
      </xui-value>
    </xui-input-field>
  </div>

  <!-- Bending Length -->
  <xui-input-field
    (focusout)="clearResult()"
    *ngIf="selectedCalculationType !== bendingForceCalculationTypes.BENDING_LENGTH"
    [(text)]="bendingLengthValue.value"
    data-ngtx="pressing-force:dieWidth"
    label="{{ translations.CALCULATIONS.INPUT_LABELS.BENDING_LENGTH | translate }}"
  >
    <xui-value
      #bendingLengthValue
      [(binding)]="bendingLength"
      [fallbackValue]="bendingLength"
      lsbValidateRequired
    >
      <xui-length-converter imperialTargetUnit="inch" metricTargetUnit="mm"></xui-length-converter>
      <xui-number-formatter
        [maxDecimals]="0"
        [unit]="bendingLengthValue.unit"
      ></xui-number-formatter>
    </xui-value>
  </xui-input-field>

  <!-- Upper tool radius -->
  <div class="dropdown">
    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.UPPER_TOOL_RADIUS | translate }}
    </span>
    <xui-dropdown
      #upperToolRadiusDropDown
      [label]="
        isUpperToolRadiusUserDefined
          ? (translations.CALCULATIONS.INPUT_LABELS.USER_DEFINED | translate)
          : upperToolRadiusInCmOrInch.value
      "
      data-ngtx="pressing-force:upper-tool-radius"
    >
      <xui-value #upperToolRadiusInCmOrInch [binding]="selectedUpperToolRadius">
        <xui-length-converter
          imperialTargetUnit="inch"
          metricTargetUnit="mm"
        ></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="2"
          [unit]="upperToolRadiusInCmOrInch.unit"
        ></xui-number-formatter>
      </xui-value>
      <xui-dropdown-item
        (click)="
          isUpperToolRadiusUserDefined = true; upperToolRadiusDropDown.close(); clearResult()
        "
      >
        {{ translations.CALCULATIONS.INPUT_LABELS.USER_DEFINED | translate }}
      </xui-dropdown-item>
      <xui-dropdown-item
        (click)="
          selectedUpperToolRadius = upperToolRadiusForCalculation = upperToolRadius;
          isUpperToolRadiusUserDefined = false;
          clearResult();
          upperToolRadiusDropDown.close()
        "
        *ngFor="let upperToolRadius of upperToolRadii"
      >
        <xui-value #upperToolRadiusInCentimeterOrInch [binding]="upperToolRadius">
          <xui-length-converter
            imperialTargetUnit="inch"
            metricTargetUnit="mm"
          ></xui-length-converter>
          <xui-number-formatter
            [maxDecimals]="2"
            [unit]="upperToolRadiusInCentimeterOrInch.unit"
          ></xui-number-formatter>
        </xui-value>
        {{ upperToolRadiusInCentimeterOrInch.value }}
      </xui-dropdown-item>
    </xui-dropdown>
  </div>
  <xui-input-field
    (focusout)="clearResult()"
    *ngIf="isUpperToolRadiusUserDefined"
    [(text)]="upperToolRadiusValue.value"
    data-ngtx="pressing-force:upperToolRadiusInput"
    label="{{ translations.CALCULATIONS.INPUT_LABELS.UPPER_TOOL_RADIUS | translate }}"
  >
    <xui-value
      #upperToolRadiusValue
      [(binding)]="upperToolRadiusForCalculation"
      [fallbackValue]="upperToolRadiusForCalculation"
      lsbValidateRequired
    >
      <xui-length-converter imperialTargetUnit="inch" metricTargetUnit="mm"></xui-length-converter>
      <xui-number-formatter
        [maxDecimals]="2"
        [unit]="upperToolRadiusValue.unit"
      ></xui-number-formatter>
    </xui-value>
  </xui-input-field>

  <!-- Bending angle -->
  <div class="dropdown">
    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.BENDING_ANGLE | translate }}
    </span>
    <xui-dropdown
      #bendingAngleDropDown
      [label]="
        isBendingAngleUserDefined
          ? (translations.CALCULATIONS.INPUT_LABELS.USER_DEFINED | translate)
          : selectedBendingAngleValue.value
      "
      data-ngtx="pressing-force:upper-tool-radius"
    >
      <xui-value #selectedBendingAngleValue [binding]="selectedBendingAngle">
        <xui-number-formatter [maxDecimals]="0" [unit]="'°'"></xui-number-formatter>
      </xui-value>
      <xui-dropdown-item
        (click)="isBendingAngleUserDefined = true; bendingAngleDropDown.close(); clearResult()"
      >
        {{ translations.CALCULATIONS.INPUT_LABELS.USER_DEFINED | translate }}
      </xui-dropdown-item>
      <xui-dropdown-item
        (click)="
          selectedBendingAngle = bendingAngleForCalculation = bendingAngle;
          isBendingAngleUserDefined = false;
          clearResult();
          bendingAngleDropDown.close()
        "
        *ngFor="let bendingAngle of bendingAngles"
      >
        <xui-value #bendingAngleDropdownItemValue [binding]="bendingAngle">
          <xui-number-formatter [maxDecimals]="0" [unit]="'°'"></xui-number-formatter>
        </xui-value>
        {{ bendingAngleDropdownItemValue.value }}
      </xui-dropdown-item>
    </xui-dropdown>
  </div>
  <xui-input-field
    (focusout)="clearResult()"
    *ngIf="isBendingAngleUserDefined"
    [(text)]="bendingAngleValue.value"
    data-ngtx="pressing-force:bendingAngleInput"
    label="{{ translations.CALCULATIONS.INPUT_LABELS.BENDING_ANGLE | translate }}"
  >
    <xui-value
      #bendingAngleValue
      [(binding)]="bendingAngleForCalculation"
      [fallbackValue]="bendingAngleForCalculation"
      lsbValidateRequired
    >
      <xui-number-formatter [maxDecimals]="0" [unit]="'°'"></xui-number-formatter>
    </xui-value>
  </xui-input-field>

  <ng-container *ngIf="calculationErrors$ | async as errors">
    <span *ngFor="let error of errors" class="calculationError">
      {{ error }}
    </span>
  </ng-container>

  <!-- Calculate -->
  <div class="calculate-button">
    <xui-button
      (click)="calculate()"
      xuiClickUsageTracking="{{ uiElementIds.CALCULATE_BENDING_FORCE }}"
    >
      {{ translations.CALCULATIONS.INPUT_LABELS.CALCULATE | translate }}
    </xui-button>
  </div>

  <ng-container *ngIf="!(calculationErrors$ | async)">
    <div *ngIf="calculationResult$ | async as calculationResult" class="calculation-result">
      <ng-container [ngSwitch]="calculationResult.type">
        <ng-container *ngSwitchCase="bendingForceCalculationTypes.PRESSING_FORCE">
          <xui-caption-label [caption]="translations.CALCULATIONS.TYPES.PRESSING_FORCE | translate">
            <xui-value
              #pressingForceResultValue
              [(binding)]="calculationResult.pressingForce"
              [fallbackValue]="0"
            >
              <xui-force-converter
                imperialTargetUnit="ton"
                metricTargetUnit="kN"
              ></xui-force-converter>
              <xui-number-formatter
                [maxDecimals]="0"
                [unit]="pressingForceResultValue.unit"
              ></xui-number-formatter>
            </xui-value>
            {{ pressingForceResultValue.value }}
          </xui-caption-label>
        </ng-container>
        <ng-container *ngSwitchCase="bendingForceCalculationTypes.SHEET_THICKNESS">
          <xui-caption-label
            [caption]="translations.CALCULATIONS.INPUT_LABELS.SHEET_THICKNESS | translate"
          >
            <xui-value
              #sheetThicknessResultValue
              [(binding)]="calculationResult.sheetThickness"
              [fallbackValue]="0"
            >
              <xui-length-converter
                imperialTargetUnit="inch"
                metricTargetUnit="mm"
              ></xui-length-converter>
              <xui-number-formatter
                [maxDecimals]="2"
                [unit]="sheetThicknessResultValue.unit"
              ></xui-number-formatter>
            </xui-value>
            {{ sheetThicknessResultValue.value }}
          </xui-caption-label>
        </ng-container>
        <ng-container *ngSwitchCase="bendingForceCalculationTypes.BENDING_LENGTH">
          <xui-caption-label
            [caption]="translations.CALCULATIONS.INPUT_LABELS.BENDING_LENGTH | translate"
          >
            <xui-value
              #bendingLengthResultValue
              [(binding)]="calculationResult.bendingLength"
              [fallbackValue]="0"
            >
              <xui-length-converter
                imperialTargetUnit="inch"
                metricTargetUnit="mm"
              ></xui-length-converter>
              <xui-number-formatter
                [maxDecimals]="2"
                [unit]="bendingLengthResultValue.unit"
              ></xui-number-formatter>
            </xui-value>
            {{ bendingLengthResultValue.value }}
          </xui-caption-label>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <div class="result-image-block">
    <lsb-pressing-force-picture
      [bendingLength]="bendingLengthPictureValue.value ?? '?'"
      [dieWidth]="dieWidthImageValue.value ?? '?'"
      [pressingForce]="pressingForceImageValue.value ?? '?'"
      [sheetThickness]="sheetThicknessPictureValue.value ?? '?'"
    >
      <xui-value #pressingForceImageValue [(binding)]="pressingForce" [fallbackValue]="0">
        <xui-force-converter imperialTargetUnit="ton" metricTargetUnit="kN"></xui-force-converter>
        <xui-number-formatter
          [maxDecimals]="0"
          [unit]="pressingForceImageValue.unit"
        ></xui-number-formatter>
      </xui-value>
      <xui-value #dieWidthImageValue [(binding)]="dieWidthForCalculation" [fallbackValue]="0">
        <xui-length-converter imperialTargetUnit="mm" metricTargetUnit="mm"></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="2"
          [unit]="dieWidthImageValue.unit"
        ></xui-number-formatter>
      </xui-value>
      <xui-value
        #bendingLengthPictureValue
        [(binding)]="bendingLength"
        [fallbackValue]="bendingLength"
        lsbValidateRequired
      >
        <xui-length-converter
          imperialTargetUnit="inch"
          metricTargetUnit="mm"
        ></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="0"
          [unit]="bendingLengthPictureValue.unit"
        ></xui-number-formatter>
      </xui-value>
      <xui-value #sheetThicknessPictureValue [binding]="sheetThicknessForCalculation">
        <xui-length-converter
          imperialTargetUnit="inch"
          metricTargetUnit="mm"
        ></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="2"
          [unit]="sheetThicknessPictureValue.unit"
        ></xui-number-formatter>
      </xui-value>
    </lsb-pressing-force-picture>
  </div>
</div>
