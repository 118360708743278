<div class="related-parts">
  <span class="label">{{ translations.PARTS.FIXTURE.ALTERNATIVE | translate }}</span>
  <!--FC02 - it's the temporary constant-->
  <xui-tag (click)="selectRelatedPrinciple('FC02')" class="tag">
    {{ translations.PARTS.FIXTURE.PREBINDERS | translate }}
  </xui-tag>
</div>
<div class="content">
  <div class="part-header">
    <div class="title">
      {{ translations.PARTS.FIXTURE.FIXTURE_FOR | translate }} {{ partDetails.name }}
    </div>
    <xui-button
      type="primary"
      (click)="downloadCAD()"
      xuiClickUsageTracking="{{ uiElementIds.PARTS_FIXTURE_DOWNLOAD }}"
      data-ngtx="download-button"
    >
      {{ translations.PARTS.DOWNLOAD_CAD | translate }}
    </xui-button>
  </div>

  <hr />
  <xui-media-gallery
    [items]="partDetails.fixtureGallery"
    [(selectedIndex)]="selectedMediaIndex"
  ></xui-media-gallery>
  <hr />

  <div
    class="related-principles"
    *ngIf="relatedWeldingPrinciples$ | async as relatedFixturePrinciples"
  >
    <h2 class="header">{{ translations.PARTS.RELATED_BENDING_PRINCIPLES_TITLE | translate }}</h2>
    <div class="cards">
      <xui-card
        class="card"
        [title]="principle.name"
        [preview]="principle.image | imageUrl"
        (click)="selectRelatedPrinciple(principle.id)"
        *ngFor="let principle of relatedFixturePrinciples"
        >{{ principle.description }}</xui-card
      >
    </div>
    <xui-button
      class="more-button"
      xuiClickUsageTracking="{{ uiElementIds.PARTS_FIXTURE_MORE_DETAILS }}"
      (click)="updatePrinciples$.next()"
      *ngIf="
        partDetails.weldingPrinciples?.length > relatedFixturePrinciplesToShow &&
        relatedFixturePrinciples.length <= relatedFixturePrinciplesToShow
      "
      [type]="'secondary'"
      >{{ translations.BENDING_PRINCIPLES.DETAILS.MORE_PARTS | translate }}</xui-button
    >
  </div>
</div>
