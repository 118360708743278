<div class="input-container">
  <div class="dropdown">
    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.TOOL_CATEGORY | translate }}
    </span>
    <xui-dropdown
      #toolCategoryDropDown
      [label]="selectedToolCategoryName()"
      data-ngtx="tool-height:tool-category"
    >
      <xui-dropdown-item
        (click)="
          selectedToolCategory = category.id;
          updateAvailableTools();
          toolCategoryDropDown.close();
          clearResult()
        "
        *ngFor="let category of toolCategories"
      >
        {{ category.name }}
      </xui-dropdown-item>
    </xui-dropdown>
  </div>
  <div class="dropdown">
    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.TOOL_TYPE | translate }}
    </span>
    <xui-dropdown
      #toolTypeDropDown
      [label]="selectedSpecificWeight.name"
      data-ngtx="tool-height:tool-type"
    >
      <xui-dropdown-item
        (click)="selectedSpecificWeight = toolType; toolTypeDropDown.close(); clearResult()"
        *ngFor="let toolType of specificWeights"
      >
        {{ toolType.name }}
      </xui-dropdown-item>
    </xui-dropdown>
  </div>
  <div class="dropdown">
    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.TOOL_LENGTH | translate }}
    </span>
    <xui-dropdown
      #toolLengthDropDown
      [label]="toolLengthInCmOrInch.value"
      data-ngtx="tool-height:tool-length"
    >
      <xui-value #toolLengthInCmOrInch [binding]="selectedToolLength">
        <xui-length-converter
          imperialTargetUnit="inch"
          metricTargetUnit="mm"
        ></xui-length-converter>
        <xui-number-formatter
          [maxDecimals]="0"
          [unit]="toolLengthInCmOrInch.unit"
        ></xui-number-formatter>
      </xui-value>

      <xui-dropdown-item
        (click)="selectedToolLength = toolLength; toolLengthDropDown.close(); clearResult()"
        *ngFor="let toolLength of toolLengths"
      >
        <xui-value #loolLengthItemInCentimeterOrInch [binding]="toolLength">
          <xui-length-converter
            imperialTargetUnit="inch"
            metricTargetUnit="mm"
          ></xui-length-converter>
          <xui-number-formatter
            [maxDecimals]="0"
            [unit]="loolLengthItemInCentimeterOrInch.unit"
          ></xui-number-formatter>
        </xui-value>
        {{ loolLengthItemInCentimeterOrInch.value }}
      </xui-dropdown-item>
    </xui-dropdown>
  </div>

  <!-- Calculate -->
  <div class="calculate-button">
    <xui-button
      (click)="calculate()"
      xuiClickUsageTracking="{{ uiElementIds.CALCULATE_TOOL_WEIGHT }}"
    >
      {{ translations.CALCULATIONS.INPUT_LABELS.CALCULATE | translate }}
    </xui-button>
  </div>

  <div *ngIf="calculationResult$ | async as calculationResult" class="calculation-result">
    <xui-caption-label [caption]="translations.CALCULATIONS.RESULT_LABELS.WEIGHT | translate">
      <xui-value #toolWeightResultValue [(binding)]="calculationResult.weight" [fallbackValue]="0">
        <xui-weight-converter imperialTargetUnit="lbs" metricTargetUnit="kg"></xui-weight-converter>
        <xui-number-formatter
          [maxDecimals]="2"
          [unit]="toolWeightResultValue.unit"
        ></xui-number-formatter>
      </xui-value>
      {{ toolWeightResultValue.value }}
    </xui-caption-label>
  </div>
</div>
