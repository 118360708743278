import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import {
  HTTP_UNAUTHORIZED_ERRORCODE,
  Keyword,
  Media,
  Part,
  WeldingPrinciple,
  WeldingPrincipleDetails,
} from '../../../../../shared';
import { WeldingPrinciplesService } from '../../services/welding-principles/welding-principles.service';
import { mailToLink } from '../../shared/helpers/mail-to';
import { isNotNullOrUndefined } from '../../shared/helpers/rxjs.helpers';
import { TranslationHelper } from '../../shared/helpers/translation.helpers';
import { AuthService } from '../../shared/services/auth/auth.service';
import { PartsService } from '../../shared/services/parts/parts.service';
import { UiElementIds } from '../../shared/usage-tracking/ui-element-ids';

const DETAILS_URL = 'bending-principles/details';
const PARTS_URL = 'parts';

@Component({
  selector: 'lsb-welding-principle-details',
  templateUrl: './welding-principle-details.component.html',
  styleUrls: ['./welding-principle-details.component.scss'],
})
export class WeldingPrincipleDetailsComponent implements OnInit {
  public readonly uiElementIds = UiElementIds;
  public readonly examplePartsToShow = 2;

  public details$: Observable<WeldingPrincipleDetails> | undefined;
  public examples$: Observable<Part[]>;
  public selectedMediaIndex = 0;

  public parts$: Observable<Part[]>;
  public updateParts$: Subject<void> = new Subject();

  public get mailLink(): string {
    return mailToLink(
      'teilegestaltung@trumpf.com',
      'Weld.Guide@de.trumpf.com',
      this.translateService.instant(this.translations.BENDING_PRINCIPLES.DETAILS.HELP.MAIL_SUBJECT),
    );
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private weldingPrinciplesService: WeldingPrinciplesService,
    private translateService: TranslateService,
    private partsService: PartsService,
    public translations: TranslationHelper,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    const weldingPrincipleId$ = this.route.paramMap.pipe(
      tap(() => this.resetMediaIndex()),
      map((params) => params.get('id')),
      isNotNullOrUndefined(),
    );

    this.details$ = weldingPrincipleId$.pipe(
      switchMap((id) =>
        this.weldingPrinciplesService.getPrincipleDetails(id).pipe(
          // There is some logic at the backend side.
          // If user is not logged-in and `AccessibleWithoutLogin` flag for current principle is false, then backend returned 'Unauthorized'
          // In this case we should redirect user to login page
          catchError((err: HttpErrorResponse) => {
            if (err.status === HTTP_UNAUTHORIZED_ERRORCODE) {
              /* 
                  hint: langju: navigate back to overview, so that user can use back button of browser,
                  without being caught in an infinite loop between "back"-button and redirect to login.
               */
              this.router.navigate(['bending-principles']);
              this.authService.login(this.router.url);
            }
            return of({} as WeldingPrincipleDetails);
          }),
        ),
      ),
    );

    this.examples$ = weldingPrincipleId$.pipe(
      switchMap((id) =>
        this.partsService.getAllPartDetails().pipe(
          map((parts) => {
            return parts.filter((part) => {
              const itemMatchesId = (item: Keyword | WeldingPrinciple): boolean => item.id === id;

              const hasSeamGeometryMatches = part.seamGeometry.some(itemMatchesId);
              const hasWeldingPrincipleMatches = part.weldingPrinciples.some(itemMatchesId);
              const hasFixturePrincipleMatches = part.relatedFixturePrinciples.some(itemMatchesId);
              const hasAnyMatches =
                hasSeamGeometryMatches || hasWeldingPrincipleMatches || hasFixturePrincipleMatches;

              return hasAnyMatches;
            });
          }),
        ),
      ),
    );

    const initialParts$ = this.examples$.pipe(
      map((examples) => examples.slice(0, this.examplePartsToShow)),
    );

    // Wait for update trigger to fetch remaining examples
    const remainingParts$ = combineLatest([this.updateParts$, this.examples$]).pipe(
      map(([, examples]) => examples.slice(this.examplePartsToShow)),
    );

    this.parts$ = combineLatest([
      initialParts$.pipe(startWith([])),
      remainingParts$.pipe(startWith([])),
    ]).pipe(map(([initialParts, remainingParts]) => [...initialParts, ...remainingParts]));
  }

  public getMediaFromGallery(indexToSelect: number, gallery: Media[]) {
    if (gallery === undefined) {
      return undefined;
    }

    return gallery[indexToSelect];
  }

  public navigateToPrinciple(keywordId: string) {
    this.router.navigate([DETAILS_URL, keywordId]);
  }

  public navigateToPart(id: string) {
    this.router.navigate([PARTS_URL, id]);
  }

  private resetMediaIndex() {
    this.selectedMediaIndex = 0;
  }
}
